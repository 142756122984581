import qs from 'qs';
import client, { url } from './client';

export const loadRFIDInfo = ({userkey}) => {
    const queryString = qs.stringify({
        userkey
    });
    return client.get(`${url}/api/rfid/loadinfo?${queryString}`);
};

export const addRFID = ({startValue, endValue, userkey}) => {
    const queryString = qs.stringify({
        startValue, endValue, userkey
    });
    return client.post(`${url}/api/rfid/add?${queryString}`, {});
};

export const deleteRFID = ({startValue, endValue, userkey}) => {
    const queryString = qs.stringify({
        startValue, endValue, userkey
    });
    return client.post(`${url}/api/rfid/delete?${queryString}`, {});
};