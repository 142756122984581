import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import palette from '../../lib/styles/palette';
import { FiRotateCw } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { timeValueToDate6, timeValueToDateTime } from '../../lib/commonFunc/commonFunc';
import { RiArrowLeftSLine } from 'react-icons/ri';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ReportView from '../member/ReportView';

const MemberBlock = styled.div`
  margin-top: 2.5rem;
`;
const ConditionBlock = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${palette.black1};
  border-left: 1px solid ${palette.black1};
  border-right: 1px solid ${palette.black1};
`;
const ConditionLastBlock = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${palette.black1};
`;
const ConditionName = styled.div`
  padding: 10px 16px;
  text-align: center;
  margin-right: 20px;
  border-right: 1px solid ${palette.black1};
  color: ${palette.black1};
  font-family: 'Noto Sans kr';
  width: 300px;

  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
    padding: 20px 28px;
    min-width: 120px;
  }
`;
const StyledForm = styled.form`
  display: flex;
  align-items: center;
`;
const ValueBlock = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
        props.noFlex &&
        css`
      display: block;
    `}
`;

const TextSpace = styled.div`
  padding: 0 10px;
  font-size: 20pt;
`;
const SearchBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 0;
    margin-bottom: 0;
`;
const SearchButtonView = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[17]};
`;
const TD = styled.td`
  text-align: center;
  min-width: 60px;
  width: 8%;
`;

const Basetd = styled.td`
  text-align: center;
  min-width: 150px;
`;

const ImageBox = styled.div`
  width: 200px;
  height: 200px;
  background-color: ${palette.webGray[17]};
  justify-content: center;
  align-items: center;

  & img {
    width: 200px;
    height: 200px;
  }
`;

const SignBox = styled.div`
  width: 288px;
  height: 162px;
  background-color: ${palette.webGray[17]};
  justify-content: center;
  align-items: center;

  & img {
    width: 288px;
    height: 162px;
  }
`;


const SearchListBlock = styled.div`
  padding-top: 40px;
    ${(props) => props.height ? css`height:${props.height}vh;` : css`height: 50vh;`}
`;

const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;
  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }
`;

export const HistoryBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  & svg {
    font-size: 24pt !important;
    color: ${palette.webGray[6]} !important;
    cursor: pointer;
  }
`;
export const HistoryBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  svg {
    font-size: 16pt;
  }
`;

const StyledInput = styled.input`
  font-size: 1rem;
  border: 1px solid ${palette.gray3};
  padding: 0.5rem;
  outline: none;
  width: 300px;
  margin-left: 1rem;
  margin-right: 1rem;
  &:focus {
    color: ${palette.blue1};
  }
  & + & {
    margin-top: 1rem;
  }
`;

const MemberInfo = ({
    item,
    history,
    onChangeState,
    onChangeRFIDInput,
    RFIDInput,
    onChangeRFID,
}) => {
    
    const [rfidEditFlag, setrfidEditFlag] = useState(false);
    const [reportView, setReportView] = useState(false);
    if(!item)
    {
      return null
    }

    return (
        <MemberBlock>
            <HistoryBlock>
                <HistoryBtn>
                    <RiArrowLeftSLine onClick={() =>{history.goBack();}}/>
                </HistoryBtn>
                <div>목록보기</div>
            </HistoryBlock>
            <ConditionBlock>
                <ConditionName>이름</ConditionName>
                <ValueBlock>
                    <div>{item.name}</div>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>핸드폰 번호</ConditionName>
                <ValueBlock>
                    <div>{item.phone}</div>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>동물 정보</ConditionName>
                <ValueBlock>
                    <div>{item.pat_name}    ({item.pat_kind} - {item.pat_color}) [성별 - {item.pat_sex == "1"?'수':'암'}, 중성화 - {item.pat_neutering == "1"?"했음":"안했음"}]</div>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>동물 정보2</ConditionName>
                <ValueBlock>
                    <div>생일 - {timeValueToDate6(item.pat_birth)}</div>
                    <div>, 입양일 - {timeValueToDate6(item.pat_registTime)}</div>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>상태</ConditionName>
                <ValueBlock>
                    {
                        (item.state == 0 || item.state == 1)?
                        <div style={{color:'#000000'}}>작성완료</div>
                        :
                        item.state == 2?
                        <div style={{color:'#ee0000'}}>결제완료</div>
                        :
                        item.state == 3?
                        <div style={{color:'#1111ee'}}>신청완료</div>
                        :
                        <div style={{color:'#00cc00'}}>등록완료</div>
                    }
                    {/* {
                        (item.state == 0 || item.state == 1)?
                        <Button style={{backgroundColor:'#f1f1f1', marginLeft:'20px'}} onClick={() =>{onChangeState(2)}}>'<div style={{color:'#ee0000'}}>결제완료</div>'로 변경하기</Button>
                        :null
                    } */}
                    {
                        // item.state == 2?
                        (item.state == 0 || item.state == 1)?
                        <Button style={{backgroundColor:'#f1f1f1', marginLeft:'20px'}} onClick={() =>{onChangeState(3)}}>'<div style={{color:'#1111ee'}}>신청완료</div>'로 변경하기</Button>
                        :null
                    }
                    {
                        //item.state == 2?
                        item.state == 3?
                        <Button style={{backgroundColor:'#f1f1f1', marginLeft:'20px'}} onClick={() =>{onChangeState(1)}}>'<div style={{color:'#000000'}}>작성완료</div>'로 변경하기</Button>
                        :null
                    }
                    {/* {
                        item.state == 3?
                        <Button style={{backgroundColor:'#f1f1f1', marginLeft:'20px'}} onClick={() =>{onChangeState(2)}}>'<div style={{color:'#ee0000'}}>결제완료</div>'로 변경하기</Button>
                        :null
                    } */}
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>작성일</ConditionName>
                <ValueBlock>
                    <div>{item.regist_time?timeValueToDateTime(item.regist_time):'-'}</div>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>RFID 정보</ConditionName>
                <ValueBlock>
                  {
                    ((item.state == 2 || item.state == 3) && rfidEditFlag)?
                    <div>
                      <StyledInput
                          name="keyword"
                          placeholder="RFID 값 입력"
                          type="number"
                          onChange={onChangeRFIDInput}
                          value={RFIDInput}
                      />
                      <Button style={{border:`1px solid ${palette.gray3}`}} onClick={()=>{onChangeRFID();setrfidEditFlag(false);}}>
                        <div style={{ paddingLeft: 10 }}>변경 완료</div>
                      </Button>
                    </div>
                    :
                    ((item.state == 2 || item.state == 3) && !rfidEditFlag)?
                    <div style={{display:'flex'}}>
                      <div>{item.RFID?item.RFID:"없음"}</div>
                      <div style={{width:200}}/>
                      <Button style={{border:`1px solid ${palette.gray3}`}} onClick={()=>{setrfidEditFlag(true)}}>
                        <div style={{ paddingLeft: 10 }}>수정 하기</div>
                      </Button>
                    </div>
                    :
                    <div>{item.RFID?item.RFID:"없음"}</div>
                  }
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>주민 번호</ConditionName>
                <ValueBlock>
                    <div>{item.jumin}-{item.jumin_next}</div>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>주민 등록상 주소</ConditionName>
                <ValueBlock>
                    <div>{item.addr} {item.addr_next} (우편번호 - {item.addr_post})</div>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>실제 거주 주소</ConditionName>
                <ValueBlock>
                    <div>{item.real_addr} {item.real_addr_next} (우편번호 - {item.real_addr_post})</div>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>이메일</ConditionName>
                <ValueBlock>
                    <div>{item.email}</div>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>사인</ConditionName>
                <ValueBlock>
                    <SignBox>
                      <img src={item.sign}
                        onClick={() => {  }} />
                    </SignBox>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>등록 이미지</ConditionName>
                <ValueBlock>
                  {
                    item.pat_image?
                    <ImageBox>
                      <img src={item.pat_image}
                        onClick={() => {  }} />
                    </ImageBox>:
                    <div>없음</div>
                  }
                </ValueBlock>
            </ConditionBlock>
            <ConditionLastBlock>
                <ConditionName>RFID 직접 입력</ConditionName>
                <ValueBlock>
                  <div>{item.input_RFID?item.input_RFID:""}</div>
                </ValueBlock>
            </ConditionLastBlock>
            {
                item && item.state >= 2?
                <SearchBlock>
                    <Button onClick={()=>setReportView(true)}>
                        <SearchButtonView>
                            <FiRotateCw></FiRotateCw>
                            <div style={{ paddingLeft: 10 }}>제출 서류 보기</div>
                        </SearchButtonView>
                    </Button>
                </SearchBlock>
                :null
            }
            {
                reportView?
                <ReportView item={item}/>
                :null
            }
        </MemberBlock>
    );
};

export default React.memo(MemberInfo);
