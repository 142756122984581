import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import Button from '@material-ui/core/Button';
import ConfirmModal from '../common/ConfirmModal';

const MemberBlock = styled.div`
  margin-top: 2.5rem;
`;

const PasswordBlock = styled.div`
  margin-top: 2.5rem;
  align-items: center;
  display:flex;
`;

const ValueBlock = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Noto Sans KR', sans-serif;

  ${(props) =>
    props.noFlex &&
    css`
      display: block;
    `}
`;
const ConditionBlock = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${palette.webGray[17]};
`;
const ConditionName = styled.div`
  padding: 20px 32px;
  background: #A5C1E7;
  text-align: center;
  margin-right: 20px;
  color: ${palette.white};
  font-family: 'Noto Sans kr';
  
  width: 300px;

  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
    padding: 20px 28px;
    min-width: 120px;
  }
`;

const StyledInput = styled.input`
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid ${palette.gray3};
  padding-bottom: 0.5rem;
  outline: none;
  width: 20%;
  margin-left: 1rem;
  margin-right: 1rem;
  &:focus {
    color: ${palette.blue1};
    border-bottom: 1px solid ${palette.gray7};
  }
  & + & {
    margin-top: 1rem;
  }
`;

const RFIDComponent = ({
    rfidInfo,
    onClickAdd,
    onClickDelete,
    addStartValue,
    addEndValue,
    onChangeAddStartValue,
    onChangeAddEndValue,
    deleteStartValue,
    deleteEndValue,
    onChangeDeleteStartValue,
    onChangeDeleteEndValue,
    modalVisible,
    setModal,
    onClickModalConform,
    modalText,
    renderState,
    password,
    setPassword,
    setLogin,
    authError,
}) => {
    if (!rfidInfo) {
        return <></>
    }
    if(renderState == 0)
    {
        return (<PasswordBlock>

        <StyledInput
            autoComplete="new-password"
            name="rfidpw"
            placeholder="비밀번호"
            type="password"
            onChange={(e)=>{
                setPassword(e.target.value);
            }}
            value={password}
        />
          <Button style={{backgroundColor:'#F1F1F1'}} onClick={setLogin}>인증</Button>
          <div style={{marginLeft:10, color:palette.red1}}>{authError}</div>
        </PasswordBlock>)
    }
    return (
        <MemberBlock>
            
            <ConfirmModal
                text={modalText}
                onClick={() => {
                    onClickModalConform();
                    setModal(false);
                }}
                setModal={() => {setModal(false)}}
                confirmText = "실행"
                onModal={modalVisible}
            />
            <ConditionBlock>
                <ConditionName>보유 RFID </ConditionName>
                <ValueBlock>
                    <div> {rfidInfo.totalMin}    ~    {rfidInfo.totalMax}  ({rfidInfo.totalCount}개)</div>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>사용한 RFID </ConditionName>
                <ValueBlock>
                    <div> {rfidInfo.usedMin}    ~    {rfidInfo.usedMax}  ({rfidInfo.usedCount}개)</div>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>미사용 RFID </ConditionName>
                <ValueBlock>
                    <div> {rfidInfo.totalCount-rfidInfo.usedCount}개</div>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>RFID 추가</ConditionName>
                <ValueBlock>
                    <input value={addStartValue} onChange={(e) => {
                        onChangeAddStartValue(e.target.value);
                    }}/>
                    <div style={{paddingRight:10, paddingLeft:10}}> ~ </div>
                    <input value={addEndValue} onChange={(e) => {
                        onChangeAddEndValue(e.target.value);
                    }}/>
                    <div style={{paddingRight:20, paddingLeft:20}}></div>
                    <Button style={{backgroundColor:'#F1F1F1'}} onClick={onClickAdd}>추가하기</Button>
                </ValueBlock>
            </ConditionBlock>
            <ConditionBlock>
                <ConditionName>RFID 삭제</ConditionName>
                <ValueBlock>
                    <input value={deleteStartValue} onChange={(e) => {
                        onChangeDeleteStartValue(e.target.value);
                    }}/>
                    <div style={{paddingRight:10, paddingLeft:10}}> ~ </div>
                    <input value={deleteEndValue} onChange={(e) => {
                        onChangeDeleteEndValue(e.target.value);
                    }}/>
                    <div style={{paddingRight:20, paddingLeft:20}}></div>
                    <Button style={{backgroundColor:'#F1F1F1'}} onClick={onClickDelete}>삭제하기</Button>
                </ValueBlock>
            </ConditionBlock>
        </MemberBlock>
    );
};

export default React.memo(RFIDComponent);
