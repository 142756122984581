import React from 'react';

import LoginPage from './pages/LoginPage';
import MemberListPage from './pages/member/MemberListPage';
import MemberPage from './pages/member/MemberPage';
import PrintPage from './pages/member/PrintPage';
import BuyPage from './pages/buy/BuyPage';
import RFIDPage from './pages/RFID/RFIDPage';
import SelectItem from './pages/selectItem/SelectItem';
import { Route } from 'react-router-dom';
import PetslookSmartStorePage from './pages/smartstore/PetslookSmartStorePage';
import MydingoSmartStorePage from './pages/smartstore/MydingoSmartStorePage';
import RegistPatMemberInfoPage from './pages/smartstore/RegistPatMemberInfoPage';
import RegistPat from './pages/registpat/registpat';

const App = () => {

  return (
    <>
      <Route component={LoginPage} path={['/', '/login']} exact />
      <Route component={MemberListPage} path="/member" exact />
      <Route component={RFIDPage} path="/rfid" exact />
      <Route component={BuyPage} path="/buy" exact />
      <Route component={MemberPage} path="/member/info" exact />
      <Route component={PrintPage} path="/member/print" exact />
      <Route component={SelectItem} path="/selectitem" exact />
      <Route component={PetslookSmartStorePage} path="/petslooksmartstore" exact />
      <Route component={MydingoSmartStorePage} path="/mydingosmartstore" exact />
      <Route component={RegistPat} path="/registpat" exact />
      <Route component={RegistPatMemberInfoPage} path="/registpat/memberinfo" exact />
    </>
  );
};

export default App;
