import React, { useState } from 'react';
import palette from '../../lib/styles/palette';
import styled from 'styled-components';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
`;

const Screen = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Modal = styled.div`
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;
`;

const ConfirmMsgBox = styled.div`
  min-width: 380px;
  min-height: 100px;
  max-width: 465px;
  max-height: 145px;
  padding: 24px;
  font-size: 16px;
  white-space: pre-line;
  word-break: keep-all;
  line-height: 130%;
  color: #3A3A3C;
  text-align: left;
`;

const BtnBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 16px;
  background-color: #F9F9F9;
`;

const BtnConfirm = styled.button`
  padding: 8px 24px;
  color: #fff;
  background-color: #3377FF;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px;
  border: 0;
  
  &.delete {
    background-color: #FF5C5C;
  }
`;

const BtnCancel = styled.button`
  padding: 8px 24px;
  color: #3A3A3C;
  background-color: #fff;
  border-radius: 6px;
  font-size: 16px;
  border: 1px solid #DDE5E9;
  box-sizing: content-box;
  cursor: pointer;
`;


const ConfirmModal = ({
  isDelete,
  text,
  onClick,
  setModal,
  confirmText,
  cancelText,
  onModal
}) => {
    if(!onModal) {
      return null
    }

    return(
        <>
                  <Background>
                    <Screen onClick={() => {setModal(false)}} />
                    <Modal>
                        <ConfirmMsgBox>
                          {text}
                        </ConfirmMsgBox>
                        <BtnBox>
                            <BtnConfirm className={isDelete ? "delete" : ""} onClick={() => {onClick()}}>{confirmText}</BtnConfirm>
                            <BtnCancel onClick={() => {setModal(false)}}>{cancelText? cancelText : "취소"}</BtnCancel>
                        </BtnBox>
                    </Modal>
                  </Background>
        </>
    )
}

export default ConfirmModal;