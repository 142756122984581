
import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import Select from 'react-select';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
import NewWindow from 'react-new-window';
import KakaoPost from '../common/KakaoPost';
import SignatureCanvas from 'react-signature-canvas'


const kind = [
    {label:"품종을 선택해 주세요.", value:"0"},
    {label:"골든 리트리버", value:"1"},
    {label:"그레이 하운드", value:"2"},
    {label:"그레이트 덴", value:"3"},
    {label:"그레이트 피레니즈", value:"4"},
    {label:"기타", value:"5"},
    {label:"꼬똥 드 뚤레아", value:"6"},
    {label:"네오폴리탄 마스티프", value:"7"},
    {label:"노르포크 테리어", value:"8"},
    {label:"노리치 테리어", value:"9"},
    {label:"노퍽 테리어", value:"10"},
    {label:"뉴펀들랜드", value:"11"},
    {label:"닥스훈트", value:"12"},
    {label:"달마시안", value:"13"},
    {label:"댄디 딘몬트 테리어", value:"14"},
    {label:"도고 까니리오", value:"15"},
    {label:"도고 아르젠티노", value:"16"},
    {label:"도고 아르젠티노", value:"17"},
    {label:"도베르만", value:"18"},
    {label:"도사", value:"19"},
    {label:"도사 믹스견", value:"20"},
    {label:"동경견", value:"21"},
    {label:"라브라도 리트리버", value:"22"},
    {label:"라사 압소", value:"23"},
    {label:"라이카", value:"24"},
    {label:"래빗 닥스훈드", value:"25"},
    {label:"랫 테리어", value:"26"},
    {label:"레이크랜드 테리어", value:"27"},
    {label:"로디지안 리즈백 ", value:"28"},
    {label:"로트와일러", value:"29"},
    {label:"로트와일러", value:"30"},
    {label:"로트와일러 믹스견", value:"31"},
    {label:"마리노이즈", value:"32"},
    {label:"마스티프", value:"33"},
    {label:"말라뮤트", value:"34"},
    {label:"말티즈", value:"35"},
    {label:"맨체스터테리어", value:"36"},
    {label:"미니어쳐 닥스훈트", value:"37"},
    {label:"미니어쳐 불 테리어", value:"38"},
    {label:"미니어쳐 슈나우저", value:"39"},
    {label:"미니어쳐 푸들", value:"40"},
    {label:"미니어쳐 핀셔", value:"41"},
    {label:"미디엄 푸들", value:"42"},
    {label:"미텔 스피츠", value:"43"},
    {label:"믹스견", value:"44"},
    {label:"바센지", value:"45"},
    {label:"바셋 하운드", value:"46"},
    {label:"버니즈 마운틴 독", value:"47"},
    {label:"베들링턴 테리어", value:"48"},
    {label:"벨기에 그로넨달", value:"49"},
    {label:"벨기에 쉽독", value:"50"},
    {label:"벨기에 테뷰런", value:"51"},
    {label:"벨지안 셰퍼드 독", value:"52"},
    {label:"보더 콜리", value:"53"},
    {label:"보르조이", value:"54"},
    {label:"보스턴 테리어", value:"55"},
    {label:"복서", value:"56"},
    {label:"볼로네즈", value:"57"},
    {label:"부비에 데 플랑드르", value:"58"},
    {label:"불 테리어", value:"59"},
    {label:"불독", value:"60"},
    {label:"브뤼셀그리펀", value:"61"},
    {label:"브리타니 스파니엘", value:"62"},
    {label:"블랙 테리어", value:"63"},
    {label:"비글", value:"64"},
    {label:"비숑 프리제", value:"65"},
    {label:"비어디드 콜리", value:"66"},
    {label:"비즐라", value:"67"},
    {label:"빠삐용", value:"68"},
    {label:"사모예드", value:"69"},
    {label:"살루키", value:"70"},
    {label:"삽살개", value:"71"},
    {label:"샤페이", value:"72"},
    {label:"세인트 버나드", value:"73"},
    {label:"센트럴 아시안 오브차카", value:"74"},
    {label:"셔틀랜드 쉽독", value:"75"},
    {label:"셰퍼드", value:"76"},
    {label:"슈나우져", value:"77"},
    {label:"스코티쉬 테리어", value:"78"},
    {label:"스코티시 디어하운드", value:"79"},
    {label:"스태퍼드셔 불 테리어", value:"80"},
    {label:"스태퍼드셔 불 테리어 믹스견", value:"81"},
    {label:"스탠다드 푸들", value:"82"},
    {label:"스피츠", value:"83"},
    {label:"시바", value:"84"},
    {label:"시베리안 허스키", value:"85"},
    {label:"시베리안라이카", value:"86"},
    {label:"시잉프랑세즈", value:"87"},
    {label:"시츄", value:"88"},
    {label:"시코쿠", value:"89"},
    {label:"실리햄 테리어", value:"90"},
    {label:"실키테리어", value:"91"},
    {label:"아나톨리안 셰퍼드", value:"92"},
    {label:"아메리칸 불독", value:"93"},
    {label:"아메리칸 스태퍼드셔 테리어", value:"94"},
    {label:"아메리칸 스태퍼드셔 테리어 믹스견", value:"95"},
    {label:"아메리칸 아키다", value:"96"},
    {label:"아메리칸 에스키모", value:"97"},
    {label:"아메리칸 코카 스파니엘", value:"98"},
    {label:"아메리칸 핏불 테리어", value:"99"},
    {label:"아메리칸 핏불 테리어 믹스견", value:"100"},
    {label:"아메리칸불리", value:"101"},
    {label:"아이리쉬 레드 앤 화이트 세터", value:"102"},
    {label:"아이리쉬 세터", value:"103"},
    {label:"아이리쉬 울프 하운드", value:"104"},
    {label:"아이리쉬소프트코튼휘튼테리어", value:"105"},
    {label:"아키다", value:"106"},
    {label:"아프간 하운드", value:"107"},
    {label:"알라스칸 말라뮤트", value:"108"},
    {label:"에어델 테리어", value:"109"},
    {label:"오브차카", value:"110"},
    {label:"오스트랄리안 셰퍼드 독", value:"111"},
    {label:"오스트랄리안 캐틀 독", value:"112"},
    {label:"오스트레일리안 켈피", value:"113"},
    {label:"올드 잉글리쉬 불독", value:"114"},
    {label:"올드 잉글리쉬 쉽독", value:"115"},
    {label:"와이마라너", value:"116"},
    {label:"와이어 폭스 테리어", value:"117"},
    {label:"요크셔 테리어", value:"118"},
    {label:"울프독", value:"119"},
    {label:"웨 시베리언 라이카", value:"120"},
    {label:"웨스트하이랜드화이트테리어", value:"121"},
    {label:"웰시 코기 카디건", value:"122"},
    {label:"웰시 코기 펨브로크", value:"123"},
    {label:"웰시 테리어", value:"124"},
    {label:"이탈리안 그레이 하운드", value:"125"},
    {label:"잉글리쉬 세터", value:"126"},
    {label:"잉글리쉬 스프링거 스파니엘", value:"127"},
    {label:"잉글리쉬 코카 스파니엘", value:"128"},
    {label:"잉글리쉬 포인터", value:"129"},
    {label:"자이언트 슈나우져", value:"130"},
    {label:"재패니즈 스피츠", value:"131"},
    {label:"잭 러셀 테리어", value:"132"},
    {label:"저먼 셰퍼드 독", value:"133"},
    {label:"저먼 와이어헤어드 포인터", value:"134"},
    {label:"저먼 포인터", value:"135"},
    {label:"저 헌팅 테리어", value:"136"},
    {label:"제주개", value:"137"},
    {label:"제페니즈칭", value:"138"},
    {label:"진도견", value:"139"},
    {label:"차우차우", value:"140"},
    {label:"차이니즈 크레스티드 독", value:"141"},
    {label:"치와와", value:"142"},
    {label:"카레리안 베어독", value:"143"},
    {label:"카이훗", value:"144"},
    {label:"캐벌리어 킹 찰스 스파니엘", value:"145"},
    {label:"케니스펜더", value:"146"},
    {label:"케리 블루 테리어", value:"147"},
    {label:"케언 테리어", value:"148"},
    {label:"케인 코르소", value:"149"},
    {label:"코리아 트라이 하운드", value:"150"},
    {label:"코리안 마스티프", value:"151"},
    {label:"코카 스파니엘", value:"152"},
    {label:"코카 푸", value:"153"},
    {label:"코카시안오브차카", value:"154"},
    {label:"콜리", value:"155"},
    {label:"클라인스피츠", value:"156"},
    {label:"키슈", value:"157"},
    {label:"키스 훈드", value:"158"},
    {label:"토이 맨체스터 테리어", value:"159"},
    {label:"토이 푸들", value:"160"},
    {label:"티베탄 마스티프", value:"161"},
    {label:"파라오 하운드", value:"162"},
    {label:"파슨 러셀 테리어", value:"163"},
    {label:"팔렌", value:"164"},
    {label:"퍼그", value:"165"},
    {label:"페키니즈", value:"166"},
    {label:"페터데일테리어", value:"167"},
    {label:"포메라니안", value:"168"},
    {label:"포인터", value:"169"},
    {label:"폭스테리어", value:"170"},
    {label:"푸들", value:"171"},
    {label:"풀리", value:"172"},
    {label:"풍산견", value:"173"},
    {label:"프레사까나리오", value:"174"},
    {label:"프렌치 불독", value:"175"},
    {label:"프렌치 브리타니", value:"176"},
    {label:"플랫 코티드 리트리버", value:"177"},
    {label:"플롯하운드", value:"178"},
    {label:"피레니안 마운틴 독", value:"179"},
    {label:"필라 브라질레이로", value:"180"},
    {label:"핏불테리어", value:"181"},
    {label:"핏불테리어 믹스견", value:"182"},
    {label:"허배너스", value:"183"},
    {label:"화이트리트리버", value:"184"},
    {label:"화이트테리어", value:"185"},
    {label:"휘펫", value:"186"},
];

const colorList = [
    { label:"색깔을 선택하세요.", value:"0" },
    { label:"APRICOT", value:"1" },
    { label:"BLACK TAN&WHITE", value:"2" },
    { label:"BLUE GRAY&WHITE", value:"3" },
    { label:"BLUE&GOLD", value:"4" },
    { label:"BLUE&TAN", value:"5" },
    { label:"GOLD&WHITE", value:"6" },
    { label:"GRAY BRINDLE", value:"7" },
    { label:"GRAY&BLACK", value:"8" },
    { label:"GRAY&WHITE", value:"9" },
    { label:"GRIZZLE", value:"10" },
    { label:"LEMON&WHITE", value:"11" },
    { label:"RED FAWN", value:"12" },
    { label:"RED&WHITE", value:"13" },
    { label:"SABLE&WHITE", value:"14" },
    { label:"SILVER&WHITE", value:"15" },
    { label:"STEEL BLUE&TAN", value:"16" },
    { label:"TAN", value:"17" },
    { label:"WHEATAN", value:"18" },
    { label:"WHITE BLACK&TAN", value:"19" },
    { label:"WHITE&TAN", value:"20" },
    { label:"WOLF GRAY", value:"21" },
    { label:"갈색", value:"22" },
    { label:"갈색&흰색", value:"23" },
    { label:"검은털", value:"24" },
    { label:"검정&은색", value:"25" },
    { label:"검정색", value:"26" },
    { label:"금갈색", value:"27" },
    { label:"금색", value:"28" },
    { label:"금청색", value:"29" },
    { label:"기타", value:"30" },
    { label:"노란색", value:"31" },
    { label:"밤색", value:"32" },
    { label:"브라운계열", value:"33" },
    { label:"블랙탄", value:"34" },
    { label:"블레넘", value:"35" },
    { label:"블루섞인그레이", value:"36" },
    { label:"빨간색", value:"37" },
    { label:"살구색", value:"38" },
    { label:"세가지색", value:"39" },
    { label:"어두운검정색", value:"40" },
    { label:"어두운청색", value:"41" },
    { label:"얼룩무늬", value:"42" },
    { label:"원파탕&흰색", value:"43" },
    { label:"적갈&흰색", value:"44" },
    { label:"적갈&흰색&회색", value:"45" },
    { label:"적갈색", value:"46" },
    { label:"주황&흰색", value:"47" },
    { label:"청색", value:"48" },
    { label:"쵸콜릿색", value:"49" },
    { label:"크림색", value:"50" },
    { label:"털끝검은체모색", value:"51" },
    { label:"파란금속색", value:"52" },
    { label:"호반&흰색", value:"53" },
    { label:"호반색", value:"54" },
    { label:"혼합색", value:"55" },
    { label:"황갈&흰색", value:"56" },
    { label:"황갈색", value:"57" },
    { label:"황색", value:"58" },
    { label:"회색", value:"59" },
    { label:"회색계열", value:"60" },
    { label:"흑갈색", value:"61" },
    { label:"흑색&회색", value:"62" },
    { label:"흰색", value:"63" },
    { label:"흰색&검은반점", value:"64" },
];

const RegistPatView = ({
    inputData,
    onChangeInputData,
    imgRef,
    signRef,
    onCommit,
    imgFile,
    setImgFile,
    viewType,
}) => {
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('addr');

    const saveImgFile = () => {
        const file = imgRef.current.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImgFile(reader.result);
           };
    };

    const bgColor = viewType=='mydingo'?'#005DAB':'#E96061'

    return (
        <div style={{background:'#F8F8F8'}}>
            <div
                className={`w-full px-5 py-5 text-2xl text-white font-semibold font-sans inline-block align-middle mb-6`}
                style={{background:bgColor}}
            >
                <div className='inline-block alialign-middle font-sans'>동물등록</div>
            </div>
            <div className=' bg-slate-50 bg-auto px-5 py-5 md:max-[2048px]:px-10 md:max-[2048px]:py-10 w-full font-sans text-sm md:max-[2048px]:text-base'>
                <div className=' flex w-full pb-6 pr-2'>
                    <div className='w-1/3'><span className=' text-red-600 pr-1'>*</span>이름</div>
                    <div className='w-2/3'>
                        <input
                            className=" bg-white border border-gray-300 text-gray-950 text-sm font-semibold rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-sans"
                            onChange={(e) => {
                                onChangeInputData('name', e.target.value);
                            }}
                            value={inputData.name}
                            placeholder=""
                            type="text"
                        >
                        </input>
                    </div>
                </div>
                <div className=' flex w-full pb-6 pr-2'>
                    <div className='w-1/3'><span className=' text-red-600 pr-1'>*</span>핸드폰 번호</div>
                    <div className='w-2/3'>
                        <input
                            className=" bg-white border border-gray-300 text-gray-950 text-sm font-semibold rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-sans"
                            onChange={(e) => {
                                onChangeInputData('phone', e.target.value);
                            }}
                            value={inputData.phone}
                            placeholder=""
                            type='text'
                        >
                        </input>
                    </div>
                </div>
                <div className=' flex w-full pb-6 pr-2'>
                    <div className='w-1/3'>이메일 주소</div>
                    <div className='w-2/3'>
                        <input
                            className=" bg-white border border-gray-300 text-gray-950 text-sm font-semibold rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-sans"
                            onChange={(e) => {
                                onChangeInputData('email', e.target.value);
                            }}
                            value={inputData.email}
                            placeholder=""
                            type='email'
                        >
                        </input>
                    </div>
                </div>
                <div className=' flex w-full pb-6'>
                    <div className='w-1/3'><span className=' text-red-600 pr-1'>*</span>주민 번호</div>
                    <div className='w-2/3 flex '>
                        <input
                            className=" bg-white border border-gray-300 text-gray-950 text-sm font-semibold rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-sans"
                            onChange={(e) => {
                                onChangeInputData('jumin', e.target.value);
                            }}
                            value={inputData.jumin}
                            placeholder=""
                            type='text'
                        >
                        </input>
                        -
                        <input
                            className=" bg-white border border-gray-300 text-gray-950 text-sm font-semibold rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-sans"
                            onChange={(e) => {
                                onChangeInputData('jumin_next', e.target.value);
                            }}
                            value={inputData.jumin_next}
                            placeholder=""
                            type='password'
                        >
                        </input>
                    </div>
                </div>
                <div className=' flex w-full pb-6'>
                    <div className='w-1/3'><span className=' text-red-600 pr-1'>*</span>주민 등록 상 주소</div>
                    <div className='w-2/3 pr-2'>
                        <input
                            className=" bg-white border border-gray-300 text-gray-950 text-sm font-semibold rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-sans cursor-pointer"
                            placeholder="주소를 검색하세요."
                            type='text'
                            readOnly
                            value={inputData.addr}
                            onClick={() => {
                                setModal(true)
                                setModalType('addr')
                            }}
                        >
                        </input>
                        <input
                            className=" mt-2 bg-white border border-gray-300 text-gray-950 text-sm font-semibold rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-sans"
                            onChange={(e) => {
                                onChangeInputData('addr_next', e.target.value);
                            }}
                            value={inputData.addr_next}
                            placeholder="상세 주소를 입력하세요."
                            type='text'
                        >
                        </input>
                    </div>
                </div>
                <div className=' flex w-full pb-6'>
                    <div className='w-1/3'><span className=' text-red-600 pr-1'>*</span>현재 거주 주소지</div>
                    <div className='w-2/3 pr-2'>
                        <div>주민 등록상 주소와 현 거주지 주소가 같은가요?</div>
                        <div className="flex items-center p-2">
                            <input
                                checked={inputData.addr_copy == 1}
                                id="addr_copy-radio-1"
                                type="radio"
                                name="addr_copy-radio"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(e)=>{
                                    onChangeInputData('addr_copy', 1)
                                }}
                            />
                            <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 font-sans">네</div>
                        </div>
                        <div className="flex items-center p-2">
                            <input
                                checked={inputData.addr_copy == 2}
                                id="addr_copy-radio-2"
                                type="radio"
                                name="addr_copy-radio"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(e)=>{
                                    onChangeInputData('addr_copy', 2)
                                }}
                            />
                            <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 font-sans">아니요</div>
                        </div>
                        <input
                            className=" bg-white border border-gray-300 text-gray-950 text-sm font-semibold rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-sans cursor-pointer"
                            placeholder="주소를 검색하세요."
                            type='text'
                            readOnly
                            value={inputData.real_addr}
                            onClick={() => {
                                setModal(true)
                                setModalType('real')
                            }}
                        >
                        </input>
                        <input
                            className=" mt-2 bg-white border border-gray-300 text-gray-950 text-sm font-semibold rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-sans"
                            onChange={(e) => {
                                onChangeInputData('real_addr_next', e.target.value);
                            }}
                            value={inputData.real_addr_next}
                            placeholder="상세 주소를 입력하세요."
                            type='text'
                        >
                        </input>
                    </div>
                </div>
                <div className=' flex w-full pb-6'>
                    <div className='w-1/3'><span className=' text-red-600 pr-1'>*</span>반려동물 이름</div>
                    <div className='w-2/3 pr-2'>
                        <input
                            className=" bg-white border border-gray-300 text-gray-950 text-sm font-semibold rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-sans"
                            onChange={(e) => {
                                onChangeInputData('pat_name', e.target.value);
                            }}
                            value={inputData.pat_name}
                            placeholder=""
                            type='text'
                        >
                        </input>
                    </div>
                </div>
                <div className=' flex w-full pb-6'>
                    <div className='w-1/3'><span className=' text-red-600 pr-1'>*</span>품종 및 털색깔</div>
                    <div className='w-2/3 pr-2'>
                        <Select
                            className='pb-2 rounded-md border-0 bg-transparent py-0 pl-2 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                            isDisabled={kind.length == 0}
                            options={kind}
                            isSearchable={false}
                            //placeholder={`목걸이 색상을 선택하세요.`}
                            placeholder={``}
                            aria-labelledby="select"
                            onChange={(e) =>{
                                if(e.value == '0')
                                {
                                    
                                }
                                onChangeInputData('pat_kind', e.label)
                            }}
                            maxMenuHeight={'300px'}
                            value={kind.filter((kind) => {
                                return kind.label === inputData.pat_kind;
                            })}
                        />
                        <Select
                            className='pb-2 rounded-md border-0 bg-transparent py-0 pl-2 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                            isDisabled={colorList.length == 0}
                            options={colorList}
                            isSearchable={false}
                            //placeholder={`목걸이 색상을 선택하세요.`}
                            placeholder={``}
                            aria-labelledby="select"
                            onChange={(e) =>{
                                if(e.value == '0')
                                {
                                    
                                }
                                onChangeInputData('pat_color', e.label)
                            }}
                            maxMenuHeight={'300px'}
                            value={colorList.filter((colordata) => {
                                return colordata.label === inputData.pat_color;
                            })}
                        />
                    </div>
                </div>
                <div className=' flex w-full pb-6'>
                    <div className='w-1/3'><span className=' text-red-600 pr-1'>*</span>반려동물 성별</div>
                    <div className='w-2/3'>
                        <div className="flex items-center p-2">
                            <input
                                checked={inputData.pat_sex == '1'}
                                id="sex-radio-1"
                                type="radio"
                                name="sex-radio"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(e)=>{
                                    onChangeInputData('pat_sex', '1')
                                }}
                            />
                            <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 font-sans">남아</div>
                        </div>
                        <div className="flex items-center p-2">
                            <input
                                checked={inputData.pat_sex == '2'}
                                id="sex-radio-2"
                                type="radio"
                                name="sex-radio"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(e)=>{
                                    onChangeInputData('pat_sex', '2')
                                }}
                            />
                            <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 font-sans">여아</div>
                        </div>
                    </div>
                </div>
                <div className=' flex w-full pb-6'>
                    <div className='w-1/3'><span className=' text-red-600 pr-1'>*</span>중성화 여부</div>
                    <div className='w-2/3'>
                        <div className="flex items-center p-2">
                            <input
                                checked={inputData.pat_neutering == '1'}
                                id="neutering-radio-1"
                                type="radio"
                                name="neutering-radio"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(e)=>{
                                    onChangeInputData('pat_neutering', '1')
                                }}
                            />
                            <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 font-sans">네</div>
                        </div>
                        <div className="flex items-center p-2">
                            <input
                                checked={inputData.pat_neutering == '0'}
                                id="neutering-radio-2"
                                type="radio"
                                name="neutering-radio"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                                onChange={(e)=>{
                                    onChangeInputData('pat_neutering', '0')
                                }}
                            />
                            <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 font-sans">아니요</div>
                        </div>
                    </div>
                </div>
                <div className=' flex w-full pb-6'>
                    <div className='w-1/3'><span className=' text-red-600 pr-1'>*</span>반려동물 출생일</div>
                    <div className='w-2/3 pr-2 pl-2'>
                        <input
                            type='date'
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={(e)=>{
                                onChangeInputData('pat_birth', e.target.value)
                            }}
                            value={inputData.pat_birth}
                        />
                    </div>
                </div>
                <div className=' flex w-full pb-6'>
                    <div className='w-1/3'><span className=' text-red-600 pr-1'>*</span>반려동물 입양일</div>
                    <div className='w-2/3 pr-2 pl-2'>
                        <input
                            type='date'
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={(e)=>{
                                onChangeInputData('pat_registTime', e.target.value)
                            }}
                            value={inputData.pat_registTime}
                        />
                    </div>
                </div>
                <div className=' flex w-full pb-6'>
                    <div className='w-1/3'>반려동물 사진</div>
                    <div className='w-2/3'>
                        {
                            imgFile?
                            <img
                                className='h-48 w-48 mb-2'
                                src={imgFile ? imgFile :``}
                                alt="프로필 이미지"
                            />
                            :null
                        }
                        <input
                            type="file"
                            accept='image/*'
                            id="profileImg"
                            onChange={saveImgFile}
                            ref={imgRef}
                        >
                        </input>
                    </div>
                </div>
                <div className=' flex w-full pb-6'>
                    <div className='w-1/3'>RFID 번호 입력</div>
                    <div className='w-2/3 pr-2'>
                        <input
                            className=" bg-white border border-gray-300 text-gray-950 text-sm font-semibold rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mx-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-sans"
                            onChange={(e) => {
                                onChangeInputData('input_RFID', e.target.value);
                            }}
                            value={inputData.input_RFID}
                            placeholder=""
                            type='text'
                        >
                        </input>
                        <div className='py-3'>{`주의사항 : 이미 RFID 번호를 가지고 계신분만 입력하세요.\n온/오프라인에서 외장칩을 구매하신 고객님께서는 외장칩 뒷면 혹은 바코드에 적힌 등록번호 15자리를 입력해주세요. 기재하시는 RFID 번호로 동물등록이 진행됩니다.`}</div>
                    </div>
                </div>
                <div className=' flex w-full pb-4'>
                    <div className='w-1/3'><span className=' text-red-600 pr-1'>*</span>서명</div>
                    <div className='w-1/3 flex mr-5'>
                        <SignatureCanvas
                            penColor='black'
                            ref={signRef}
                            canvasProps={{className: 'sigCanvas border-2 md:h-[14rem] md:w-[28rem] h-[10rem] w-[10rem]'}}
                        />
                    </div>
                </div>
                <div className=' flex w-full pb-4'>
                    <input
                        checked={inputData.terms_total == '1'}
                        onChange={(e)=>{
                            console.log('e : ', e)
                            onChangeInputData('terms_total', inputData.terms_total == '1'?'':'1')
                        }}
                        id="checkbox-1"
                        type="checkbox"
                        value=""
                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    >
                    </input>
                    <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">전체 선택</div>
                </div>
                <div className=' flex w-full pb-2'>
                    <input
                        checked={inputData.terms_1 == '1'}
                        onChange={(e)=>{
                            onChangeInputData('terms_1', inputData.terms_1 == '1'?'':'1')
                        }}
                        id="checkbox-2"
                        type="checkbox"
                        value=""
                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    >
                    </input>
                    <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">(필수)동물등록대행업체(마이딩고) 대상 정보 제공 동의(제공항목: 동물등록 접수에 필요한 정보로 성명, 주민등록번호, 전화번호, 주민등록지상 주소, 거주 주소, 반려동물 정보)</div>
                </div>
                <div className=' flex w-full pb-2'>
                    <input
                        checked={inputData.terms_2 == '1'}
                        onChange={(e)=>{
                            onChangeInputData('terms_2', inputData.terms_2 == '1'?'':'1')
                        }}
                        id="checkbox-3"
                        type="checkbox"
                        value=""
                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    >
                    </input>
                    <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">(필수)「동물보호법」 제 12조 제 1항·제2항 및 동 법 시행규칙 제 8조 제 1항 및 제 9조 제 2항에 따른 동물등록 신청에 동의</div>
                </div>
                <div className=' flex w-full pb-2'>
                    <input
                        checked={inputData.terms_3 == '1'}
                        onChange={(e)=>{
                            onChangeInputData('terms_3', inputData.terms_3 == '1'?'':'1')
                        }}
                        id="checkbox-4"
                        type="checkbox"
                        value=""
                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    >
                    </input>
                    <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">(필수)「전자정부법」 제 36조 1항에 따라 담당 공무원이 주민등록표 초본을 확인하는 것에 동의</div>
                </div>
                <div className=' flex w-full pb-2'>
                    <input
                        checked={inputData.terms_4 == '1'}
                        onChange={(e)=>{
                            onChangeInputData('terms_4', inputData.terms_4 == '1'?'':'1')
                        }}
                        id="checkbox-5"
                        type="checkbox"
                        value=""
                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    >
                    </input>
                    <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">(필수) 신고 내용에 대하여 유효 기간 내 수집 및 이용에 동의하며, 유기·유실동물 반환 등의 목적 이용에 동의</div>
                </div>
                <div className=' flex w-full pb-2'>
                    <input
                        checked={inputData.terms_5 == '1'}
                        onChange={(e)=>{
                            onChangeInputData('terms_5', inputData.terms_5 == '1'?'':'1')
                        }}
                        id="checkbox-6"
                        type="checkbox"
                        value=""
                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    >
                    </input>
                    <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">(필수) 유기ㆍ유실동물의 반환 등의 목적으로 등록대상동물의 소유자의 정보와 등록내용을 활용할 수 있도록 해당 지방자치단체 등에 제공함에 동의</div>
                </div>
                <div className=' flex w-full pb-2'>
                    <input
                        checked={inputData.terms_6 == '1'}
                        onChange={(e)=>{
                            onChangeInputData('terms_6', inputData.terms_6 == '1'?'':'1')
                        }}
                        id="checkbox-7"
                        type="checkbox"
                        value=""
                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                    >
                    </input>
                    <div className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">(필수) 상품 결제시 동물등록 번호가 즉시 부여되며 동물등록 번호 발급 이후에는 주문취소 불가함에 동의</div>
                </div>

                <div className='flex justify-center items-center mb-16 mt-10'>
                    <div
                        className='rounded w-11/12 h-20 flex justify-center items-center font-sans font-semibold text-2xl text-white cursor-pointer'
                        style={{background:bgColor}}
                        onClick={onCommit}
                    >
                        동물 등록 완료
                    </div>
                </div>
                {/* <div className='w-full flex align-middle justify-center items-center'>
                    <div className='w-1/3 h-10 flex bg-slate-900 text-white cursor-pointer justify-center items-center font-sans text-xl' onClick={onCommit}><div>동물 등록</div></div>
                </div> */}
                {modal ? (
                <Background >
                    <Screen onClick={() => {setModal(false)}} />
                    <Modal>
                    <KakaoPost findAddress={(address) => {
                        if (modalType == 'addr') {
                            onChangeInputData('addr', address);
                        }
                        else {
                            onChangeInputData('real_addr', address);
                        }
                        setModal(false)
                    }} />

                    </Modal>
                </Background>
                    // <NewWindow
                    //     onUnload={() => setModal(false)}
                    // >
                    //     <KakaoPost findAddress={(address) => {
                    //         if (modalType == 'addr') {
                    //             onChangeInputData('addr', address);
                    //         }
                    //         else {
                    //             onChangeInputData('real_addr', address);
                    //         }
                    //         setModal(false)
                    //     }} />
                    // </NewWindow>
                ) : undefined}
            </div>
        </div>
    );
};

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 999;
`;

const Screen = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  z-index: -1;
`;
const Modal = styled.div`
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;
  margin-top:350px;
  width:50%;
  height:200px;
`;

export default RegistPatView;

/*
CREATE TABLE `User` (
  `uuid` varchar(30) NOT NULL,
  `userid` varchar(20) NOT NULL,
  `regist_time` int NOT NULL,
  `name` varchar(20) NOT NULL,
  `phone` varchar(20) NOT NULL,
  `email` varchar(50) NOT NULL,
  `jumin` varchar(8) NOT NULL,
  `jumin_next` varchar(10) NOT NULL,
  `addr` varchar(100) NOT NULL,
  `addr_next` varchar(100) NOT NULL,
  `addr_post` varchar(15) NOT NULL,
  `real_addr` varchar(100) NOT NULL,
  `real_addr_next` varchar(100) NOT NULL,
  `real_addr_post` varchar(15) NOT NULL,
  `pat_name` varchar(20) NOT NULL,
  `pat_image` varchar(300) NOT NULL,
  `pat_type` varchar(4) NOT NULL,
  `pat_kind` varchar(30) NOT NULL,
  `pat_color` varchar(30) NOT NULL,
  `pat_sex` varchar(4) NOT NULL,
  `pat_neutering` varchar(4) NOT NULL,
  `pat_birth` int NOT NULL,
  `pat_registTime` int NOT NULL,
  `sign` varchar(300) NOT NULL,
  `state` int NOT NULL,
  `product_name` varchar(100) DEFAULT NULL,
  `product_option` varchar(100) DEFAULT NULL,
  `RFID` bigint DEFAULT NULL,
  `buy_time` int NOT NULL,
  `input_RFID` bigint DEFAULT NULL,
  `price` varchar(10) NOT NULL DEFAULT ''
) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci
*/