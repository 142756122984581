import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CommonButton from '../common/Button';
import palette from '../../lib/styles/palette';
import { FiRotateCw } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { inputNumberAutoComma, timeValueToDate, timeValueToDate6, timeValueToDateTime } from '../../lib/commonFunc/commonFunc';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Pagination from '@material-ui/lab/Pagination';

const MemberBlock = styled.div`
  margin-top: 2.5rem;
`;
const ConditionBlock = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${palette.webGray[17]};
`;
const ConditionName = styled.div`
  padding: 20px 32px;
  background: #A5C1E7;
  text-align: center;
  margin-right: 20px;
  color: ${palette.white};
  font-family: 'Noto Sans kr';
  min-width: 150px;

  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
    padding: 20px 28px;
    min-width: 120px;
  }
`;
const StyledForm = styled.form`
  display: flex;
  align-items: center;
`;
const ValueBlock = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.noFlex &&
    css`
      display: block;
    `}
`;

const TextSpace = styled.div`
  padding: 0 10px;
  font-size: 20pt;
`;
const SearchBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 0;
    margin-bottom: 0;
`;
const SearchButtonView = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[17]};
`;
const TD = styled.td`
  text-align: center;
  min-width: 60px;
  width: 8%;
`;

const Basetd = styled.td`
  text-align: center;
  min-width: 150px;
`;

const ImageBox = styled.div`
  height: 50px;
  justify-content: center;
  align-items: center;

  & img {
    width: 50px;
    height: 50px;
  }
`;
const SearchListBlock = styled.div`
  padding-top: 40px;
    ${(props) => props.height ? css`height:${props.height}vh;` : css`height: auto;`}
`;

const MemberTable = styled.table`
  width: 100%;
  text-align: center;
  table-layout: fixed;
`
const MemberTableHeader = styled.thead`
  width: 100%;
  border-top: 1px solid ${palette.black1};
  border-bottom: 1px solid ${palette.webGray[5]};

  & tr{
    cursor: default;
    & td{
      font-weight: bold;
      padding: 12px 10px;
    }
  }
`

const MemberTableBody = styled.tbody`
  width: 100%;
  & tr {
    width: 100%;
  }
  
  & td {    
    font-size: .95rem;
    border-bottom: 1px solid ${palette.webGray[17]};
    text-overflow:ellipsis; 
    overflow:hidden; 
    white-space:nowrap;
    margin-top:10px;
    margin-bottom:10px;
  }
  
  & td:nth-child(1) {    
  }
`

const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;
  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }
`;

const StyledInput = styled.input`
  font-size: 1rem;
  border: 1px solid ${palette.gray3};
  padding: 0.5rem;
  outline: none;
  width: 300px;
  margin-left: 1rem;
  margin-right: 1rem;
  &:focus {
    color: ${palette.blue1};
  }
  & + & {
    margin-top: 1rem;
  }
`;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  input: {
    width: '65px',
  },
  buttons: {
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const MydingoSmartStoreCompoment = ({
  onChangeStartTime,
  onChangeEndTime,
  onSetPeriod,
  onChangeKeyword,
  searchInfo,
  onSearch,
  onChangePage,
  page,
  onDownload,
  filter,
  setFilter,
  filterList,
}) => {
  const {
    loading
  } = useSelector(({ loading }) => ({
    loading: loading[`smartstore/LOAD_ODER_LIST`],
  }));

  let startindex = (page - 1) * 100;
  let endindex = (page * 100);

  let userlist = []
  for (let i = startindex; i < endindex; i++) {
    if (filterList.length > i) {
      userlist.push(filterList[i]);
    }
  }

  const getItemName = (item) => {
    if (!item) {
      return ''
    }

    let optionStr = ''
    if (item.colorid != 0) {
      if (optionStr != '') {
        optionStr += ', '
      }
      optionStr += item.color
    }
    if (item.pendantid != 0) {
      if (optionStr != '') {
        optionStr += ', '
      }
      optionStr += item.pendant
    }
    if (item.sizeid != 0) {
      if (optionStr != '') {
        optionStr += ', '
      }
      optionStr += item.size
    }
    if (item.fontid != 0) {
      if (optionStr != '') {
        optionStr += ', '
      }
      optionStr += item.font
    }
    if (item.imageid != 0) {
      if (optionStr != '') {
        optionStr += ', '
      }
      optionStr += item.image
    }
    if (item.decorationid != 0) {
      if (optionStr != '') {
        optionStr += ', '
      }
      optionStr += item.decoration
    }

    return `${item.name} / ` + optionStr
  }

  const classes = useStyles();
  return (
    <MemberBlock>
      <ConditionBlock>
        <ConditionName>기간</ConditionName>
        <ValueBlock>
          <StyledForm noValidate>
            <TextField
              id="startTime"
              label="시작일"
              name="startTime"
              type="date"
              value={searchInfo.startTime}
              onChange={(e) => onChangeStartTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextSpace>~</TextSpace>
            <TextField
              id="endTime"
              label="종료일"
              name="endTime"
              type="date"
              value={searchInfo.endTime}
              onChange={(e) => onChangeEndTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.buttons}>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button onClick={() => onSetPeriod('days')}>1일</Button>
                <Button onClick={() => onSetPeriod('week')}>1주일</Button>
                <Button onClick={() => onSetPeriod('months')}>1달</Button>
                <Button onClick={() => onSetPeriod('')}>전체</Button>
              </ButtonGroup>
            </div>
          </StyledForm>
        </ValueBlock>
      </ConditionBlock>
      <ConditionBlock>
        <ConditionName>검색</ConditionName>
        <ValueBlock>
          <StyledInput
            name="keyword"
            placeholder="이름 또는 아이디를 입력하세요."
            type="text"
            onChange={(e) => {
              onChangeKeyword(e.target.value);
            }}
            value={searchInfo.keyword}
          />
        </ValueBlock>
      </ConditionBlock>
      <SearchBlock>
        <Button onClick={onSearch}>
          <SearchButtonView>
            <FiRotateCw></FiRotateCw>
            <div style={{ paddingLeft: 10 }}>검색</div>
          </SearchButtonView>
        </Button>
      </SearchBlock>
      <div style={{width: '100%', display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
          <div style={{flex:1}}>
            <input type='radio' value={0} checked={filter == 0} onChange={(e)=>{setFilter(e.target.value)}}>
            </input>
            <label style={{paddingRight:'20px', paddingLeft:'5px'}}>전체 보기</label>
            <input type='radio' value={1} checked={filter == 1} onChange={(e)=>{setFilter(e.target.value)}}>
            </input>
            <label style={{paddingRight:'20px', paddingLeft:'5px'}}>다운로드 안한 목록 보기</label>
            <input type='radio' value={2} checked={filter == 2} onChange={(e)=>{setFilter(e.target.value)}}>
            </input>
            <label style={{paddingRight:'20px', paddingLeft:'5px'}}>다운로드 한 목록 보기</label>
          </div>
          <CommonButton
              onClick={onDownload}
          >{`Excel 다운로드`}</CommonButton>
      </div>
      <SearchListBlock>
        {
          (filterList && filterList.length > 0) &&
          <MemberTable className='membertable_userlist'>
            <MemberTableHeader>
              <tr>
                <td width="7%">다운완료</td>
                <td width="30%">상품명 / 옵션명</td>
                <td width="4%">수량</td>
                <td width="7%">구매 금액</td>
                <td width="36%">각인 내용</td>
                {/* <td width="18%">뒷면 각인 내용</td> */}
                <td width="8%">수취인 이름</td>
                <td width="10%">수취인 전화번호</td>
              </tr>

            </MemberTableHeader>
            <MemberTableBody style={loading ? { display: 'none' } : {}}>
              {
                userlist && userlist.map((userData, index) => {
                  return (
                    <tr key={index} >
                      <td style={{paddingTop:10, paddingBottom:10}} width="7%">{userData.print == 1 ? 'O' : '-'}</td>
                      <td style={{paddingTop:10, paddingBottom:10}} width="30%">{getItemName(userData)}</td>
                      <td style={{paddingTop:10, paddingBottom:10}} width="4%">{inputNumberAutoComma(userData.count)}개</td>
                      <td style={{paddingTop:10, paddingBottom:10}} width="7%">{inputNumberAutoComma(userData.totalPrice)}원</td>
                      <td style={{paddingTop:10, paddingBottom:10}} width="36%">{userData.frontText}</td>
                      {/* <td style={{paddingTop:10, paddingBottom:10}} width="18%">{userData.backText}</td> */}
                      <td style={{paddingTop:10, paddingBottom:10}} width="7%">{userData.username}</td>
                      <td style={{paddingTop:10, paddingBottom:10}} width="9%">{userData.phone}</td>
                    </tr>
                  )
                })
              }
            </MemberTableBody>
          </MemberTable>
          //   <tbody style={{ height: 50 }}>
          //     {user && user.map((userData, index) =>
          //       <tr
          //         key={index}
          //         style={{ borderTop: `1px solid ${palette.webGray[17]}` }}
          //         // onClick={() => {
          //         //   //onClickMember(userData.playerID, index);
          //         //   
          //         // }}
          //       >
          //         <Basetd>{userData.userid}</Basetd>
          //         <Basetd>{timeValueToDateTime(userData.regist_time)}</Basetd>
          //         <Basetd>{userData.name}</Basetd>
          //         <Basetd>{userData.pat_name}</Basetd>
          //         <Basetd>{userData.pat_kind}</Basetd>
          //         <Basetd>{userData.pat_color}</Basetd>
          //         <Basetd>{userData.state == 0?"등록 대기":(userData.state == 1?"구매 완료":(userData.state == 2?"등록 신청 완료":"등록 승인"))}</Basetd>
          //         <Basetd>
          //           <ImageBox>
          //             <img src={userData.pat_image}
          //               onClick={() => {  }} />
          //           </ImageBox>
          //         </Basetd>
          //         <Basetd>
          //           <Button style={{backgroundColor:'#f1f1f1'}} onClick={() =>{ setSelectItem(userData)}}>상세보기</Button>
          //         </Basetd>
          //       </tr>
          //     )}
          //   </tbody>
          // </Table>
        }
        {
          (filterList && filterList.length > 0) ?
            <div style={{ display: 'flex', width: '100%', marginTop: '40px', justifyContent: 'center' }}>
              <Pagination
                count={filterList ? Math.ceil(filterList.length / 100) : 1}
                page={page}
                size="large"
                showFirstButton
                showLastButton
                onChange={(event, value) => {
                  if (value !== page) {
                    onChangePage(value)
                  }
                }}
              />
            </div>
            : null
        }
      </SearchListBlock>
    </MemberBlock>
  );
};

export default React.memo(MydingoSmartStoreCompoment);

