import React, { useEffect, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Logo from './Logo';
import {
  FiSettings,
  FiUser,
  FiBriefcase,
  FiCalendar,
  FiDollarSign,
  FiBarChart,
  FiShoppingCart,
  FiMonitor,
  FiEdit
} from 'react-icons/fi';
import { FaMobileAlt } from 'react-icons/fa';
import {
  ImEnter
} from 'react-icons/im';
import { MdLocalParking } from 'react-icons/md';

const navigation = [
  {
    name: 'memeber',
    text: '회원 정보',
    url: '/member',
    icon: function () {
      return <FiUser />;
    },
  },
  {
    name: 'mydingosmartstore',
    text: '마이딩고 스마트스토어',
    url: '/mydingosmartstore',
    icon: function () {
      return <FiShoppingCart />;
    },
  },
  {
    name: 'petslooksmartstore',
    text: '쿠폰펫 스마트스토어',
    url: '/petslooksmartstore',
    icon: function () {
      return <FiShoppingCart />;
    },
  },
  // {
  //   name: 'buy',
  //   text: '구매기록',
  //   url: '/buy',
  //   icon: function () {
  //     return <FiDollarSign />;
  //   },
  // },
  {
    name: 'rfid',
    text: 'RFID 관리',
    url: '/rfid',
    icon: function () {
      return <FiBarChart />;
    },
  },
];

const NavigationBlock = styled.div`
  display: flex;
  width: 240px;
  height: 100%;
  position: fixed;
  min-width: 150px;
  flex-direction: column;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background: white;
  z-index: 10;
  @media screen and (max-width: 1600px) {
    width: 200px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const LockerIcon = styled.img`
  width: 16.8px;
  height: 16.8px;
`;
const StyledMenu = css`
  color: ${palette.webBlack[9]};
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: 200px;
  @media screen and (max-width: 1600px) {
    width: 110px;
    font-size: 0.8rem;
  }
`;
const StyledLink = styled(NavLink)`
  ${StyledMenu}

  span {
    margin-right: 10px;
    font-size: 1.05rem;
    @media screen and (max-width: 1600px) {
      font-size: 0.9rem;
    }
  }
`;
const StyledUl = styled.ul`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLi = styled.li`
  margin-top: 1.25rem;

  & .active {
    filter: invert(33%) sepia(95%) saturate(1756%) hue-rotate(201deg) brightness(94%) contrast(95%);
  }
`;
const svgStyle = {
  marginRight: '15px',
};

const activeStyle = {
  color: palette.webCyan[5],
  fontWeight: 'bold',
};

const FreeServiceAlertBtn = styled.div`
  margin-right: 15px;
  border-radius: 4px;
  padding: 30px 30px;
  color: ${palette.webCyan[5]};
  background-color: #fff;
  font-size: 1.2rem;
  border: 1px solid ${palette.webCyan[5]};
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  &.warn {
    border-color: #F95050;
    color: #F95050;
  }
`;

const Navigation = () => {
    const [freeButtomActive, setFreeButtomActive] = useState(true)

    let history = useHistory();
    

  return (
    <NavigationBlock>
      <Logo />
      <StyledUl>
        {navigation.map((menu) => {
            return(
              <StyledLi key={menu.name} style={{position: 'relative'}}>
                <StyledLink to={menu.url} activeStyle={activeStyle}>
                  <span className={menu.text && menu.text=="locker" ? "active" : ""}>{menu.icon()}</span>
                  <span>{menu.text}</span>
                </StyledLink>
              </StyledLi>
            )
          })}
          <div style={{height:'22vh'}}/>
      </StyledUl>
    </NavigationBlock>
  );
};

export default Navigation;
