import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import palette from '../../lib/styles/palette';
import { FiRotateCw } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { timeValueToDate, timeValueToDate6, timeValueToDateTime } from '../../lib/commonFunc/commonFunc';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ReportView from '../member/ReportView';
import Pagination from '@material-ui/lab/Pagination';

const MemberBlock = styled.div`
  margin-top: 2.5rem;
`;
const ConditionBlock = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${palette.webGray[17]};
`;
const ConditionName = styled.div`
  padding: 20px 32px;
  background: #A5C1E7;
  text-align: center;
  margin-right: 20px;
  color: ${palette.white};
  font-family: 'Noto Sans kr';
  min-width: 150px;

  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
    padding: 20px 28px;
    min-width: 120px;
  }
`;
const StyledForm = styled.form`
  display: flex;
  align-items: center;
`;
const ValueBlock = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.noFlex &&
    css`
      display: block;
    `}
`;

const TextSpace = styled.div`
  padding: 0 10px;
  font-size: 20pt;
`;
const SearchBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 0;
    margin-bottom: 0;
`;
const SearchButtonView = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[17]};
`;
const TD = styled.td`
  text-align: center;
  min-width: 60px;
  width: 8%;
`;

const Basetd = styled.td`
  text-align: center;
  min-width: 150px;
`;

const ImageBox = styled.div`
  height: 50px;
  justify-content: center;
  align-items: center;

  & img {
    width: 50px;
    height: 50px;
  }
`;
const SearchListBlock = styled.div`
  padding-top: 40px;
    ${(props) => props.height ? css`height:${props.height}vh;` : css`height: auto;`}
`;

const MemberTable = styled.table`
  width: 100%;
  text-align: center;
  table-layout: fixed;
`
const MemberTableHeader = styled.thead`
  width: 100%;
  border-top: 1px solid ${palette.black1};
  border-bottom: 1px solid ${palette.webGray[5]};

  & tr{
    cursor: default;
    & td{
      font-weight: bold;
      padding: 12px 10px;
    }
  }
`

const MemberTableBody = styled.tbody`
  width: 100%;
  & tr {
    width: 100%;
  }
  
  & td {    
    font-size: .95rem;
    border-bottom: 1px solid ${palette.webGray[17]};
    text-overflow:ellipsis; 
    overflow:hidden; 
    white-space:nowrap;
  }
  
  & td:nth-child(1) {    
  }
`

const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;
  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }
`;

const StyledInput = styled.input`
  font-size: 1rem;
  border: 1px solid ${palette.gray3};
  padding: 0.5rem;
  outline: none;
  width: 300px;
  margin-left: 1rem;
  margin-right: 1rem;
  &:focus {
    color: ${palette.blue1};
  }
  & + & {
    margin-top: 1rem;
  }
`;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  input: {
    width: '65px',
  },
  buttons: {
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const RegistPatMemberList = ({
  onChangeStartTime,
  onChangeEndTime,
  onSetPeriod,
  onChangeKeyword,
  searchInfo,
  onSearch,
  onChangeType,
  setSelectItem,
  onChangePage,
  page,
  allchecked,
  onChangeAllChecked,
  checkList,
  onChangeChecked,
  onClickPrint
}) => {
  const {
    user,
    loading
  } = useSelector(({ registPatList, loading }) => ({
    user: registPatList.memberList,
    loading: loading[`registpatlist/LOAD_MEMBER_LIST`],
  }));

  let startindex = (page - 1) * 20;
  let endindex = (page * 20);

  let userlist = []
  for (let i = startindex; i < endindex; i++) {
    if (user.length > i) {
      userlist.push(user[i]);
    }
  }

  const isPrint = () => {
    if (checkList) {
      for (let i = 0; i < checkList.length; i++) {
        if (checkList[i] && user && user.length > i) {
          if (user[i].state > 1) {
            return true;
          }
        }
      }
      return false;
    }
    return false;
  }

  const classes = useStyles();
  return (
    <MemberBlock>
      <ConditionBlock>
        <ConditionName>기간</ConditionName>
        <ValueBlock>
          <StyledForm noValidate>
            <TextField
              id="startTime"
              label="시작일"
              name="startTime"
              type="date"
              value={searchInfo.startTime}
              onChange={(e) => onChangeStartTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextSpace>~</TextSpace>
            <TextField
              id="endTime"
              label="종료일"
              name="endTime"
              type="date"
              value={searchInfo.endTime}
              onChange={(e) => onChangeEndTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.buttons}>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button onClick={() => onSetPeriod('days')}>1일</Button>
                <Button onClick={() => onSetPeriod('week')}>1주일</Button>
                <Button onClick={() => onSetPeriod('months')}>1달</Button>
                <Button onClick={() => onSetPeriod('')}>전체</Button>
              </ButtonGroup>
            </div>
          </StyledForm>
        </ValueBlock>
      </ConditionBlock>

      <ConditionBlock>
        <ConditionName>검색타입</ConditionName>
        <ValueBlock>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={searchInfo.type}
              onChange={onChangeType}
              row={true}
            >
              <FormControlLabel value="0" label="전체" control={<Radio color="primary" />} />
              <FormControlLabel value="1" label="작성 완료" control={<Radio color="primary" />} />
              {/* <FormControlLabel value="2" label="결제 완료" control={<Radio color="primary" />} /> */}
              <FormControlLabel value="3" label="신청 완료" control={<Radio color="primary" />} />
              <FormControlLabel value="4" label="등록 완료" control={<Radio color="primary" />} />
            </RadioGroup>
          </FormControl>
        </ValueBlock>
      </ConditionBlock>
      <ConditionBlock>
        <ConditionName>검색</ConditionName>
        <ValueBlock>
          <StyledInput
              name="keyword"
              placeholder="이름 또는 아이디를 입력하세요."
              type="text"
              onChange={(e)=>{
                onChangeKeyword(e.target.value);
              }}
              value={searchInfo.keyword}
          />
        </ValueBlock>
      </ConditionBlock>
      <SearchBlock>
        <Button onClick={onSearch}>
          <SearchButtonView>
            <FiRotateCw></FiRotateCw>
            <div style={{ paddingLeft: 10 }}>검색</div>
          </SearchButtonView>
        </Button>
      </SearchBlock>

      <SearchListBlock>
        {
          isPrint() ?
          <div style={{ height:40, justifyContent:'center' }}>
            <Button style={{ backgroundColor: '#ffffff', border: "1px solid #000000", borderWidth: 1, marginRight: 100 }} onClick={onClickPrint}>인쇄하기</Button>
          </div>
          : 
          <div style={{ height:40}}/>
        }
        {
          (user && user.length > 0) &&
          <MemberTable>
            <MemberTableHeader>
              <tr>
                <td width="5%">
                  <input
                    type="checkbox"
                    checked={allchecked}
                    onChange={({ target: { checked } }) => onChangeAllChecked(checked)}
                  />
                </td>
                <td width="5%">상태</td>
                <td width="7%">회원 이름</td>
                <td width="9%">동물 이름</td>
                <td width="9%">품종</td>
                <td width="8%">작성일</td>
                <td width="8%">RFID입력</td>
                {/* <td width="10%">동물 사진</td> */}
                <td width="10%">-</td>
              </tr>

            </MemberTableHeader>
            <MemberTableBody style={loading ? { display: 'none' } : {}}>
              {
                userlist && userlist.map((userData, index) => {
                  return (
                    <tr key={index}>
                      <td width="5%">
                        <input
                          type="checkbox"
                          checked={checkList.length > index ? checkList[index] : false}
                          onChange={({ target: { checked } }) => onChangeChecked(index, checked)}
                        />
                      </td>
                      {
                        userData.state == 0 || userData.state == 1 ?
                          <td style={{ color: '#000000' }} width="8%">작성완료</td>
                          :
                            userData.state == 3 ?
                              <td style={{ color: '#1111ee' }} width="8%">신청완료</td>
                              :
                              <td style={{ color: '#00cc00' }} width="8%">등록완료</td>
                      }
                      <td width="7%">{userData.name}</td>
                      <td width="9%">{userData.pat_name}</td>
                      <td width="9%">{userData.pat_kind}</td>
                      <td width="8%">{timeValueToDate(userData.regist_time)}</td>
                      <td width="8%">{userData.state >= 2?((userData.RFID != 0 || userData.RFID)?'O':'X'):'-'}</td>
                      {/* <td width="10%">
                        <ImageBox>
                          <img src={userData.pat_image}
                            onClick={() => {  }} />
                        </ImageBox>
                      </td> */}
                      <td width="10%"><Button style={{ backgroundColor: '#f9f9f9', border:'1px solid #aaaaaa' }} onClick={() => { setSelectItem(userData) }}>상세보기</Button></td>
                    </tr>
                  )
                })
              }
            </MemberTableBody>
          </MemberTable>
          //   <tbody style={{ height: 50 }}>
          //     {user && user.map((userData, index) =>
          //       <tr
          //         key={index}
          //         style={{ borderTop: `1px solid ${palette.webGray[17]}` }}
          //         // onClick={() => {
          //         //   //onClickMember(userData.playerID, index);
          //         //   
          //         // }}
          //       >
          //         <Basetd>{userData.userid}</Basetd>
          //         <Basetd>{timeValueToDateTime(userData.regist_time)}</Basetd>
          //         <Basetd>{userData.name}</Basetd>
          //         <Basetd>{userData.pat_name}</Basetd>
          //         <Basetd>{userData.pat_kind}</Basetd>
          //         <Basetd>{userData.pat_color}</Basetd>
          //         <Basetd>{userData.state == 0?"등록 대기":(userData.state == 1?"구매 완료":(userData.state == 2?"등록 신청 완료":"등록 승인"))}</Basetd>
          //         <Basetd>
          //           <ImageBox>
          //             <img src={userData.pat_image}
          //               onClick={() => {  }} />
          //           </ImageBox>
          //         </Basetd>
          //         <Basetd>
          //           <Button style={{backgroundColor:'#f1f1f1'}} onClick={() =>{ setSelectItem(userData)}}>상세보기</Button>
          //         </Basetd>
          //       </tr>
          //     )}
          //   </tbody>
          // </Table>
        }
        {
          (user && user.length > 0) ?
            <div style={{ display: 'flex', width: '100%', marginTop: '40px', justifyContent: 'center' }}>
              <Pagination
                count={user ? Math.ceil(user.length / 20) : 1}
                page={page}
                size="large"
                showFirstButton
                showLastButton
                onChange={(event, value) => {
                  if (value !== page) {
                    onChangePage(value)
                  }
                }}
              />
            </div>
            : null
        }
      </SearchListBlock>
    </MemberBlock>
  );
};

export default React.memo(RegistPatMemberList);
