import styled from 'styled-components';

export const SideSpacer = styled.div`
  margin-left: 240px;
  /* padding-bottom: 8rem; */
  @media screen and (max-width: 1600px) {
    margin-left: 200px;
  }
  @media screen and (max-width: 768px) {
    margin: 0 30px;
  }
`;

export const MarginLeftPaddingBottomSpace = styled.div`
  margin-left: 240px;

  @media screen and (max-width: 1600px) {
    margin-left: 200px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 10px;
  }
`;
