
import React, { useEffect, useState } from 'react';

const MydingoSmartStoreTab = ({
  tab,
  setTab,
}) => {
  return (
    <div className=' flex'>
        <div
            className={` px-8 py-4 ${tab == 1?'text-blue-700 font-semibold':' text-black font-normal'} text-xl cursor-pointer font-sans`}
            onClick={()=>setTab(1)}
        >견적 신청</div>
        <div
            className={` px-8 py-4 ${tab == 2?'text-blue-700 font-semibold':' text-black font-normal'} text-xl cursor-pointer font-sans`}
            onClick={()=>setTab(2)}
        >동물 등록</div>
    </div>
  );
};

export default React.memo(MydingoSmartStoreTab);

