import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/common/Header';
import { withRouter } from 'react-router-dom';

const HeaderContainer = ({ history }) => {
  // const { user, auth} = useSelector(
  //   ({ user, auth }) => ({
  //     user: user.user,
  //     auth: auth.auth
  //   }),
  // );
  // const [isAllDelete, setIsAllDelete] = useState(false);

  // const dispatch = useDispatch();

  // useEffect(() => {
  // }, [history, user]);

  // useEffect(() => {
  //   if (!user) {
  //     let tempUser = JSON.parse(localStorage.getItem("user"))
  //     console.log(tempUser)
  //     if(!tempUser || tempUser.memberType != 1) {
  //       //history.push('/login');
  //     }
  //   }
  // }, [history, user, dispatch]);

  // useEffect(() => {
  //   if (auth) {
  //     console.log(auth)
  //   }
  // }, [auth, dispatch]);
  
  // useEffect(() => {
  //   if(user) {
  //   }
  //   let tempUser = (localStorage.getItem("user"))
  //   console.log('aaa', tempUser)
  //   console.log('bbb',user)
  // }, [user])
  return (
    <>
      <Header
        //user={user}
      />
    </>
  );
};

export default withRouter(HeaderContainer);
