
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    initialize,
    loadRFIDInfo,
    addRFID,
    deleteRFID
} from '../../modules/RFID/RFID';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import RFIDComponent from '../../components/RFID/RFIDComponent';

const RFIDContainer = ({ history }) => {

    const {
        rfidInfo,
        deleteRes,
        addRes,
    } = useSelector(({ RFID }) => ({
        rfidInfo: RFID.rfidInfo,
        deleteRes: RFID.deleteRes,
        addRes: RFID.addRes,
    }));

    
    const [addStartValue, setAddStartValue] = useState("");
    const [addEndValue, setAddEndValue] = useState("");
    const [deleteStartValue, setDeleteStartValue] = useState("");
    const [deleteEndValue, setDeleteEndValue] = useState("");
    const [changeType, setChangeType] = useState(0);
    const [conformModal, setConformModal] = useState(false);
    const [modalText, setModalText] = useState("");
    const [password, setPassword] = useState("");
    const [renderState, setRenderState] = useState(0);
    const [authError, setAuthError] = useState("");
    
    

    const dispatch = useDispatch();

    let tempUser = (localStorage.getItem("user"))
    useEffect(() => {
        dispatch(initialize());
        dispatch(loadRFIDInfo({userkey:tempUser}));
    }, []);

    useEffect(() => {
        if(!rfidInfo)
        {
            return;
        }

        console.log('loadinfo emptyMin : ', rfidInfo.emptyMin);
        console.log('loadinfo emptyMax : ', rfidInfo.emptyMax);
        console.log('loadinfo usedMin : ', rfidInfo.usedMin);
        console.log('loadinfo usedMax : ', rfidInfo.usedMax);
        console.log('loadinfo totalMin : ', rfidInfo.totalMin);
        console.log('loadinfo totalMax : ', rfidInfo.totalMax);
        console.log('loadinfo totalCount : ', rfidInfo.totalCount);
        console.log('loadinfo usedCount : ', rfidInfo.usedCount);

    }, [rfidInfo]);

    useEffect(() => {
        console.log('deleteRes : ', deleteRes)
        if(deleteRes == 1)
        {
            dispatch(initialize());
            dispatch(loadRFIDInfo({userkey:tempUser}));
        }
    }, [deleteRes]);

    useEffect(() => {
        console.log('addRes : ', addRes)
        if(addRes == 1)
        {
            dispatch(initialize());
            dispatch(loadRFIDInfo({userkey:tempUser}));
        }
    }, [addRes]);

    
    
    const onClickModalConform = ()=>{
        if(changeType == 'add')
        {
            let startValue = parseInt(addStartValue);
            let endValue = parseInt(addEndValue);
            console.log('add : ', startValue, endValue)
            dispatch(addRFID({startValue:startValue, endValue:endValue, userkey:tempUser}));
        }
        else
        {
            let startValue = parseInt(deleteStartValue);
            let endValue = parseInt(deleteEndValue);
            dispatch(deleteRFID({startValue:startValue, endValue:endValue, userkey:tempUser}));
        }
    }

    const onClickAdd = ()=>{
        let startvalue = parseInt(addStartValue);
        let endvalue = parseInt(addEndValue);
        if(!startvalue || !endvalue)
        {
            alert('값을 잘못 입력하였습니다.');
            return;
        }
        if(startvalue>endvalue)
        {
            alert('값을 잘못 입력하였습니다.');
            return;
        }

        setChangeType("add");
        setConformModal(true);
        setModalText('RFID를 추가하시겠습니까?')
    }
    const onClickDelete = ()=>{
        let startvalue = parseInt(deleteStartValue);
        let endvalue = parseInt(deleteEndValue);
        if(!startvalue || !endvalue)
        {
            alert('값을 잘못 입력하였습니다.');
            return;
        }
        if(startvalue>endvalue)
        {
            alert('값을 잘못 입력하였습니다.');
            return;
        }

        setChangeType("delete");
        setConformModal(true);
        setModalText('RFID를 삭제하시겠습니까?')
    }

    const onChangeAddStartValue = (value)=>{
        setAddStartValue(value);
    }

    const onChangeAddEndValue = (value)=>{
        setAddEndValue(value);
    }

    const onChangeDeleteStartValue = (value)=>{
        setDeleteStartValue(value);
    }

    const onChangeDeleteEndValue = (value)=>{
        setDeleteEndValue(value);
    }

    const setLogin = ()=>{
        if(password == "mydingo777")
        {
            setRenderState(1);
        }
        else
        {
            setAuthError("비밀번호가 틀립니다.")
        }
    }
  
    return (
        <>
            <RFIDComponent
                rfidInfo={rfidInfo}
                onClickAdd={onClickAdd}
                onClickDelete={onClickDelete}
                addStartValue={addStartValue}
                addEndValue={addEndValue}
                onChangeAddStartValue={onChangeAddStartValue}
                onChangeAddEndValue={onChangeAddEndValue}
                deleteStartValue={deleteStartValue}
                deleteEndValue={deleteEndValue}
                onChangeDeleteStartValue={onChangeDeleteStartValue}
                onChangeDeleteEndValue={onChangeDeleteEndValue}
                modalVisible={conformModal}
                setModal={setConformModal}
                onClickModalConform={onClickModalConform}
                modalText={modalText}
                renderState={renderState}
                password={password}
                setPassword={setPassword}
                setLogin={setLogin}
                authError={authError}
            />
        </>
    );
};

export default withRouter(RFIDContainer);
