import client, { url } from './client';
import qs from 'qs';

export const search = ({ type, keyword, clubID, socketKey }) => {
  const queryString = qs.stringify({
    type,
    keyword,
    clubID,
    socketKey,
  });
  return client.get(`${url}/api/search?${queryString}`);
};

export const loadMemberList = ({startTime, endTime, type, userkey, keyword}) => {
  const queryString = qs.stringify({
    startTime, endTime, type, userkey, keyword
  });
  console.log(queryString)
  return client.get(`${url}/api/user/load?${queryString}`);
}

export const loadBuyList = ({startTime, endTime, userkey, keyword}) => {
  const queryString = qs.stringify({
    startTime, endTime, userkey, keyword
  });
  console.log(queryString)
  return client.get(`${url}/api/user/loadbuy?${queryString}`);
}

export const changeUserState = ({user_uuid, userkey, state}) => {
  const queryString = qs.stringify({
    user_uuid, userkey, state
  });
  return client.post(`${url}/api/user/changeuserstate?${queryString}`, {});
}

export const changeUserListState = ({user_uuids, userkey, state}) => {
  const queryString = qs.stringify({
    user_uuids, userkey, state
  });
  return client.post(`${url}/api/user/changeuserliststate?${queryString}`, {});
}

export const changeUserRFID = ({user_uuid, rfid, userkey}) => {
  const queryString = qs.stringify({
    user_uuid, rfid, userkey
  });
  return client.post(`${url}/api/user/updaterfid?${queryString}`, {});
}

