import ReactToPrint, { useReactToPrint } from 'react-to-print';
import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ReportItemView from './ReportItemView';
import Button from '@material-ui/core/Button';
import palette from '../../lib/styles/palette';
import { RiArrowLeftSLine } from 'react-icons/ri';
import Loading from '../common/Loading';

export const HistoryBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  & svg {
    font-size: 24pt !important;
    color: ${palette.webGray[6]} !important;
    cursor: pointer;
  }
`;
export const HistoryBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  svg {
    font-size: 16pt;
  }
`;

const ReportListView = ({
    itemList,
    history,
    onChangeState
}) => {
    const [resetStart, setresetStart] = useState(false)
    const [loading, setloading] = useState(true)
    
    const resetTime = ()=>{
        if(!resetStart)
        {
            setresetStart(true)
            setTimeout(()=>{
                setloading(false)
            }, 5000)
        }
    }
    const componentRef = useRef();
    //const handlePrint = useReactToPrint({content: () =>{return componentRef.current}, fonts:CUSTOM_FONTS});
    const handlePrint = useReactToPrint({content: () =>{return componentRef.current}});
    return (
        itemList?
        <div>
            {/* <ReactToPrint
                trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return <a href="#">Print this out!</a>;
                }}
                content={() => componentRef}
            /> */}
            <HistoryBlock>
                <HistoryBtn>
                    <RiArrowLeftSLine onClick={() =>{history.goBack();}}/>
                </HistoryBtn>
                <div>목록보기</div>
            </HistoryBlock>
            <div style={{height:20}}/>
            {
                loading?
                <Loading/>
                :
                <div style={{display:'flex'}}>
                    <Button style={{ backgroundColor: '#f1f1f1', border: "1px solid #000000", borderWidth: 1, marginRight: 50, height:50, width:100 }} onClick={handlePrint}>인쇄하기</Button>
                    
                    <Button style={{ backgroundColor: '#005dac', color:'white', marginRight: 100, height:50, }} onClick={()=>onChangeState(3)}>모두 신청 완료로 전환하기</Button>
                </div>
            }
            <div style={{height:100}}></div>
            <div ref={componentRef}>
                {
                    itemList.map((item, index)=>{
                        return (
                            <ReportItemView
                                item={item}
                                itemID={`ReportListView_ReportItemView_${index}`}
                                key={`ReportListView_ReportItemView_${index}`}
                                resetTime={resetTime}
                            />
                        )
                    })
                }
            </div>
        </div>:null
    );
};

export default React.memo(ReportListView);
