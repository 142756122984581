
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PetslookSmartStoreCompoment from '../../components/smartstore/PetslookSmartStoreCompoment';
import {
  initialize,
  loadOrderList,
  pageChange,
  updateDownloadList,
} from '../../modules/smartstore/smartstore';
import moment from 'moment';
import { downloadExcel } from '../../lib/excelDownload';

const PetslookSmartStoreContainer = ({ history }) => {
  
    const {
        orderList,
        page,
        updateRes,
      } = useSelector(({ smartstore }) => ({
        orderList: smartstore.orderList,
        page:smartstore.page,
        updateRes:smartstore.updateRes,
      }));
    
      const [searchInfo, setSearchInfo] = useState({
        startTime: "",
        endTime: "",
        dateType: "",
        keyword: "",
      })
      const [filter, setFilter] = useState(0)
      const dispatch = useDispatch();

      useEffect(() => {
        dispatch(initialize());
        if (!orderList) {
          let startDate = moment().subtract(1, 'week').format('YYYY-MM-DD');
          let endDate = moment().endOf('day').format('YYYY-MM-DD');
          let startTime = moment().subtract(1, 'week').unix();
          let endTime = moment().endOf('day').unix();

          dispatch(loadOrderList({
            startTime: startTime,
            endTime: endTime,
            keyword: "",
            type: "patslook"
          }),);

          setSearchInfo({
            startTime: startDate,
            endTime: endDate,
            dateType: "week",
            keyword: ""
          })
        }
      }, []);
      
      useEffect(() => {
        if (updateRes) {
          onSearch()
        }
      }, [updateRes]);

      const onSetPeriod = (unit) => {
        let startTime = '';
        if (unit == '') {
          startTime = '2023-08-18';
        }
        else {
          startTime = moment().subtract(1, unit).format('YYYY-MM-DD');
        }
    
        const endTime = moment().format('YYYY-MM-DD');
        setSearchInfo({
          startTime: startTime,
          endTime: endTime,
          dateType: unit,
          keyword: searchInfo.keyword,
        })
      };

      const onChangeStartTime = (startTime) => {
        console.log('onChangeStartTime : ', startTime)
        setSearchInfo({
          startTime: startTime,
          endTime: searchInfo.endTime,
          keyword: searchInfo.keyword,
          dateType: searchInfo.dateType,
        })
      };
    
      const onChangeEndTime = (endTime) => {
        console.log('onChangeEndTime : ', endTime)
        setSearchInfo({
          startTime: searchInfo.startTime,
          endTime: endTime,
          keyword: searchInfo.keyword,
          dateType: searchInfo.dateType,
        })
      };
    
      const onChangeKeyword = (keyword) => {
        setSearchInfo({
          startTime: searchInfo.startTime,
          endTime: searchInfo.endTime,
          keyword: keyword,
          dateType: searchInfo.dateType,
        })
      };

      const onSearch = () => {
    
        let startTime = moment('2023-08-18').startOf('day').unix();
        let endTime = moment().endOf('day').unix();
    
        if (searchInfo.startTime) {
          startTime = moment(searchInfo.startTime).startOf('day').unix()
        }
        if (searchInfo.endTime) {
          endTime = moment(searchInfo.endTime).endOf('day').unix();
        }
    
        console.log('onSearch : ', searchInfo.startTime)
        console.log('onSearch : ', searchInfo.endTime)
    
        dispatch(loadOrderList({
          startTime,
          endTime,
          keyword: searchInfo.keyword,
          type: "patslook"
        }),)
      }

      const onChangePage = (page) => {
      }


      const filterList = orderList.filter((order) => {
        if(filter == 0)
        {
          return true;
        }
        else if(filter == 1)
        {
          return order.print == 0;
        }
        else
        {
          return order.print == 1;
        }
      })

      const onDownload = () => {

        let startindex = (page - 1) * 100;
        let endindex = (page * 100);
      
        let optionidlist = []
        for (let i = startindex; i < endindex; i++) {
          if (filterList.length > i) {
            optionidlist.push(filterList[i].id)
          }
        }

        //let 
        //updateDownloadList
        downloadExcel('Petslook', 'membertable_userlist')
        
        dispatch(updateDownloadList({optionidlist}));
      }
      console.log('orderList : ', orderList)
    return (
        <>
            <PetslookSmartStoreCompoment
                onChangeStartTime={onChangeStartTime}
                onChangeEndTime={onChangeEndTime}
                onChangeKeyword={onChangeKeyword}
                onSetPeriod={onSetPeriod}
                searchInfo={searchInfo}
                onSearch={onSearch}
                onChangePage={onChangePage}
                page={page}
                onDownload={onDownload}
                filter={filter}
                setFilter={setFilter}
                filterList={filterList}
            />
        </>
    );
};

export default withRouter(PetslookSmartStoreContainer);

