import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import auth, { authSaga } from './auth/auth';
import RFID, { RFIDSaga } from './RFID/RFID';
import search, { searchSaga } from './commons/search';
import selectItem, { selectItemSaga } from './selectItem/selectItem';
import smartstore, { smartstoreSaga } from './smartstore/smartstore';
import registPat, { registPatSaga } from './registpat/registpat';
import registPatList, { registPatMemberListSaga } from './registpat/registpatlist';

import loading from './commons/loading';

const rootReducer = combineReducers({
  auth,
  loading,
  search,
  RFID,
  selectItem,
  smartstore,
  registPat,
  registPatList,
});

export function* rootSaga() {
  yield all([
    authSaga(),
    searchSaga(),
    RFIDSaga(),
    selectItemSaga(),
    smartstoreSaga(),
    registPatSaga(),
    registPatMemberListSaga(),
  ]);
}

export default rootReducer;
