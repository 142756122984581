
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeUserState,
  selectUser,
  userListChange,
  initializeStateRes,
  changeUserRFID,
} from '../../modules/registpat/registpatlist';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import MemberInfo from '../../components/registpat/RegistPatMemberInfo';

const RegistPatMemberContainer = ({ history }) => {
  let {
    selectUserdata,
    memberList,
    changeUserStateRes,
    changeRFIDRes,
  } = useSelector(({ registPatList }) => ({
    selectUserdata: registPatList.selectUser,
    changeUserStateRes: registPatList.changeUserStateRes,
    changeRFIDRes: registPatList.changeRFIDRes,
    memberList:registPatList.memberList,
  }));
  
  const [RFIDInput, setRFIDInput] = useState("")

  const dispatch = useDispatch();

  const onChangeState = (itemstate)=>{
    let tempUser = (localStorage.getItem("user"))
    dispatch(changeUserState({user_uuid:selectUserdata.uuid, userkey:tempUser, state:itemstate}))
    console.log('onChangeState : ', itemstate)
  }

  useEffect(() => {
    if(changeUserStateRes != null)
    {
      if(selectUserdata)
      {
        selectUserdata.state = changeUserStateRes;
        dispatch(selectUser(selectUserdata));
        dispatch(initializeStateRes());
        
        if(memberList)
        {
          for (let i = 0; i < memberList.length; i++) {
            if(memberList[i].uuid == selectUserdata.uuid)
            {
              memberList[i].state = changeUserStateRes;
              dispatch(userListChange(memberList));
              break;
            }
          }
        }
      }
    }
  }, [changeUserStateRes]);

  useEffect(() => {
    if(selectUserdata != null)
    {
      setRFIDInput(selectUserdata.RFID)
    }
  }, []);

  const onChangeRFIDInput = (e)=>{
    setRFIDInput(e.target.value)
  }

  useEffect(() => {
    if(changeRFIDRes != null)
    {
      console.log('changeRFIDRes update : ', changeRFIDRes,selectUserdata, memberList)
      if(selectUserdata)
      {
        if(memberList)
        {
          for (let i = 0; i < memberList.length; i++) {
            if(memberList[i].uuid == selectUserdata.uuid)
            {
              memberList[i].RFID = changeRFIDRes;
              console.log('user update : ', memberList[i])
              dispatch(userListChange(memberList));
              break;
            }
          }
        }
        dispatch(initializeStateRes());
      }
    }
  }, [changeRFIDRes]);

  const onChangeRFID = ()=>{
    let tempUser = (localStorage.getItem("user"))
    dispatch(changeUserRFID({user_uuid:selectUserdata.uuid, userkey:tempUser, rfid:RFIDInput}))
  }
  

  return (
    <>
      <MemberInfo
        item={selectUserdata}
        history={history}
        onChangeState={onChangeState}
        RFIDInput={RFIDInput}
        onChangeRFIDInput={onChangeRFIDInput}
        onChangeRFID={onChangeRFID}
      />
    </>
  );
};

export default withRouter(RegistPatMemberContainer);
