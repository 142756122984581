import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useSearchParams } from 'react-router-dom';
import {
    initialize,
    updatePat,
    resetRegistPatResult,
    uploadSign,
    uploadPatImage,
    resetUploadSignResult,
    resetUploadPatImageResult,
} from '../../modules/registpat/registpat';
import SelectItemView from '../../components/selectItem/SelectItemView';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
import RegistPatView from '../../components/registpat/RegistPatView';
import imageCompression from 'browser-image-compression'
import moment from 'moment';

const RegistPatContainer = ({ history }) => {

    const {
        registPatList,
        registPatResult,
        uploadSignResult,
        uploadPatImageResult,
    } = useSelector(({ registPat }) => ({
        registPatResult: registPat.registPatResult,
        uploadSignResult: registPat.uploadSignResult,
        uploadPatImageResult: registPat.uploadPatImageResult,
    }));
    const [imgFile, setImgFile] = useState("");
    const imgRef = useRef();
    const signRef = useRef();

    const params = new URLSearchParams(window.location.search);
    let _type = params.get("type");

    useEffect(() => {
        if(uploadSignResult && ((uploadPatImageResult && imgFile) || (imgFile == '')))
        {
            console.log('uploadSignResult : ', uploadSignResult)
            console.log('uploadPatImageResult : ', uploadPatImageResult)
            let pat_birth = moment(inputData.pat_birth).startOf('day').unix();
            let pat_registTime = moment(inputData.pat_registTime).startOf('day').unix();
            let pat_image = '';
            if(uploadPatImageResult && imgFile)
            {
                pat_image = `https://mydingo.s3.ap-northeast-2.amazonaws.com/image/${uploadPatImageResult}`;
            }
            let sign = `https://mydingo.s3.ap-northeast-2.amazonaws.com/image/${uploadSignResult}`;
            const userData = {
                userid:'RegistPat',
                name:inputData.name,
                phone:inputData.phone,
                email:inputData.email,
                jumin:inputData.jumin,
                jumin_next:inputData.jumin_next,
                addr:inputData.addr,
                addr_next:inputData.addr_next,
                addr_post:inputData.addr_post,
                real_addr:inputData.real_addr,
                real_addr_next:inputData.real_addr_next,
                real_addr_post:inputData.real_addr_post,
                pat_name:inputData.pat_name,
                pat_image:pat_image,
                sign:sign,
                pat_type:'1',
                pat_kind:inputData.pat_kind,
                pat_color:inputData.pat_color,
                pat_sex:inputData.pat_sex,
                pat_neutering:inputData.pat_neutering,
                pat_birth:pat_birth,
                pat_registTime:pat_registTime,
                input_RFID :inputData.input_RFID
            }
            dispatch(updatePat({userData}));
            
            dispatch(resetUploadSignResult());
            dispatch(resetUploadPatImageResult());
        }
        if(registPatResult)
        {
            alert('전송완료')
            dispatch(resetRegistPatResult());
        }
    }, [uploadSignResult, uploadPatImageResult, registPatResult]);
/*
CREATE TABLE `User` (
  `uuid` varchar(30) NOT NULL,
  `userid` varchar(20) NOT NULL,
  `regist_time` int NOT NULL,
  `name` varchar(20) NOT NULL,
  `phone` varchar(20) NOT NULL,
  `email` varchar(50) NOT NULL,
  `jumin` varchar(8) NOT NULL,
  `jumin_next` varchar(10) NOT NULL,
  `addr` varchar(100) NOT NULL,
  `addr_next` varchar(100) NOT NULL,
  `addr_post` varchar(15) NOT NULL,
  `real_addr` varchar(100) NOT NULL,
  `real_addr_next` varchar(100) NOT NULL,
  `real_addr_post` varchar(15) NOT NULL,
  `pat_name` varchar(20) NOT NULL,
  `pat_image` varchar(300) NOT NULL,
  `pat_type` varchar(4) NOT NULL,
  `pat_kind` varchar(30) NOT NULL,
  `pat_color` varchar(30) NOT NULL,
  `pat_sex` varchar(4) NOT NULL,
  `pat_neutering` varchar(4) NOT NULL,
  `pat_birth` int NOT NULL,
  `pat_registTime` int NOT NULL,
  `sign` varchar(300) NOT NULL,
  `state` int NOT NULL,
  `product_name` varchar(100) DEFAULT NULL,
  `product_option` varchar(100) DEFAULT NULL,
  `RFID` bigint DEFAULT NULL,
  `buy_time` int NOT NULL,
  `input_RFID` bigint DEFAULT NULL,
  `price` varchar(10) NOT NULL DEFAULT ''
) ENGINE=InnoDB DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_0900_ai_ci
*/
    const [inputData, setInputData] = useState({
        name:'',
        phone:'',
        email:'',
        jumin:'',
        jumin_next:'',
        addr:'',
        addr_next:'',
        addr_post:'',
        addr_copy:0,
        real_addr:'',
        real_addr_next:'',
        real_addr_post:'',
        pat_name:'',
        pat_image:'',
        pat_type:'',
        pat_kind:'',
        pat_color:'',
        pat_sex:'',
        pat_neutering:'',
        pat_birth:0,
        pat_registTime:0,
        sign:'',
        input_RFID:'',
        terms_total:'',
        terms_1:'',
        terms_2:'',
        terms_3:'',
        terms_4:'',
        terms_5:'',
        terms_6:'',
    });

    const dispatch = useDispatch();

    const onChangeInputData = (target, value)=>{
        let name = inputData.name
        let phone = inputData.phone
        let email = inputData.email
        let jumin = inputData.jumin
        let jumin_next = inputData.jumin_next
        let addr = inputData.addr
        let addr_next = inputData.addr_next
        let addr_post = inputData.addr_post
        let real_addr = inputData.real_addr
        let real_addr_next = inputData.real_addr_next
        let real_addr_post = inputData.real_addr_post
        let pat_name = inputData.pat_name
        let pat_image = inputData.pat_image
        let pat_type = inputData.pat_type
        let pat_kind = inputData.pat_kind
        let pat_color = inputData.pat_color
        let pat_sex = inputData.pat_sex
        let pat_neutering = inputData.pat_neutering
        let pat_birth = inputData.pat_birth
        let pat_registTime = inputData.pat_registTime
        let sign = inputData.sign
        let addr_copy = inputData.addr_copy
        let input_RFID = inputData.input_RFID
        let terms_total = inputData.terms_total
        let terms_1 = inputData.terms_1
        let terms_2 = inputData.terms_2
        let terms_3 = inputData.terms_3
        let terms_4 = inputData.terms_4
        let terms_5 = inputData.terms_5
        let terms_6 = inputData.terms_6
        
        if(target == 'name')
        {
            name = value
        }
        if(target == 'phone')
        {
            phone = value
        }
        if(target == 'email')
        {
            email = value
        }
        if(target == 'jumin')
        {
            jumin = value
        }
        if(target == 'jumin_next')
        {
            jumin_next = value
        }
        if(target == 'addr')
        {
            addr = value.address
            addr_post = value.postcode
        }
        if(target == 'addr_next')
        {
            addr_next = value
        }
        if(target == 'real_addr')
        {
            real_addr = value.address
            real_addr_post = value.postcode
        }
        if(target == 'real_addr_next')
        {
            real_addr_next = value
        }
        if(target == 'pat_name')
        {
            pat_name = value
        }
        if(target == 'pat_image')
        {
            pat_image = value
        }
        if(target == 'pat_type')
        {
            pat_type = value
        }
        if(target == 'pat_kind')
        {
            pat_kind = value
        }
        if(target == 'pat_color')
        {
            pat_color = value
        }
        if(target == 'pat_sex')
        {
            pat_sex = value
        }
        if(target == 'pat_neutering')
        {
            pat_neutering = value
        }
        if(target == 'pat_birth')
        {
            pat_birth = value
        }
        if(target == 'pat_registTime')
        {
            pat_registTime = value
        }
        if(target == 'sign')
        {
            sign = value
        }
        if(target == 'addr_copy')
        {
            addr_copy = value
            if(value == 1)
            {
                real_addr = addr
                real_addr_next = addr_next
                real_addr_post = addr_post
            }
            if(value == 2)
            {
                real_addr = ''
                real_addr_next = ''
                real_addr_post = ''
            }
        }
        if(target == 'input_RFID')
        {
            input_RFID = value
        }
        if(target == 'terms_total')
        {
            terms_1 = value
            terms_2 = value
            terms_3 = value
            terms_4 = value
            terms_5 = value
            terms_6 = value
        }
        if(target == 'terms_1')
        {
            terms_1 = value
        }
        if(target == 'terms_2')
        {
            terms_2 = value
        }
        if(target == 'terms_3')
        {
            terms_3 = value
        }
        if(target == 'terms_4')
        {
            terms_4 = value
        }
        if(target == 'terms_5')
        {
            terms_5 = value
        }
        if(target == 'terms_6')
        {
            terms_6 = value
        }

        if(terms_1 == '1' && terms_2 == '1' && terms_3 == '1' && terms_4 == '1' && terms_5 == '1' && terms_6 == '1')
        {
            terms_total = '1'
        }
        else
        {
            terms_total = ''
        }
        
        
        setInputData({
            name:name,
            phone:phone,
            email:email,
            jumin:jumin,
            jumin_next:jumin_next,
            addr:addr,
            addr_next:addr_next,
            addr_post:addr_post,
            real_addr:real_addr,
            real_addr_next:real_addr_next,
            real_addr_post:real_addr_post,
            pat_name:pat_name,
            pat_image:pat_image,
            pat_type:pat_type,
            pat_kind:pat_kind,
            pat_color:pat_color,
            pat_sex:pat_sex,
            pat_neutering:pat_neutering,
            pat_birth:pat_birth,
            pat_registTime:pat_registTime,
            sign:sign,
            addr_copy:addr_copy,
            input_RFID:input_RFID,
            terms_total:terms_total,
            terms_1:terms_1,
            terms_2:terms_2,
            terms_3:terms_3,
            terms_4:terms_4,
            terms_5:terms_5,
            terms_6:terms_6,
        })
    }

    const resizeProfileImage = async (img) => {
        let file = img; // 입력받은 file객체
        
        //이미지 resize 옵션 설정 (최대 width을 100px로 지정)
        const options = { 
            maxSizeMB: 0.256
        }
        
        try {
          const compressedFile = await imageCompression(file, options);
  
          return compressedFile;
        } catch (error) {
            alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
            console.log(error);
        }
    }
    
    const dataURItoBlob = (dataURI)=>{
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
      
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
      
        // create a view into the buffer
        var ia = new Uint8Array(ab);
      
        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
      
        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        return blob;
      
      }

    const uploadImage = async ()=>{
        const compressedFile = await resizeProfileImage(imgRef.current.files[0])
        let formData = new FormData(); // formData 객체를 생성한다.
    
        formData.append("file", compressedFile);
        dispatch(uploadPatImage({data:formData}))
    }

    const uploadsign = async ()=>{
        console.log('signRef : ', signRef)
        let file = dataURItoBlob(signRef.current.toDataURL('image/png'))
        const compressedFile = await resizeProfileImage(file)
        let formData = new FormData(); // formData 객체를 생성한다.
    
        formData.append("file", compressedFile);
        dispatch(uploadSign({data:formData}))
    }
        
    const checkjumin = ()=>{
        let numfirst = inputData.jumin;
        let numsecond = inputData.jumin_next;
        let jumin = `${numfirst}${numsecond}`
        jumin = jumin.split('');
        let ckarr = [2,3,4,5,6,7,8,9,2,3,4,5];

        // 1. 각자리에 2,3,4,5,6,7,8,9,2,3,4,5를 곱해줌. 단 마지막 자리는 빼놓음
        for(let i=0; i<jumin.length-1; i++){
            jumin[i] = jumin[i] * ckarr[i];
        }
        let juminlast = jumin[jumin.length-1];

        // 2. 각 자리의 숫자를 모두 더함
        let sum = 0;
        for(let i=0; i<jumin.length-1; i++){
            sum += jumin[i];
        }

        //3. 11로 나눈 나머지 값을 구함
        sum = sum % 11;

        // 4. 11에서 결과값을 뺌(단, 마지막 결과가 두자리인 경우 다시 10으로 나눈 나머지 값을 구함)

        sum = 11 - sum;

        if(sum > 9){
            sum = sum % 10;
        }
        
        // 5. 결과가 주민등록번호 마지막 자리와 일치하면 유효한 주민등록번호임
        if(sum == juminlast){
            return true;
        }
        if(sum != juminlast && juminlast != undefined){
            return false;
        }
    }

    const onCommit = async ()=>{
        //--> 예외 검사!
        if(!inputData.name) {
            alert('이름을 입력해야 합니다.')
            return;
        }

        if(!inputData.phone) {
            alert('핸드폰 번호를 입력해야 합니다.')
            return;
        }
        else if(inputData.phone <10) {
            alert('핸드폰 번호가 올바르지 않습니다.')
            return;
        }

        if(!inputData.jumin || !inputData.jumin_next) {
            alert('주민 번호를 입력해야 합니다.')
            return;
        }
        else if(inputData.jumin.length != 6 || inputData.jumin_next.length != 7) {
            alert('주민 번호가 올바르지 않습니다.')
            return;
        }
        else if(checkjumin() == false)
        {
            alert('주민 번호가 올바르지 않습니다.')
            return;
        }
        if(!inputData.addr_post || !inputData.addr || !inputData.addr_next) {
            alert('주소를 입력해야 합니다.')
            return;
        }
        if(!inputData.real_addr_post || !inputData.real_addr || !inputData.real_addr_next) {
            alert('주소를 입력해야 합니다.')
            return;
        }

        if (!inputData.pat_name){
            alert('반려동물 이름을 입력해야 합니다.')
            return;
        }

        if (!inputData.pat_kind || inputData.pat_kind == "품종을 선택해 주세요." || (inputData.pat_kind == "기타" && !inputData.pat_kind_input)){
            alert('품종을 선택해 주세요.')
            return;
        }

        if (!inputData.pat_color || inputData.pat_color == "색깔을 선택하세요." || (inputData.pat_color == "기타" && !inputData.pat_color_input)){
            alert('색깔을 선택하세요.')
            return;
        }

        if (inputData.pat_sex == '') {
            alert('성별을 선택해 주세요.')
            return;
        }

        if (inputData.pat_neutering == '') {
            alert('중성화 여부를 선택해 주세요.')
            return;
        }

        if (!inputData.pat_birth) {
            alert('반려동물 출생일을 선택해 주세요.')
            return;
        }
        if (!inputData.pat_registTime) {
            alert('반려동물 입양일 선택해 주세요.')
            return;
        }
        
        if(inputData.input_RFID && inputData.input_RFID != "" && inputData.input_RFID.length != 15) {
            alert('RFID 번호는 15자리 숫자를 입력해주세요.')
            return;
        }

        if (!inputData.terms_1 || !inputData.terms_2 || !inputData.terms_3 || !inputData.terms_4 || !inputData.terms_5 || !inputData.terms_6) {
            alert('약관에 동의해 주세요.')
            return;
        }
        
        if(signRef.current.isEmpty())
        {
            alert('서명을 입력해 주세요.')
            return;
        }

        if(imgFile)
        {
            await uploadImage()
        }

        await uploadsign()
    }
    
    return (
        <>
            <RegistPatView
                inputData={inputData}
                onChangeInputData={onChangeInputData}
                imgRef={imgRef}
                signRef={signRef}
                onCommit={onCommit}
                imgFile={imgFile}
                setImgFile={setImgFile}
                viewType={_type}
            />
        </>
    );
};

export default withRouter(RegistPatContainer);
