
import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
    createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as selectItem_API from '../../lib/api/selectItem';

const INITIALIZE = 'selectItem/INITIALIZE';

const UPDATE_ORDER_RESULT = 'selectItem/UPDATE_ORDER_RESULT';

const [
    LOAD_SELECT_ITEM,
    LOAD_SELECT_ITEM_SUCCESS,
    LOAD_SELECT_ITEM_FAILURE,
] = createRequestActionTypes('selectItem/LOAD_SELECT_ITEM');

const [
    LOAD_SELECT_ITEM_OPTION,
    LOAD_SELECT_ITEM_OPTION_SUCCESS,
    LOAD_SELECT_ITEM_OPTION_FAILURE,
] = createRequestActionTypes('selectItem/LOAD_SELECT_ITEM_OPTION');

const [
    ORDER_ITEM,
    ORDER_ITEM_SUCCESS,
    ORDER_ITEM_FAILURE,
] = createRequestActionTypes('selectItem/ORDER_ITEM');

export const loadSelectItem = createAction(
    LOAD_SELECT_ITEM,
    ({ type }) => ({
        type
    }),
);

export const loadSelectItemOption = createAction(
    LOAD_SELECT_ITEM_OPTION,
    ({ itemid }) => ({
        itemid
    }),
);


export const orderItem = createAction(
    ORDER_ITEM,
    ({ name, phone, email, price, frontText, backText, optionData, type }) => ({
        name, phone, email, price, frontText, backText, optionData, type
    }),
);


export const initialize = createAction(INITIALIZE, (form) => form);
export const resetOrderResult = createAction(UPDATE_ORDER_RESULT, (form) => form);


const loadSelectItemSaga = createRequestSaga(LOAD_SELECT_ITEM, selectItem_API.loadSelectItem);
const loadSelectItemOptionSaga = createRequestSaga(LOAD_SELECT_ITEM_OPTION, selectItem_API.loadSelectItemOption);
const orderItemSaga = createRequestSaga(ORDER_ITEM, selectItem_API.orderItem);

export function* selectItemSaga() {
    yield takeLatest(LOAD_SELECT_ITEM, loadSelectItemSaga);
    yield takeLatest(LOAD_SELECT_ITEM_OPTION, loadSelectItemOptionSaga);
    yield takeLatest(ORDER_ITEM, orderItemSaga);
}

const initialState = {
    infos:[],
    optionInfos:[],
    orderResult:null,
};

const selectItem = handleActions(
    {
        [INITIALIZE]: (state) => ({
            ...state,
            infos:[],
            optionInfos:[]
        }),
        [UPDATE_ORDER_RESULT]: (state) => ({
            ...state,
            orderResult:null,
        }),
        [LOAD_SELECT_ITEM_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            infos: res,
        }),
        [LOAD_SELECT_ITEM_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
        [LOAD_SELECT_ITEM_OPTION_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            optionInfos: res,
        }),
        [LOAD_SELECT_ITEM_OPTION_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
        [ORDER_ITEM_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            orderResult: res,
        }),
        [ORDER_ITEM_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        })
    },
    initialState,
);

export default selectItem;
