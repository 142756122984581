import qs from 'qs';
import client, { url } from './client';

export const registPat = ({userData}) => {
  return client.post(`${url}/api/registpat/add`, {userData});
};

export const uploadSign = ({data}) => {
    let axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }
    const queryString = qs.stringify({
        id:'registpat_sign'
    });
    return client.post(`${url}/api/image/upload?${queryString}`, data, axiosConfig);
};

export const uploadPatImage = ({data}) => {
    let axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }
    const queryString = qs.stringify({
        id:'registpat_pat'
    });
    return client.post(`${url}/api/image/upload?${queryString}`, data, axiosConfig);
};


//////////////////////////////////////
export const loadMemberList = ({startTime, endTime, type, userkey, keyword}) => {
    const queryString = qs.stringify({
        startTime, endTime, type, userkey, keyword
    });
    console.log(queryString)
    return client.get(`${url}/api/registpat/load?${queryString}`);
}

export const changeUserState = ({user_uuid, userkey, state}) => {
    const queryString = qs.stringify({
        user_uuid, userkey, state
    });
    return client.post(`${url}/api/registpat/changeuserstate?${queryString}`, {});
}

export const changeUserListState = ({user_uuids, userkey, state}) => {
    const queryString = qs.stringify({
        user_uuids, userkey, state
    });
    return client.post(`${url}/api/registpat/changeuserliststate?${queryString}`, {});
}

export const changeUserRFID = ({user_uuid, rfid, userkey}) => {
    const queryString = qs.stringify({
        user_uuid, rfid, userkey
    });
    return client.post(`${url}/api/registpat/updaterfid?${queryString}`, {});
}
//////////////////////////////////////
