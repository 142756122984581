import React from 'react';
import Navigation from '../../components/common/Navigation';
import { useHistory } from 'react-router-dom';
import HeaderContainer from '../../containers/common/HeaderContainer';
import { SideSpacer } from '../../components/common/Spacer';
import {
  CommonContainer,
} from '../../components/common/Container';
import RegistPatMemberContainer from '../../containers/smartstore/RegistPatMemberContainer';

const RegistPatMemberInfoPage = () => {

  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer style={{width: '1510px', margin: '0 75px'}}>
            <RegistPatMemberContainer />
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default RegistPatMemberInfoPage;

