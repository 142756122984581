import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import Responsive from './Responsive';
import Button from './Button';
import { AutoSizer, List } from 'react-virtualized';
import Select from 'react-select';
import { MdLocalParking } from 'react-icons/md';
import { RiCloseFill, RiPlayCircleLine } from 'react-icons/ri';
import { HiMenu } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { BsDot } from 'react-icons/bs';
import Logo from './Logo';
import {
  ImEnter
} from 'react-icons/im';
import {
  RiDoorLine
} from 'react-icons/ri';
import {
  FiSettings,
  FiUser,
  FiBriefcase,
  FiCalendar,
  FiDollarSign,
  FiBarChart,
  FiShoppingCart,
  FiMonitor,
  FiEdit
} from 'react-icons/fi';
import {FaMobileAlt} from 'react-icons/fa';
import {BsPlayBtn, BsBellFill, BsBell, BsPlayCircle} from 'react-icons/bs';
import { Ring } from 'react-awesome-spinners';
import { returnTimeToString, timeValueToDate3, timeValueToDate4, timeValueToDate5, timeValueToDateTime } from '../../lib/commonFunc/commonFunc';

const NotificationModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const NotificationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  height: 95vh;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const NotificationTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const NotificationContentBox = styled.div`
  width: 800px;
  height: 100%;
  overflow: auto;
  & ::-webkit-scrollbar {
    width: 10px;
  }
  & ::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  & ::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const HeaderBlock = styled.div`
  width: 100%;
  min-width: 768px;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  position: absolute;
  display: flex;
  justify-content: flex-end;
`;
const MobileHeaderBlock = styled.div`
  display: none;
  width: 100%;
  height: 50px;
  position: fixed;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  & svg {
    font-size: 2rem;
    color: #141414;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

const RightBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    margin-left: 20px;
  }
`;

const activeStyle = {
  color: palette.webCyan[5],
  fontWeight: 'bold',
};
const StyledMenu = css`
  color: ${palette.webBlack[9]};
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: 130px;
  @media screen and (max-width: 1600px) {
    width: 110px;
    font-size: 0.8rem;
  }
`;

const LogoImg = styled.img`
  width: auto;
  height: 100%;
  padding: 13px 13px 13px 2px;
`;
const Wrapper = styled(Responsive)`
  height: 10rem;
  min-width: 100px;
  width: calc(100% - 240px);
  padding: 0 3rem;

  @media screen and (max-width: 1600px) {
    width: calc(100% - 200px);
  }

  @media screen and (max-width: 768px) {
    justify-content: flex-end;
    margin-left: 10%;
    display: none;
  }
`;

const AccountInfoBlock = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  .logo {
    font-size: 1.125rem;
    font-weight: 800;
    letter-spacing: 2px;
  }
  .right {
    display: flex;
    align-items: center;
  }
`;

const UserInfo = styled.div`
  font-weight: 800;
  margin-right: 1rem;
`;

const History = styled.div`
  color: ${palette.white};
  font-size: 12pt;
  display: flex;
  margin-top: 2rem;
  align-items: center;
  svg {
    color: ${palette.white};
    font-size: 16pt;
  }
  div {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;

const MobileMenuBox = styled.div`
  position: relative;
  width: 240px;
  height: 100%;
  display: flex;
  position: fixed;
  min-width: 150px;
  flex-direction: column;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background: white;
  z-index: 9999;
  left: -240px;
  @media screen and (max-width: 1600px) {
    width: 200px;
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
  transition: .3s ease-in-out;

  &.active {
    left: 0;
  }
`;
const StyledUl = styled.ul`
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  
  & svg.close {
    position: absolute;
    font-size: 1.3rem;
    color: #000;
    top: 15px;
    right: 15px;
  }
`;
const LockerIcon = styled.img`
  width: 16.8px;
  height: 16.8px;
`;

const StyledLi = styled.li`
  margin-top: 1.25rem;
`;
const StyledLink = styled(NavLink)`
  ${StyledMenu}

  span {
    margin-right: 10px;
    font-size: 1.05rem;
    @media screen and (max-width: 1600px) {
      font-size: 0.9rem;
    }
  }
`;

const HeaderRightBox = styled.div`
  display: flex;
`;

const BtnDoorOpen = styled.button`
  border: 0;
  border-radius: .2rem;
  font-weight: bold;
  cursor: pointer;
  padding: 0 10px;
`;

const BtnLogin = styled.button`
  padding: 5px 10px;
  border: 0;
  border-radius: .15em;
  background-color: #141414;
  color: #fff;
  font-size: .9rem;
  font-weight: bold;
  cursor: pointer;
`;

const HeaderIconBox = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
`;

const NotificationIconBox = styled.li`
  position: relative;
  margin-right: 15px;

  & svg {
    color: #141414;
    font-size: 1.65rem;
    padding-top: 2px;
    cursor: pointer;
  }
`;

const DoorIconBox = styled.li`
  & svg {
    color: #141414;
    font-size: 2.1rem;
    padding-top: 2px;
    cursor: pointer;
    margin-right: 10px;
  }
`;

const Count = styled.div`
  background-color: ${palette.webCyan[5]};
  font-size: 1rem;
  color: #fff;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  font-size: .75rem;
  position: absolute;
  font-weight: bold;
  top: 0;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
`;

const NotificationList = styled.ul`
  position: absolute;
  top: calc(100% + 22px);
  right: -8px;
  padding: 5px 15px;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: .3rem;
  z-index: 99;
  width: 260px;
  height: fit-content;

  & svg {
    position: absolute;
    top: -20px;
    right: 10px;
    color: #fff;
    font-size: 1.4rem;
  }
`;

const NotificationListItem = styled.li`
  color: #4d4d4d;
  font-size: .95rem;
  padding: 10px 0;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, .2);

  &:nth-last-child(1) {
    border-bottom: 0;
  }

  & .content {
    width: 100%;
    margin-bottom: 10px;

    & span {
      color: ${palette.webCyan[5]};
    }
  }

  & .registTime {  
    width: 100%;
    text-align: right;
    color: rgba(0, 0, 0, .5);
    font-size: .8rem;
  }
`;

const NotificationItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: .9rem;
  line-height: 140%;
  color: #4d4d4d;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  height: 100%;

  &.delete {
    cursor: pointer;
  }

  & .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 75%;
    padding-left: 50px;
    text-align: left;

    &.none-padding {
      padding-left: 20px;
    }
  }

  & .registTime {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    text-align: right;
    padding-right: 20px;
    padding-left: 20px;
  }

  & span {
    color: ${palette.webCyan[5]};
  }
`;

const NotificationBtnBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-top: 1px solid rgba(0, 0, 0, .1);
`;

const BtnNotificationDelete = styled.button`
  background-color: #fff;
  color: #4d4d4d;
  font-size: .9rem;
  padding: 7px 45px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .2rem;
`
const BtnNotificationClose = styled.button`
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: .9rem;
  padding: 7px 45px;
  cursor: pointer;
  border: 1px ${palette.webGray[5]};
  border-radius: .2rem;
`

const NoticeDeleteIcon = styled.div`

  position: absolute;
  left: 20px;
  padding-top: 5px;

    &.active{
        & svg {
            color: #EC1B23;
            font-size: 1.5rem;
        }
    }
    & svg {
        color: rgba(0, 0, 0, .2);
        font-size: 1.5rem;
    }
`;

const CheckDot = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 2rem;
    color: ${palette.webCyan[5]};
`;

const FreeServiceAlertBtn = styled.div`
  margin-right: 15px;
  border-radius: 4px;
  padding: 5px 15px;
  color: ${palette.webCyan[5]};
  background-color: #fff;
  font-size: .9rem;
  border: 1px solid ${palette.webCyan[5]};
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  &.warn {
    border-color: #F95050;
    color: #F95050;
  }
`;

const FreeServiceAlertModal = styled.div`
  padding: 35px;
  background-color: #fff;
`;

const FreeServiceAlertModalTitle = styled.div`
  color: #141414;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 20px;
`;

const FreeServiceAlertModalBtnBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
`;

const FreeServiceAlertModalBtn = styled.button`
  cursor: pointer;
  width: 130px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 1rem;
  color: #141414;

  &:nth-child(1) {
    margin-right: 14px;
  }
`;

const ServiceIntroBox = styled.div`
  border: 1px solid ${palette.webCyan[5]};
  width: 350px;
  height: 400px;
  display: flex;
  color: #141414;
  flex-direction: column;

  &:nth-child(1) {
    margin-right: 20px;
  }
`;

const ServiceIntroBoxHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid #CCDFF8;
  padding: 14px;
`;

const ServiceIntroTitle = styled.div`
  color: ${palette.webCyan[5]};
  font-weight: bold;
  font-size: 1.6rem;
`;

const ServiceIntroInfoBox = styled.div`
  padding: 14px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ServicePrice = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const BtnServiceStart = styled.button`
  padding: 10px 30px;
  border-radius: 100px;
  color: ${palette.webCyan[5]};
  background-color: #fff;
  border: 1px solid ${palette.webCyan[5]};
  cursor: pointer;

  &:hover {
    opacity: .7;
  }
`;

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: '1px dotted pink',
    color: state.selectProps.menuColor,
    padding: 20,
    'z-index': 500,
  }),
  indicatorSeparator: (provided, state) => ({
    backgroundColor: 'none',
  }),
  indicatorContainer: (provided, state) => ({
    color: '#4d4d4d',
  }),
  input: () => ({
    curosr: 'pointer',
    color: '#4d4d4d',
  }),
  control: (_, { selectProps: { width } }) => ({
    width: width,
    display: 'flex',
    color: '#4d4d4d',
    'z-index': 500,
    curosr: 'pointer',
  }),
  placeholder: () => ({
    cursor: 'pointer',
    color: '#4d4d4d',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const color = '#ff';

    return { ...provided, opacity, transition, color };
  },
};

const Header = ({
  user
}) => {

  const navigation = [
    /* {
      name: 'attendance',
      text: '출석관리',
      url: '/attendance',
    },*/
    {
      name: 'memeber',
      text: '회원 정보',
      url: '/member',
      icon: function () {
        return <FiUser />;
      },
    }
  ];

  const [mobileMenuBoxClass, setMobileMenuBoxClass] = useState("");


  return (
    <>
      <HeaderBlock>
        <Wrapper>
          <AccountInfoBlock>

            <HeaderRightBox>
              <div className="right">
                <HeaderIconBox>
                  
                  <NotificationIconBox>
                  </NotificationIconBox>
                </HeaderIconBox>
                <BtnLogin onClick={()=>{}}>로그아웃</BtnLogin>
              </div>
            </HeaderRightBox>
          </AccountInfoBlock>
        </Wrapper>
      </HeaderBlock>
      <MobileHeaderBlock>
        <HiMenu onClick={() => { setMobileMenuBoxClass("active") }} />
        <RightBox>
        </RightBox>
      </MobileHeaderBlock>

      <MobileMenuBox className={mobileMenuBoxClass}>
        <Logo />
        <StyledUl>
          <RiCloseFill className="close" onClick={() => { setMobileMenuBoxClass(false) }} />
          {navigation.map((menu) => {
            return (
              <StyledLi key={menu.name} style={{ position: 'relative' }}>
                <StyledLink to={menu.url} activeStyle={activeStyle}>
                  <span className={menu.text && menu.text == "locker" ? "active" : ""}>{menu.icon()}</span>
                  <span>{menu.text}</span>
                </StyledLink>
              </StyledLi>
            )
          })}
          {/*           
          {navigation.map((menu) => (
            (currentClub && currentClub.pointActive == 0 && menu.name == 'point')?
            ""
            :
            (currentClub && currentClub.attendActive == 0 && menu.name == 'attend')?
            ""
            :
            ((!mobileProductView || !newIconEndTime || !testClubID) && menu.name == 'mobileProduct') || ((currentClub && currentClub.clubID != testClubID && menu.name == 'mobileProduct') || (mobileProductView === 0 && menu.name == 'mobileProduct'))?
            ""
            :
            <StyledLi key={menu.name} style={{position: 'relative'}}>
              <StyledLink to={menu.url} activeStyle={activeStyle}>
                <span className={menu.text && menu.text=="locker" ? "active" : ""}>{menu.icon()}</span>
                <span>{menu.text}</span>
              </StyledLink>
              {(menu.name === "mobileProduct" && newIconEndTime != 0 && new Date(newIconEndTime).getTime() > new Date().getTime()) ? 
                <span style={{position: 'absolute', left: '90%', padding: '5px 5px 3px 5px', top: 0, backgroundColor: '#FF3E3E', color: '#fff', borderRadius: '4px', fontSize: '.75rem'}}>NEW</span> 
                : 
                ""
              }
            </StyledLi>
          ))} */}
        </StyledUl>
      </MobileMenuBox>
    </>
  );
};

export default React.memo(Header);
