const palette = {
  webBlack: '#141414', // Health Navi main Black
  webGray: [
    '#f8f9fa',
    '#f1f2f5',
    '#e9ecef',
    '#dee2e6',
    '#ced4da',
    '#adb5bd',
    '#868e96',
    '#495057',
    '#343a40',
    '#212529',
    '#646464', // HealthNavi main Gray   webGray[10]
    '#9A9A9A', // HealthNavi sub gray    webGray[11]
    '#c4c4c4', // HealthNavi sub gray    webGray[12]
    '#141414', // HealthNavi sub gray    webGray[13]
    '#E0E0E0', //HealthNavi sub gray    webGray[14]
    '#F0F0F0', //HealthNavi sub gray    webGray[15]
    '#CAD4DD', //16
    'rgba(196, 196, 196, 0.2)', //17
    '#B4C1C9', //18
    '#D6D6D6', //19
    '#f8f8f8', //20
  ],
  webCyan: [
    '#e3fafc',
    '#c5f6fa',
    '#99e9f2',
    '#66d9e8',
    '#3bc9db',
    '#1A73E9', // HealthNavi main blue    webCyan[5]
    '#22b8cf',
    '#15aabf',
    '#1098ad',
    'rgba(26, 115, 233, 0.7)', // HealthNavi main blue    webCyan[9]
    '#2F8EDD', //10
    'rgba(26, 115, 233, 0.4)',
  ],
  hover: 'rgba(204, 204, 204, 0.5)',
  //lockerState
  occupied: '#00b6d0',
  expired: '#a7a9ac',
  disabled: '#e4e4e4',
  valid: '#629eb4',
  hold: '#ffcc4e',
  wait: '#dfc7c1',
  willExpired: '#ff7f7f',
  green: '#81c147',
  warning: '#ff7f7f',

  //Input Color
  inputColor: '#99A3BA',
  inputBorder: '#CDD9ED',
  inputBackground: '#fff',
  inputPlaceHolder: '#CBD1DC',
  inputBorderFocus: '#275EFE',
  groupBackground: '#EEF4FF',
  groupBackgrondFocus: '#678EFE',

  copylight: '#B6C5FF',
  blue1: '#3B63FF',
  blueAlpha1: '#ECF0FF',
  black1: '#262626',
  black2: '#000000', // 0,0,0
  gray1: '#C4C4C4',
  gray2: '#E0E0E0',
  gray3: '#9A9A9A', // 154, 154, 154
  gray4: '#F0F0F0', // 240, 240, 240
  gray5: '#646464',
  gray6: '#F9F9F9',
  gray7: '#DCDCDC', // 178, 178, 178
  gray8: '#E6E6E6', // 230, 230, 230
  orange1: '#FD9627', // 253, 150, 39
  skyBlue1: '#B2C7DA',
  skyBlue2: '#C1D2E1',
  skyBlueAlpha1: '#96A7B6',
  skyBlueAlpha2: '#CAD3DA',

  red1: '#EC1B23', // 237, 27, 35
  white: '#FFFFFF', // 255, 255, 255
  gray0: '#C8C8C8', // 200, 200, 200

  gray9: '#E9E9EF', // 233, 233, 239
  gray10: '#343a40',
  orange2: '#F39C12', // 243, 156, 18
  blue2: '#007AFF', // 0, 122, 255
  green1: '#96C458', // 150, 196, 88
  popupBg:
    'rgba(0.1490196078431373, 0.1490196078431373, 0.1490196078431373, 0.6)',
  popupBg2: 'rgba(0, 0, 0, 0.6)',
  alpha: 'rgba(0,0,0,0)',
};

export default palette;
