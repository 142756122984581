import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import * as registpatAPI from '../../lib/api/registpat';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';

const INITIALIZE = 'registpatlist/INITIALIZE';
const INITIALIZE_BUY = 'registpatlist/INITIALIZE_BUY';
const INITIALIZE_STATERES = 'registpatlist/INITIALIZE_STATERES';
const PAGE_CHANGE = 'registpatlist/PAGE_CHANGE';
const SELECT_USER = 'registpatlist/SELECT_USER';
const USERLIST_CHANGE = 'registpatlist/USERLIST_CHANGE';
const PRINT_LIST_CHANGE = 'registpatlist/PRINT_LIST';

const PAGE_BUY_CHANGE = 'registpatlist/PAGE_BUY_CHANGE';

const [
  LOAD_MEMBER_LIST,
  LOAD_MEMBER_LIST_SUCCESS,
  LOAD_MEMBER_LIST_FAILURE,
] = createRequestActionTypes('registpatlist/LOAD_MEMBER_LIST');

const [
  CHANGE_USER_STATE,
  CHANGE_USER_STATE_SUCCESS,
  CHANGE_USER_STATE_FAILURE,
] = createRequestActionTypes('registpatlist/CHANGE_USER_STATE');

const [
  CHANGE_USER_LIST_STATE,
  CHANGE_USER_LIST_STATE_SUCCESS,
  CHANGE_USER_LIST_STATE_FAILURE,
] = createRequestActionTypes('registpatlist/CHANGE_USER_LIST_STATE');

const [
  LOAD_BUY_LIST,
  LOAD_BUY_LIST_SUCCESS,
  LOAD_BUY_LIST_FAILURE,
] = createRequestActionTypes('registpatlist/LOAD_BUY_LIST');

const [
  CHANGE_USER_RFID,
  CHANGE_USER_RFID_SUCCESS,
  CHANGE_USER_RFID_FAILURE,
] = createRequestActionTypes('searegistpatlistrch/CHANGE_USER_RFID');

export const loadMemberList = createAction(
  LOAD_MEMBER_LIST,
  ({ startTime, endTime, userkey, type, keyword }) => ({
    startTime, endTime, userkey, type, keyword
  }),
);

export const changeUserState = createAction(
  CHANGE_USER_STATE,
  ({ user_uuid, userkey, state }) => ({
    user_uuid, userkey, state
  }),
);

export const changeUserListState = createAction(
  CHANGE_USER_LIST_STATE,
  ({ user_uuids, userkey, state }) => ({
    user_uuids, userkey, state
  }),
);

export const loadBuyList = createAction(
  LOAD_BUY_LIST,
  ({ startTime, endTime, userkey, keyword }) => ({
    startTime, endTime, userkey, keyword
  }),
);


export const changeUserRFID = createAction(
  CHANGE_USER_RFID,
  ({ user_uuid, rfid, userkey }) => ({
    user_uuid, rfid, userkey
  }),
);


export const initialize = createAction(INITIALIZE, (form) => form);
export const initializeStateRes = createAction(INITIALIZE_STATERES, (form) => form);

export const initializeBuy = createAction(INITIALIZE_BUY, (form) => form);
export const pageChange = createAction(PAGE_CHANGE, (form) => form);
export const pageBuyChange = createAction(PAGE_BUY_CHANGE, (form) => form);

export const selectUser = createAction(SELECT_USER, (form) => form);
export const userListChange = createAction(USERLIST_CHANGE, (form) => form);
export const printListChenge = createAction(PRINT_LIST_CHANGE, (form) => form);

const loadMemberListSaga = createRequestSaga(LOAD_MEMBER_LIST, registpatAPI.loadMemberList);
const changeUserStateSaga = createRequestSaga(CHANGE_USER_STATE, registpatAPI.changeUserState);
const changeUserListStateSaga = createRequestSaga(CHANGE_USER_LIST_STATE, registpatAPI.changeUserListState);
//const loadBuyListSaga = createRequestSaga(LOAD_BUY_LIST, searchAPI.loadBuyList);
const changeUserRFIDSaga = createRequestSaga(CHANGE_USER_RFID, registpatAPI.changeUserRFID);

export function* registPatMemberListSaga() {
  yield takeLatest(LOAD_MEMBER_LIST, loadMemberListSaga);
  yield takeLatest(CHANGE_USER_STATE, changeUserStateSaga);
  //yield takeLatest(LOAD_BUY_LIST, loadBuyListSaga);
  yield takeLatest(CHANGE_USER_LIST_STATE, changeUserListStateSaga);
  yield takeLatest(CHANGE_USER_RFID, changeUserRFIDSaga);
  
}

const initialState = {
  result: null,
  error: null,
  memberList: [],
  page:1,
  buyPage:1,
  selectUser:null,
  changeUserStateRes:null,
  changeUserListStateRes:null,
  printList: [],
  buyList:[],
  changeRFIDRes:null,
};

const registPatList = handleActions(
  {
    [INITIALIZE]: (state, { payload: form }) => ({
      ...state,
      [form]: initialState[form],
      result: null,
      error: null,
      page:1,
      buyPage:1,
      selectUser:null,
      buyList:[],
    }),
    [INITIALIZE_STATERES]: (state, { payload: form }) => ({
      ...state,
      changeUserStateRes:null,
      changeUserListStateRes:null,
      changeRFIDRes:null,
    }),
    
    [INITIALIZE_BUY]: (state, { payload: form }) => ({
      ...state,
      buyPage:1,
      buyList:[],
    }),
    [PAGE_CHANGE]: (state, { payload: form }) => ({
      ...state,
      page: form,
    }),
    [PAGE_BUY_CHANGE]: (state, { payload: form }) => ({
      ...state,
      buyPage: form,
    }),
    [PRINT_LIST_CHANGE]: (state, { payload: form }) => ({
      ...state,
      printList: form,
    }),
    [SELECT_USER]: (state, { payload: form }) => ({
      ...state,
      selectUser: form,
    }),
    [USERLIST_CHANGE]: (state, { payload: form }) => ({
      ...state,
      memberList: form,
    }),
    [LOAD_MEMBER_LIST_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      memberList: res
    }),
    [LOAD_MEMBER_LIST_FAILURE]: (state, { payload: res }) => ({
      ...state,
      result: res.result,
    }),
    [LOAD_BUY_LIST_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      buyList: res
    }),
    [LOAD_BUY_LIST_FAILURE]: (state, { payload: res }) => ({
      ...state,
      result: res.result,
    }),
    [CHANGE_USER_STATE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      changeUserStateRes: res
    }),
    [CHANGE_USER_STATE_FAILURE]: (state, { payload: res }) => ({
      ...state,
      result: res.result,
    }),
    [CHANGE_USER_LIST_STATE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      changeUserListStateRes: res
    }),
    [CHANGE_USER_LIST_STATE_FAILURE]: (state, { payload: res }) => ({
      ...state,
      result: res.result,
    }),
    [CHANGE_USER_RFID_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      changeRFIDRes: res
    }),
    [CHANGE_USER_RFID_FAILURE]: (state, { payload: res }) => ({
      ...state,
      result: res.result,
    }),
  },
  initialState,
);
export default registPatList;
