import client, { url } from './client';
import qs from 'qs';

export const login = ({ account, password }) => {
  return client.post(`${url}/api/auth/login`, { account, password });
};

export const logout = (socketKey) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/auth/logout?${queryString}`);
};