import React from 'react';
import Navigation from '../../components/common/Navigation';
import { useHistory } from 'react-router-dom';
import HeaderContainer from '../../containers/common/HeaderContainer';
import { SideSpacer } from '../../components/common/Spacer';
import {
  CommonContainer,
  HistoryBlock,
  HistoryURL,
  HistoryActiveStyle,
  HistoryBlockIcon,
  NoClickHistory,
  MemberInfoContainer,
  HistoryBtn
} from '../../components/common/Container';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { MdChevronRight } from 'react-icons/md';
import RFIDContainer from '../../containers/RFID/RFIDContainer';

const RFIDPage = () => {
  let history = useHistory();

  const onClickBackButton = () => {
    history.push('/rfid')
  }

  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer style={{width: '1510px', margin: '0 75px'}}>
            <RFIDContainer />
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default RFIDPage;
