import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
    createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as RFID_API from '../../lib/api/RFID';
import { sort, sortReverse } from '../../lib/commonFunc/commonFunc';

const INITIALIZE = 'RFID/INITIALIZE';

const [
    LOAD_RFIDINFO,
    LOAD_RFIDINFO_SUCCESS,
    LOAD_RFIDINFO_FAILURE,
] = createRequestActionTypes('RFID/LOAD_RFIDINFO');

const [
    ADD_RFID,
    ADD_RFID_SUCCESS,
    ADD_RFID_FAILURE,
] = createRequestActionTypes('RFID/ADD_RFID');

const [
    DELETE_RFID,
    DELETE_RFID_SUCCESS,
    DELETE_RFID_FAILURE,
] = createRequestActionTypes('RFID/DELETE_RFID');

export const loadRFIDInfo = createAction(
    LOAD_RFIDINFO,
    ({userkey }) => ({userkey}),
);

export const addRFID = createAction(
    ADD_RFID,
    ({ startValue, endValue, userkey }) => ({
        startValue,
        endValue,
        userkey,
    }),
);

export const deleteRFID = createAction(
    DELETE_RFID,
    ({ startValue, endValue, userkey }) => ({
        startValue,
        endValue,
        userkey,
    }),
);

export const initialize = createAction(INITIALIZE, (form) => form);

const loadRFIDSaga = createRequestSaga(LOAD_RFIDINFO, RFID_API.loadRFIDInfo);
const addRFIDSaga = createRequestSaga(ADD_RFID, RFID_API.addRFID);
const deleteRFIDSaga = createRequestSaga(DELETE_RFID, RFID_API.deleteRFID);

export function* RFIDSaga() {
    yield takeLatest(LOAD_RFIDINFO, loadRFIDSaga);
    yield takeLatest(ADD_RFID, addRFIDSaga);
    yield takeLatest(DELETE_RFID, deleteRFIDSaga);
}

const initialState = {
    rfidInfo: null,
    deleteRes: null,
    addRes: null,
};

const RFID = handleActions(
    {
        [INITIALIZE]: (state) => ({
            ...state,
            deleteRes: null,
            addRes: null,
            rfidInfo: null,
        }),
        [LOAD_RFIDINFO_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            rfidInfo: res,
        }),
        [LOAD_RFIDINFO_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
        [ADD_RFID_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            addRes: res
        }),
        [ADD_RFID_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
        [DELETE_RFID_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            deleteRes: res
        }),
        [DELETE_RFID_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
    },
    initialState,
);

export default RFID;
