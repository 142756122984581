import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useSearchParams } from 'react-router-dom';
import {
    initialize,
    loadSelectItem,
    loadSelectItemOption,
    orderItem,
    resetOrderResult
} from '../../modules/selectItem/selectItem';
import SelectItemView from '../../components/selectItem/SelectItemView';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';

const SelectItemContainer = ({ history }) => {

    const {
        selectItemInfos,
        selectItemOptionInfos,
        orderResult,
    } = useSelector(({ selectItem }) => ({
        selectItemInfos: selectItem.infos,
        selectItemOptionInfos: selectItem.optionInfos,
        orderResult:selectItem.orderResult
    }));

    const dispatch = useDispatch();

    const [selectItemIndex, setSelectItemIndex] = useState(-1);
    const [selectOptionData, setSelectOptionData] = useState({
        color:0,
        pendant:0,
        size:0,
        font:0,
        image:0,
        decoration:0,
        count:1,
    });
    const [cartList, setCartList] = useState([]);
    const [cartSelectList, setCartSelectList] = useState([]);
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [frontText, setFrontText] = useState('');
    const [backText, setBackText] = useState('');
    const [termsCheck, setTermsCheck] = useState(false);
    const [termsView, setTermsView] = useState(false);

    const params = new URLSearchParams(window.location.search);
    let _type = params.get("type");
    let _tableType = params.get("tableType");
    let addprice = params.get("addprice");

    if(!addprice)
    {
        addprice = 0
    }

    console.log('addprice : ', addprice);
    console.log('_tableType : ', _tableType);

    useEffect(() => {
        dispatch(initialize());
        dispatch(loadSelectItem({type:_tableType}));
    }, []);

    useEffect(() => {
        if(orderResult)
        {
            setSelectItemIndex(-1);
            setSelectOptionData({
                color:0,
                pendant:0,
                size:0,
                font:0,
                image:0,
                decoration:0,
                colorImage:'',
                pendantImage:'',
                decoration:0,
                count:1,
            });
            //setCartList([]);
            // setCartSelectList([]);
            // setUserName('');
            // setUserPhone('');
            // setUserEmail('');
            // setFrontText('');
            // setBackText('');
            // setTermsCheck(false);
            // setTermsView(false);
            alert('판매자에게 전송했습니다. 수량을 확인하시고 결제해 주세요.')
            dispatch(initialize());
            dispatch(loadSelectItem({type:_tableType}));
        }
    }, [orderResult]);
    


    useEffect(() => {
        if(selectItemIndex != -1 && selectOptionData.color == 0)
        {
            //const colorOptiona = selectItemOptionInfos?getOptionList(selectItemOptionInfos.colordata, addprice, false):[]
            //onSelectOption('color', colorOptiona[0].value)
        }
    }, [selectItemOptionInfos]);
    
    console.log('selectItemInfos : ', selectItemInfos)
    console.log('selectItemOptionInfos : ', selectItemOptionInfos)

    const onSelectItemIndex = (_index) => {
        setSelectOptionData({
            color:0,
            pendant:0,
            size:0,
            font:0,
            image:0,
            decoration:0,
            colorImage:'',
            pendantImage:'',
            count:1,
        })
        dispatch(loadSelectItemOption({itemid:_index}));
        setSelectItemIndex(_index)
    }
    

    const getTotalPrice = (color, pendant, size, decoration, font, image)=>{
        let price = 0
        let colordata = selectItemOptionInfos.colordata.filter((_data)=>{
            return _data.id == color
        })
        if(colordata.length > 0)
        {
            price += parseInt(colordata[0].price) + parseInt(addprice)
        }

        let pendantdata = selectItemOptionInfos.pendantdata.filter((_data)=>{
            return _data.id == pendant
        })
        if(pendantdata.length > 0)
        {
            price += pendantdata[0].price
        }

        let sizedata = selectItemOptionInfos.sizedata.filter((_data)=>{
            return _data.id == size
        })
        if(sizedata.length > 0)
        {
            price += sizedata[0].price
        }

        let decorationdata = selectItemOptionInfos.decorationdata.filter((_data)=>{
            return _data.id == decoration
        })
        if(decorationdata.length > 0)
        {
            price += decorationdata[0].price
        }

        let fontdata = selectItemOptionInfos.fontdata.filter((_data)=>{
            return _data.id == font
        })
        if(fontdata.length > 0)
        {
            price += fontdata[0].price
        }

        let imagedata = selectItemOptionInfos.imagedata.filter((_data)=>{
            return _data.id == image
        })
        if(imagedata.length > 0)
        {
            price += imagedata[0].price
        }
        
        return price
    }

    const onSelectOption = (optionstate, value) => {
        let color = selectOptionData.color;
        let pendant = selectOptionData.pendant;
        let size = selectOptionData.size;
        let font = selectOptionData.font;
        let image = selectOptionData.image;
        let decoration = selectOptionData.decoration;
        let count = selectOptionData.count;
        let totalPrice = selectOptionData.totalPrice;
        let colorImage = selectOptionData.colorImage;
        let pendantImage = selectOptionData.pendantImage;
        

        if(optionstate == 'color')
        {
            color = value
            if(selectItemOptionInfos && selectItemOptionInfos.colordata)
            {
                let colordata = selectItemOptionInfos.colordata.filter((_data)=>{
                    return _data.id == color
                })
                if(colordata.length > 0)
                {
                    colorImage = colordata[0].image
                }
            }
        }
        else if(optionstate == 'pendant')
        {
            pendant = value
            if(selectItemOptionInfos && selectItemOptionInfos.pendantdata)
            {
                let pendantdata = selectItemOptionInfos.pendantdata.filter((_data)=>{
                    return _data.id == pendant
                })
                if(pendantdata.length > 0)
                {
                    pendantImage = pendantdata[0].image
                }
            }
        }
        else if(optionstate == 'size')
        {
            size = value
        }
        else if(optionstate == 'font')
        {
            font = value
        }
        else if(optionstate == 'image')
        {
            image = value
        }
        else if(optionstate == 'decoration')
        {
            decoration = value
        }
        else if(optionstate == 'count')
        {
            count = parseInt(value)
            if(!count)
            {
                count = 0
                //count = '1';
                //totalPrice = 1;
            }
        }
        totalPrice = getTotalPrice(color, pendant, size, decoration, font, image) * count
        
        setSelectOptionData({
            color:color,
            pendant:pendant,
            size:size,
            font:font,
            image:image,
            decoration:decoration,
            count:count,
            colorImage:colorImage,
            pendantImage:pendantImage,
            totalPrice:totalPrice,
        })
    }

    const onAddCart = ()=>{
        let _selectItem = null
        if(selectItemInfos && selectItemInfos.length > 0 && selectItemIndex != -1)
        {
            for (let i = 0; i < selectItemInfos.length; i++) {
                if(selectItemInfos[i].id == selectItemIndex)
                {
                    _selectItem = selectItemInfos[i];
                    break;
                }
            }
        }
        if(!_selectItem)
        {
            alert('목걸이를 선택하세요.')
            return;
        }

        if(!selectOptionData.count)
        {
            alert('최소 수량은 1개 입니다.')
            return;
        }

        let name = _selectItem.name
        let optiondata = {}
        let optionText = ''

        if(selectOptionData.color == 0)
        {
            alert('목걸이 색상을 선택하세요.')
            return;
        }
        else
        {
            let colordata = selectItemOptionInfos.colordata.filter((_data)=>{
                return _data.id == selectOptionData.color
            })
            
            optiondata.color = colordata[0]
            if(optionText != '')
            {
                optionText += ' / '
            }
            optionText += colordata[0].name
        }
        if(selectOptionData.pendant == 0 && selectItemOptionInfos && selectItemOptionInfos.pendantdata.length > 0)
        {
            alert('팬던트를 선택하세요.')
            return;
        }
        else
        {
            if(selectItemOptionInfos && selectItemOptionInfos.pendantdata.length > 0)
            {
                let pendantdata = selectItemOptionInfos.pendantdata.filter((_data)=>{
                    return _data.id == selectOptionData.pendant
                })
                
                optiondata.pendant = pendantdata[0]
                if(optionText != '')
                {
                    optionText += ' / '
                }
                optionText += pendantdata[0].name
            }
        }

        if(selectOptionData.size == 0 && selectItemOptionInfos && selectItemOptionInfos.sizedata.length > 0)
        {
            alert('목걸이 길이를 선택하세요.')
            return;
        }
        else
        {
            if(selectItemOptionInfos && selectItemOptionInfos.sizedata.length > 0)
            {
                let sizedata = selectItemOptionInfos.sizedata.filter((_data)=>{
                    return _data.id == selectOptionData.size
                })
                
                optiondata.size = sizedata[0]
                if(optionText != '')
                {
                    optionText += ' / '
                }
                optionText += sizedata[0].name
            }
        }

        if(selectOptionData.decoration > 0)
        {
            let decorationdata = selectItemOptionInfos.decorationdata.filter((_data)=>{
                return _data.id == selectOptionData.decoration
            })
            
            optiondata.decoration = decorationdata[0]
            if(optionText != '')
            {
                optionText += ' / '
            }
            optionText += decorationdata[0].name
        }

        if(selectOptionData.font > 0)
        {
            let fontdata = selectItemOptionInfos.fontdata.filter((_data)=>{
                return _data.id == selectOptionData.font
            })
            
            optiondata.font = fontdata[0]
            if(optionText != '')
            {
                optionText += ' / '
            }
            optionText += fontdata[0].name
        }
        
        if(selectOptionData.image > 0)
        {
            let imagedata = selectItemOptionInfos.imagedata.filter((_data)=>{
                return _data.id == selectOptionData.image
            })
            
            optiondata.image = imagedata[0]
            if(optionText != '')
            {
                optionText += ' / '
            }
            optionText += imagedata[0].name
        }

        let _cartList = [...cartList];
        _cartList.push({
            item:_selectItem,
            optiondata:optiondata,
            name:name,
            optionText:optionText,
            count:selectOptionData.count,
            totalPrice:selectOptionData.totalPrice,
        })

        setCartList(_cartList);
    }

    const onDeleteCart = ()=>{
        let _cartList = [];
        let isAdd = false;
        for (let i = 0; i < cartList.length; i++) {
            isAdd = true;
            for (let j = 0; j < cartSelectList.length; j++) {
                if(i == cartSelectList[j])
                {
                    isAdd = false;
                }
            }

            if(isAdd)
            {
                _cartList.push(cartList[i]) 
            }
        }
        setCartList(_cartList);
        setCartSelectList([])
    }

    const onClearCart = ()=>{
        setCartList([]);
        setCartSelectList([])
    }

    const onAddCartSelectList = (index)=>{
        let _cartSelectList = [...cartSelectList];
        for (let i = 0; i < _cartSelectList.length; i++) {
            if(index == _cartSelectList[i])
            {
                _cartSelectList.splice(i)
                setCartSelectList(_cartSelectList)
                return;
            }
        }

        _cartSelectList.push(index)
        setCartSelectList(_cartSelectList)
    }

    
    const onChangeUserName = (e)=>{
        setUserName(e.target.value)
    }
    const onChangeUserPhone = (e)=>{
        setUserPhone(e.target.value)
    }
    const onChangeUserEmail = (e)=>{
        setUserEmail(e.target.value)
    }
    const onChangeFrontText = (e)=>{
        setFrontText(e.target.value)
    }
    const onChangeBackText = (e)=>{
        setBackText(e.target.value)
    }

    const onCommit = ()=>{
        if(cartList.length == 0)
        {
            alert('상품이 없습니다.')
            return
        }
        if(!userName)
        {
            alert('수취인 이름을 입력해주세요.')
            return
        }
        if(!userPhone)
        {
            alert('수취인 연락처를 입력해주세요.')
            return
        }
        if(!userEmail)
        {
            alert('수취인 이메일을 입력해주세요.')
            return
        }
        if(!frontText)
        {
            alert('펜던트 앞면 각인 내용을 입력해주세요.')
            return
        }
        // if(!backText)
        // {
        //     alert('펜던트 뒷면 각인 내용을 입력해주세요.')
        //     return
        // }
        if(!termsCheck)
        {
            alert('개인정보 취급방침 및 마케팅메시지의 수신을 동의해 주세요.')
            return
        }
        

        let totalPrice = 0;
        if (cartList && cartList.length > 0) {
            for (let i = 0; i < cartList.length; i++) {
                totalPrice += cartList[i].totalPrice
            }
        }

        let optionData = []
        for (let i = 0; i < cartList.length; i++) {
            optionData.push({
                ...cartList[i].optiondata,
                totalPrice:cartList[i].totalPrice,
                count:cartList[i].count,
                name:cartList[i].name,
            })
        }

        console.log('optionData : ', optionData)

        dispatch(orderItem({
            name:userName,
            phone:userPhone,
            email:userEmail,
            price:totalPrice,
            frontText:frontText,
            backText:backText,
            optionData:optionData,
            type:_type
        }));
        
    }
    
    const getOptionList = (data, _addprice, isempty)=>{
        let result = []
        if(isempty)
        {
            result.push({
                label: '선택 안함',
                value: -1
            })
        }
        if (data && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                result.push({
                    label: `${data[i].name} ${(data[i].price>0)?`(+${inputNumberAutoComma(parseInt(data[i].price) + parseInt(_addprice?_addprice:0))}원)`:''}`,
                    value: data[i].id
                })
            }
        }

        return result;
    }
    
    const colorOption = selectItemOptionInfos?getOptionList(selectItemOptionInfos.colordata, addprice, false):[]
    const pendantOption = selectItemOptionInfos?getOptionList(selectItemOptionInfos.pendantdata, 0, false):[]
    const sizeOption = selectItemOptionInfos?getOptionList(selectItemOptionInfos.sizedata, 0, false):[]
    const fontOption = selectItemOptionInfos?getOptionList(selectItemOptionInfos.fontdata, 0, true):[]
    const imageOption = selectItemOptionInfos?getOptionList(selectItemOptionInfos.imagedata, 0, true):[]
    const decorationOption = selectItemOptionInfos?getOptionList(selectItemOptionInfos.decorationdata, 0, true):[]

    let optionPrice = 0

    if(selectItemOptionInfos && selectItemOptionInfos.colordata && selectOptionData && selectOptionData.color && selectOptionData.color != 0)
    {
        const _colorData = selectItemOptionInfos.colordata.filter((data)=>{
            return data.id === selectOptionData.color;
        })

        if(_colorData.length > 0)
        {
            optionPrice += _colorData[0].price
        }
    }
    if(selectItemOptionInfos && selectItemOptionInfos.decorationdata && selectOptionData && selectOptionData.decoration && selectOptionData.decoration != 0)
    {
        const _decorationData = selectItemOptionInfos.decorationdata.filter((data)=>{
            return data.id === selectOptionData.decoration;
        })

        if(_decorationData.length > 0)
        {
            optionPrice += _decorationData[0].price
        }
    }
    if(selectItemOptionInfos && selectItemOptionInfos.fontdata && selectOptionData && selectOptionData.font && selectOptionData.font != 0)
    {
        const _fontData = selectItemOptionInfos.fontdata.filter((data)=>{
            return data.id === selectOptionData.font;
        })

        if(_fontData.length > 0)
        {
            optionPrice += _fontData[0].price
        }
    }
    if(selectItemOptionInfos && selectItemOptionInfos.imagedata && selectOptionData && selectOptionData.image && selectOptionData.image != 0)
    {
        const _imageData = selectItemOptionInfos.imagedata.filter((data)=>{
            return data.id === selectOptionData.image;
        })

        if(_imageData.length > 0)
        {
            optionPrice += _imageData[0].price
        }
    }
    if(selectItemOptionInfos && selectItemOptionInfos.pendantdata && selectOptionData && selectOptionData.pendant && selectOptionData.pendant != 0)
    {
        const _pendantData = selectItemOptionInfos.pendantdata.filter((data)=>{
            return data.id === selectOptionData.pendant;
        })

        if(_pendantData.length > 0)
        {
            optionPrice += _pendantData[0].price
        }
    }
    if(selectItemOptionInfos && selectItemOptionInfos.sizedata && selectOptionData && selectOptionData.size && selectOptionData.size != 0)
    {
        const _sizeData = selectItemOptionInfos.sizedata.filter((data)=>{
            return data.id === selectOptionData.size;
        })

        if(_sizeData.length > 0)
        {
            optionPrice += _sizeData[0].price
        }
    }

    let selectItem = null
    if(selectItemInfos && selectItemInfos.length > 0 && selectItemIndex != -1)
    {
        for (let i = 0; i < selectItemInfos.length; i++) {
            if(selectItemInfos[i].id == selectItemIndex)
            {
                selectItem = selectItemInfos[i];
                break;
            }
        }
        
    }

    let selectItemInfosSelectList = []
    if(selectItemInfos)
    {
        for (let i = 0; i < selectItemInfos.length; i++) {
            selectItemInfosSelectList.push({
                label: selectItemInfos[i].name,
                value: selectItemInfos[i].id
            })
        }
    }
    
    return (
        <>
            <SelectItemView
                selectItemInfos={selectItemInfos}
                selectItemOptionInfos={selectItemOptionInfos}
                selectItemIndex={selectItemIndex}
                onSelectItemIndex={onSelectItemIndex}
                
                onSelectOption={onSelectOption}
                selectOptionData={selectOptionData}

                colorOption={colorOption}
                decorationOption={decorationOption}
                fontOption={fontOption}
                imageOption={imageOption}
                pendantOption={pendantOption}
                sizeOption={sizeOption}

                optionPrice={optionPrice}
                selectItem={selectItem}

                cartList={cartList}
                cartSelectList={cartSelectList}
                onAddCart={onAddCart}
                onDeleteCart={onDeleteCart}
                onClearCart={onClearCart}
                onAddCartSelectList={onAddCartSelectList}
                onChangeUserName={onChangeUserName}
                userName={userName}
                onChangeUserPhone={onChangeUserPhone}
                userPhone={userPhone}
                onChangeUserEmail={onChangeUserEmail}
                userEmail={userEmail}
                onChangeFrontText={onChangeFrontText}
                frontText={frontText}
                onChangeBackText={onChangeBackText}
                backText={backText}
                termsCheck={termsCheck}
                onChangeTermsCheck={setTermsCheck}
                setTermsView={setTermsView}
                termsView={termsView}

                onCommit={onCommit}
                addprice={addprice}
                viewType={_type}
                tableType={_tableType}
                selectItemInfosSelectList={selectItemInfosSelectList}
            />
        </>
    );
};

export default withRouter(SelectItemContainer);
