import qs from 'qs';
import client, { url } from './client';

export const loadSelectItem = ({type}) => {
    const queryString = qs.stringify({
        type
    });
    console.log(`aaa : ${type}`)
    return client.get(`${url}/api/selectitem/load?${queryString}`);
};

export const loadSelectItemOption = ({itemid}) => {
    const queryString = qs.stringify({
        itemid
    });
    return client.get(`${url}/api/selectitem/loadoption?${queryString}`);
};


export const orderItem = ({name, phone, email, price, frontText, backText, optionData, type}) => {
  return client.post(`${url}/api/selectitem/order`, {name, phone, email, price, frontText, backText, optionData, type});
};
