import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import { NavLink } from 'react-router-dom';

export const StyledContentsBlock = styled.div`
  padding: 2rem;
`;
export const Space = styled.div`
  margin-top: 10rem;
`;

export const MarginTopSpace = styled.div`
  margin-top: 4rem;
`;

export const TableContainer = styled.div`
  flex: 1;
`;

export const ButtonBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const BoxContainer = styled.div`
  z-index: 20;
  background: white;
  width: 60vw;
  height: 80vh;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  top: 6rem;
  padding: 4rem;
`;

export const PaymentContianer = styled.div`
  z-index: 20;
  background: white;
  width: 60vw;
  height: 40vh;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  top: 6rem;
  padding: 4rem;
  margin-top: 5rem;
`;

export const SearchContainer = styled.div`
  z-index: 20;
  background: white;
  width: 60vw;
  height: 80vh;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  top: 6rem;
  padding: 2rem 0;
`;

export const SignUpStyleContainer = styled.div`
  background: white;
  width: 1190px;
  border-radius: 0.2rem;
  padding: 50px;
  margin: 0 auto;
  position: relative;
  top: 50px;
`;

export const CommonContainer = styled.div`
  min-width: 1000px;
  background: white;
  width: 90%;
  margin-bottom: 1rem;
  border-radius: 0.2rem;
  padding: 4rem;
  margin: 0 auto;
  position: relative;
  top: 6rem;

  & + & {
    margin-top: 4rem;
  }
`;

export const MemberInfoContainer = styled.div`
  min-width: 1000px;
  display: flex;
  background: none;
  width: 100%;
  min-width: 1500px;
  margin: 0;
  position: relative;
  top: 6rem;
  margin-bottom: 40px;
  padding: 0 75px;
  
  & div.cover {
    background-color: #fff;
    padding: 40px;
    border-radius: 0.2rem;
  }
  & div.padding-cover {
    margin: 0 5%;
  }
`;

export const CommonContainerPaddingBottomLess = styled.div`
  min-width: 1000px;
  background: white;
  width: 90%;
  margin-bottom: 1rem;
  border-radius: 0.2rem;
  padding: 4rem 4rem 3rem 4rem;
  margin: 0 auto;
  position: relative;
  top: 6rem;

  & + & {
    margin-top: 4rem;
  }
`;

export const NoPaddingContainer = styled.div`
  min-width: 690px;
  background: white;
  width: 90%;
  margin-bottom: 1rem;
  border-radius: 0.2rem;
  margin: 0 auto;
  position: relative;
  top: 6rem;
`;

/* merge container set... */

export const MergeConatiner = styled.div`
  border-radius: 0.2rem;
  background: white;
  width: fit-content;
  top: 6rem;
  position: relative;
  height: fit-content;
  margin: 0 32px;
`;
export const BothSideContainer = styled.div`
  display: flex;
  height: -webkit-fill-available;
`;
export const LeftSideInContainer = styled.div`
  min-width: 600px;
  width: 68.5vw;
  border-right: 1px solid hsla(0, 0%, 0%, 0.08);
  padding: 2rem;
`;
export const RightSideInContainer = styled.div`
  width: 30%;
  min-width: 300px;
  padding: 2rem;
  @media screen and (max-width: 1600px) {
    padding: 1.6rem;
  }
`;

export const BottomFullWidthButtonBlock = styled.div`
  padding: 30px 50px;
`;
/* ... */

export const HorizonWrapper = styled.div`
  display: flex;
  padding: 0 2rem;
`;
export const HorizonContainer = styled.div`
  background: white;
  border-radius: 0.2rem;
  padding: 4rem 2rem;
  margin: 0 auto;
  position: relative;
  top: 6rem;
  max-height: 95vh;
  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}
  ${(props) =>
    props.ratio &&
    css`
      flex: ${props.ratio};
    `}
    & + & {
    margin-left: 50px;
  }
  @media screen and (max-width: 1600px) {
    padding: 3rem 1.5rem;
  }
`;

export const PrescriptionRegistContainer = styled.div`
  background: white;
  border-radius: 0.2rem;
  padding: 3rem 2rem;
  min-width: 45%;
  /* height: 85vh; */
  position: relative;
  top: 6rem;
  margin-right: 50px;
  max-height: 85vh;
`;

export const RegistFormBlock = styled.div`
  background: white;
  width: 90%;
  margin-bottom: 1rem;
  border-radius: 0.2rem;
  padding: 4rem;
  margin: 0 auto;
  position: relative;
  top: 6rem;

  & + & {
    border-top: 0.7px solid #ccc;
  }
`;

export const RegistFormBackground = styled.div`
  background: ${palette.webCyan[5]};
  width: 100%;
  height: 20vh;
  position: absolute;
  left: 0;
  top: 0;
`;

export const LoadingBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
`;

export const StateIcon = styled.div`
margin-left: 8px;
display:inline-block;
padding: 4px 8px;

font-size:0.8rem;
${(props) =>
  props.state
    ? props.state === '유효' &&
      css`
        background: ${palette.webCyan[5]};
        color: ${palette.white};
        border-radius: 4px;
      `
    : css``}
  ${(props) =>
    props.state
      ? props.state === '만료' &&
        css`
          background: ${palette.expired};
          color: ${palette.white};
          border-radius: 4px;
        `
      : css``}
  ${(props) =>
    props.state
      ? props.state === '대기' &&
        css`
          background: ${palette.wait};
          color: ${palette.white};
          border-radius: 4px;
        `
      : css``}
  ${(props) =>
    props.state
      ? props.state === '홀드' &&
        css`
          background: ${palette.hold};
          color: ${palette.white};
          border-radius: 4px;
        `
      : css``}
  ${(props) =>
    props.state
      ? props.state === '만료 예정' &&
        css`
          background: ${palette.willExpired};
          color: ${palette.white};
          border-radius: 4px;
        `
      : css``}

`;

export const HistoryBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  & svg {
    font-size: 24pt !important;
    color: ${palette.webGray[6]} !important;
    cursor: pointer;
  }
`;

export const HistoryURI = styled(NavLink)`
  display: flex;
  align-items: center;
  svg {
    font-size: 16pt;
  }
`;

export const HistoryActiveStyle = {
  color: palette.webGray[10],
  fontWeight: 'bold',
};

export const HistoryBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  svg {
    font-size: 16pt;
  }
`;

export const HistoryBlockIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 16pt;
    color: ${palette.webGray[10]};
  }
`;

export const HistoryURL = styled(NavLink)`
  display: flex;
  align-items: center;
  color: ${palette.webGray[10]};
  svg {
    font-size: 16pt;
  }
  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
  }
`;

export const NoClickHistory = styled.div`
  display: flex;
  align-items: center;
  color: ${palette.webGray[10]};
  font-weight: bold;
  cursor: pointer;
`;

export const NormalHistory = styled.div`
  display: flex;
  align-items: center;
  color: ${palette.webGray[10]};
  cursor: default;
`;
