import React, { useEffect } from 'react';
import SelectItemTemplate from '../../components/selectItem/SelectItemTemplate';
import RegistPatContainer from '../../containers/registpat/RegistPatContainer';

const RegistPat = () => {
  return (
    <>
      <RegistPatContainer/>
    </>
  );
};

export default RegistPat;

