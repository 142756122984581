
import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
    createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as registpat_API from '../../lib/api/registpat';

const INITIALIZE = 'registpat/INITIALIZE';

const UPDATE_REGIST_PAT_RESULT = 'registpat/UPDATE_REGIST_PAT_RESULT';
const UPDATE_UPLOAD_SIGN_RESULT = 'registpat/UPDATE_UPLOAD_SIGN_RESULT';
const UPDATE_UPLOAD_PAT_IMAGE_RESULT = 'registpat/UPDATE_UPLOAD_PAT_IMAGE_RESULT';

const [
    LOAD_REGIST_PAT,
    LOAD_REGIST_PAT_SUCCESS,
    LOAD_REGIST_PAT_FAILURE,
] = createRequestActionTypes('registpat/LOAD_REGIST_PAT');

const [
    REGIST_PAT,
    REGIST_PAT_SUCCESS,
    REGIST_PAT_FAILURE,
] = createRequestActionTypes('registpat/REGIST_PAT');

const [
    UPLOAD_SIGN,
    UPLOAD_SIGN_SUCCESS,
    UPLOAD_SIGN_FAILURE,
] = createRequestActionTypes('registpat/UPLOAD_SIGN');

const [
    UPLOAD_PAT_IMAGE,
    UPLOAD_PAT_IMAGE_SUCCESS,
    UPLOAD_PAT_IMAGE_FAILURE,
] = createRequestActionTypes('registpat/UPLOAD_PAT_IMAGE');

export const loadRegistPat = createAction(
    LOAD_REGIST_PAT,
    ({ startTime, endTime, userkey, type, keyword }) => ({
        startTime, endTime, userkey, type, keyword
    }),
);

export const updatePat = createAction(
    REGIST_PAT,
    ({ userData }) => ({
        userData
    }),
);

export const uploadSign = createAction(
    UPLOAD_SIGN,
    ({ data }) => ({
        data
    }),
);

export const uploadPatImage = createAction(
    UPLOAD_PAT_IMAGE,
    ({ data }) => ({
        data
    }),
);


export const initialize = createAction(INITIALIZE, (form) => form);
export const resetRegistPatResult = createAction(UPDATE_REGIST_PAT_RESULT, (form) => form);
export const resetUploadSignResult = createAction(UPDATE_UPLOAD_SIGN_RESULT, (form) => form);
export const resetUploadPatImageResult = createAction(UPDATE_UPLOAD_PAT_IMAGE_RESULT, (form) => form);


//const loadRegistPatSaga = createRequestSaga(LOAD_REGIST_PAT, registpat_API.loadRegistPat);
const _registPatSaga = createRequestSaga(REGIST_PAT, registpat_API.registPat);
const uploadSignSaga = createRequestSaga(UPLOAD_SIGN, registpat_API.uploadSign);
const uploadPatImageSaga = createRequestSaga(UPLOAD_PAT_IMAGE, registpat_API.uploadPatImage);

export function* registPatSaga() {
    //yield takeLatest(LOAD_REGIST_PAT, loadRegistPatSaga);
    yield takeLatest(REGIST_PAT, _registPatSaga);
    yield takeLatest(UPLOAD_SIGN, uploadSignSaga);
    yield takeLatest(UPLOAD_PAT_IMAGE, uploadPatImageSaga);
}

const initialState = {
    registPatList:[],
    registPatResult:null,
    uploadSignResult:null,
    uploadPatImageResult:null,
};

const registPat = handleActions(
    {
        [INITIALIZE]: (state) => ({
            ...state,
            registPatList:[],
            registPatResult:null,
            uploadSignResult:null,
            uploadPatImageResult:null,
        }),
        [UPDATE_REGIST_PAT_RESULT]: (state) => ({
            ...state,
            registPatResult:null,
        }),
        [UPDATE_UPLOAD_SIGN_RESULT]: (state) => ({
            ...state,
            uploadSignResult:null,
        }),
        [UPDATE_UPLOAD_PAT_IMAGE_RESULT]: (state) => ({
            ...state,
            uploadPatImageResult:null,
        }),
        [LOAD_REGIST_PAT_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            registPatList: res,
        }),
        [LOAD_REGIST_PAT_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
        [REGIST_PAT_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            registPatResult: res,
        }),
        [REGIST_PAT_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
        [UPLOAD_SIGN_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            uploadSignResult: res,
        }),
        [UPLOAD_SIGN_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        }),
        [UPLOAD_PAT_IMAGE_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            uploadPatImageResult: res,
        }),
        [UPLOAD_PAT_IMAGE_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
        })
    },
    initialState,
);

export default registPat;
