/*global daum*/
/*global kakao*/
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DaumPostcode from 'react-daum-postcode';

const PostBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const KakaoPost = ({ findAddress }) => {
  const handleAddress = data => {
    let fullAddress = {
        address:data.roadAddress,
        postcode:data.zonecode
    };
    
    findAddress(fullAddress);
    window.close();
  };

  return (
    <PostBlock>
      <DaumPostcode onComplete={handleAddress}></DaumPostcode>
    </PostBlock>
  );
};

export default KakaoPost;