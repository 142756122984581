import React from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import { Link } from 'react-router-dom';

const buttonStyle = css`
  min-width:65px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.25rem 1rem;
  color: white;
  outline: none;
  cursor: pointer;
  font-family: 'roboto';
  background: ${palette.webGray[8]};
  &:hover {
    background: ${palette.webGray[6]};
  }
  @media screen and (max-width: 1600px) {
        font-size: .9rem;
     
      }
${(props) =>
  props.rect &&
  css`
    border-radius: 0px;
    background: ${palette.webGray[6]};
    &:hover {
      background: ${palette.webCyan[5]};
    }
  `}

  ${(props) =>
    props.sale &&
    css`
      width: 48% !important;
      color: #fff;
      border-radius: .2rem;
      height: 40px;
      border: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    `}


  ${(props) =>
    props.fullWidth &&
    css`
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      width: 100%;
      font-size: 1.125rem;

      @media screen and (max-width: 1600px) {
        font-size: 1rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }
    `}

  ${(props) =>
    props.cyan &&
    css`
      background: #005dac;
      &:hover {
        background: #005dac;
      }
    `}
  ${(props) =>
    props.red &&
    css`
      background: ${palette.red1};
      &:hover {
        background: ${palette.red1};
        opacity: 0.3;
      }
    `}
   
    ${(props) =>
      props.marginLeft &&
      css`
        margin-left: 10px;
      `}
    ${(props) =>
      props.padding &&
      css`
        padding: 10px 0;
      `}
   
    &:disabled {
    background: ${palette.webGray[3]};
    color: ${palette.webGray[5]};
    cursor: not-allowed;
  }

`;

const StyledButton = styled.button`
  ${buttonStyle}
`;
const StyledLink = styled(Link)`
  ${buttonStyle}
`;

const Button = (props) => {
  return props.to ? (
    <StyledLink {...props} cyan={props.cyan ? 1 : 0} red={props.red ? 1 : 0} />
  ) : (
    <StyledButton {...props} />
  );
};

export default Button;
