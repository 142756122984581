import ReactToPrint, { useReactToPrint } from 'react-to-print';
import React, { useRef, useEffect, useState } from 'react';
import HTMLPAGE from '../../resources/html/page1t.txt';
import HTMLPAGE_SECOND from '../../resources/html/page2t.txt';
import styled, { css } from 'styled-components';
import {CUSTOM_FONTS} from '../../fonts/font';
import Logo from '../../resources/images/svg/logo.svg'

const ReportBlock = styled.div`
    @page {
        size: A4;
        margin: 16mm;
    }
`;

const ReportItemView = ({
    item,
    itemID,
    resetTime
}) => {
    const [htmltext1, setHtmltext1] = useState("")
    const [htmltext2, setHtmltext2] = useState("")

    useEffect(() => {
        if(!item)
        {
            return;
        }

        fetch(HTMLPAGE)
        .then((r) => r.text())
        .then(text => {
            setHtmltext1(text);
            resetTime();
            setTimeout(() => {
                let item_Obect = document.getElementById(itemID);

                console.log("item_Obect1 : ", item_Obect)
                let name_input = item_Obect.getElementsByClassName('name_input')[0];
                let jumin_input = item_Obect.getElementsByClassName('jumin_input')[0];
                let phone_input = item_Obect.getElementsByClassName('phone_input')[0];
                let address_input = item_Obect.getElementsByClassName('address_input')[0];
                let address_real_input = item_Obect.getElementsByClassName('address_real_input')[0];
                let pat_registnumber_input = item_Obect.getElementsByClassName('pat_registnumber_input')[0];
                let pat_name_input = item_Obect.getElementsByClassName('pat_name_input')[0];
                let pat_kind_input = item_Obect.getElementsByClassName('pat_kind_input')[0];
                let pat_color_input = item_Obect.getElementsByClassName('pat_color_input')[0];
                let pat_sex_input = item_Obect.getElementsByClassName('pat_sex_input')[0];
                let pat_neutering_input = item_Obect.getElementsByClassName('pat_neutering_input')[0];
                let pat_birth_input = item_Obect.getElementsByClassName('pat_birth_input')[0];
                let pat_regist_input = item_Obect.getElementsByClassName('pat_regist_input')[0];
                let regist_input = item_Obect.getElementsByClassName('regist_input')[0];
                let sign_name_input = item_Obect.getElementsByClassName('sign_name_input')[0];
                let sign_image_input = item_Obect.getElementsByClassName('sign_image_input')[0];
                
                console.log('item : ', item)

                // pat_registnumber_input .innerText = 등록 번호 입력;
                pat_registnumber_input.innerText = item.RFID?item.RFID:"";
                name_input.innerText = item.name;
                jumin_input.innerText = `${item.jumin} - ${item.jumin_next}`;
                phone_input.innerText = item.phone;

                if(item.addr == item.real_addr && item.addr_next == item.real_addr_next && item.addr_post == item.real_addr_post)
                {
                    address_input.innerText = `우편번호(${item.addr_post}) ${item.addr} ${item.addr_next}`;
                    address_real_input.innerText = "";
                }
                else
                {
                    address_input.innerText = `주소 - 우편번호(${item.addr_post}) ${item.addr} ${item.addr_next}`;
                    address_real_input.innerText = `현재 거주지 - 우편번호(${item.real_addr_post}) ${item.real_addr} ${item.real_addr_next}`;
                }

                pat_name_input.innerText = item.pat_name;
                pat_kind_input.innerText = item.pat_kind;
                pat_color_input.innerText = item.pat_color;
                pat_sex_input.innerText = item.pat_sex == "1"?'수':'암';
                pat_neutering_input.innerText = item.pat_neutering == "1"?'여':'부';
                let birthDate = new Date(item.pat_birth * 1000);
                pat_birth_input.innerText = `${birthDate.getFullYear()}-${birthDate.getMonth()+1}-${birthDate.getDate()}`;
                let patRegistDate = new Date(item.pat_registTime * 1000);
                pat_regist_input.innerText = `${patRegistDate.getFullYear()}-${patRegistDate.getMonth()+1}-${patRegistDate.getDate()}`;
                
                let registDate = new Date();
                let registStr = `${registDate.getFullYear()}년`;
                registStr += '            ';
                registStr += `${registDate.getMonth()+1}월`;
                registStr += '             ';
                registStr += `${registDate.getDate()}일`;
                regist_input.innerText = registStr;

                sign_name_input.innerText = item.name;
                sign_image_input.style.backgroundImage = `URL("${item.sign}")`;

            }, 3000)
        })
        

        fetch(HTMLPAGE_SECOND)
        .then((r) => r.text())
        .then(text => {
            setHtmltext2(text);
            setTimeout(() => {
                let item_Obect = document.getElementById(itemID);
                console.log("item_Obect2 : ", item_Obect)
                
                let sign_name_input2 = item_Obect.getElementsByClassName('sign_name_input2')[0];
                let sign_image_input2 = item_Obect.getElementsByClassName('sign_image_input2')[0];
                let sign_name_input3 = item_Obect.getElementsByClassName('sign_name_input3')[0];
                let sign_image_input3 = item_Obect.getElementsByClassName('sign_image_input3')[0];
                let sign_name_input4 = item_Obect.getElementsByClassName('sign_name_input4')[0];
                let sign_image_input4 = item_Obect.getElementsByClassName('sign_image_input4')[0];
                sign_name_input2.innerText = item.name;
                sign_name_input3.innerText = item.name;
                sign_name_input4.innerText = item.name;
                sign_image_input2.style.backgroundImage = `URL("${item.sign}")`;
                sign_image_input3.style.backgroundImage = `URL("${item.sign}")`;
                sign_image_input4.style.backgroundImage = `URL("${item.sign}")`;

            }, 3000)
        })
    }, [item]);

    const componentRef = useRef();

    const handlePrint = useReactToPrint({content: () =>{return componentRef.current}, fonts:CUSTOM_FONTS});
    
    const iframePart1 = () => {
        return {
            __html: htmltext1,
        };
    };
    const iframePart2 = () => {
        return {
            __html: htmltext2,
        };
    };

    return (
        item?
        <div id={`${itemID}`}>
            <div style={{position:'relative'}}>
                <div style={{pageBreakBefore:'always', marginBottom:30}}>
                    <div style={{position:'absolute', top:0, right:0, width: '100px', height: '100px'}}>
                        <object type="image/svg+xml" data={Logo} style={{width: '100px', height: 'auto'}} />
                    </div>
                    <ReportBlock
                        dangerouslySetInnerHTML={iframePart1()}
                    />
                </div>
                <div style={{pageBreakBefore:'always'}}>
                    <ReportBlock
                        dangerouslySetInnerHTML={iframePart2()}
                    />
                </div>
            </div>
        </div>:null
    );
};

export default React.memo(ReportItemView);
