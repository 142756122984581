
import React, { useEffect, useState, useCallback } from 'react';
import MemberList from '../../components/member/MemberList';
import { useSelector, useDispatch } from 'react-redux';
import {
  initialize,
  selectUser,
  loadMemberList,
  pageChange,
  printListChenge,
} from '../../modules/commons/search';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const MemberListContainer = ({ history }) => {

  const {
    memberList,
    page
  } = useSelector(({ search }) => ({
    memberList: search.memberList,
    page:search.page
  }));

  const [searchInfo, setAccountInfo] = useState({
    startTime: "",
    endTime: "",
    dateType: "",
    type: '0',
    keyword: "",
  })
  
  const [allchecked, setAllChecked] = useState(false)
  const [checkList, setCheckList] = useState([])

  const dispatch = useDispatch();


  if(memberList && memberList.length != checkList.length)
  {
    let tempcheckList = []
    for (let i = 0; i < memberList.length; i++) {
      tempcheckList.push(false);
    }
    setCheckList(tempcheckList);
  }

  useEffect(() => {
    if (!memberList) {
      dispatch(initialize());
      let userSearchPrevStr = (localStorage.getItem("userSearchPrev"));
      console.log('userSearchPrevStr : ', userSearchPrevStr)
      if (userSearchPrevStr) {
        let userSearchPrev = JSON.parse(userSearchPrevStr);

        let startTime = moment('2023-08-18').startOf('day').unix();
        let endTime = moment().endOf('day').unix();

        if (userSearchPrev.startTime) {
          startTime = moment(userSearchPrev.startTime).startOf('day').unix()
        }
        if (userSearchPrev.endTime) {
          endTime = moment(userSearchPrev.endTime).endOf('day').unix();
        }

        localStorage.setItem("userSearchPrev", "");

        let tempUser = (localStorage.getItem("user"))
        dispatch(loadMemberList({
          startTime: startTime,
          endTime: endTime,
          userkey: tempUser
        }),);

        setAccountInfo({
          startTime: userSearchPrev.startTime,
          endTime: userSearchPrev.endTime,
          dateType: userSearchPrev.dateType,
          type: userSearchPrev.type
        })
      }
    }
  }, []);

  const onSetPeriod = (unit) => {
    let startTime = '';

    console.log('unit : ', unit)
    if (unit == '') {
      startTime = '2023-08-18';
    }
    else {
      startTime = moment().subtract(1, unit).format('YYYY-MM-DD');
    }

    const endTime = moment().format('YYYY-MM-DD');
    setAccountInfo({
      startTime: startTime,
      endTime: endTime,
      dateType: unit,
      type: searchInfo.type,
      keyword: searchInfo.keyword,
    })
    // dispatch(
    //   changeField({
    //     form: 'detailMember',
    //     type: 'startTime',
    //     val: unit === '' ? '' : startTime,
    //   }),
    // );
    // dispatch(
    //   changeField({
    //     form: 'detailMember',
    //     type: 'endTime',
    //     val: unit === '' ? moment().format('YYYY-MM-DD') : endTime,
    //   }),
    // );
  };

  const onChangeStartTime = (startTime) => {
    console.log('onChangeStartTime : ', startTime)
    setAccountInfo({
      startTime: startTime,
      endTime: searchInfo.endTime,
      dateType: searchInfo.dateType,
      type: searchInfo.type,
      keyword: searchInfo.keyword,
    })
  };

  const onChangeEndTime = (endTime) => {
    console.log('onChangeEndTime : ', endTime)
    setAccountInfo({
      startTime: searchInfo.startTime,
      endTime: endTime,
      dateType: searchInfo.dateType,
      type: searchInfo.type,
      keyword: searchInfo.keyword,
    })
  };

  const onChangeKeyword = (keyword) => {
    setAccountInfo({
      startTime: searchInfo.startTime,
      endTime: searchInfo.endTime,
      dateType: searchInfo.dateType,
      type: searchInfo.type,
      keyword: keyword,
    })
  };

  const onChangeType = (e) => {

    const { name, value } = e.target;

    setAccountInfo({
      startTime: searchInfo.startTime,
      endTime: searchInfo.endTime,
      dateType: searchInfo.dateType,
      type: value,
      keyword: searchInfo.keyword,
    })
    console.log('onChangeType : ', value)
  };

  const onSearch = () => {

    let startTime = moment('2023-08-18').startOf('day').unix();
    let endTime = moment().endOf('day').unix();

    if (searchInfo.startTime) {
      startTime = moment(searchInfo.startTime).startOf('day').unix()
    }
    if (searchInfo.endTime) {
      endTime = moment(searchInfo.endTime).endOf('day').unix();
    }

    console.log('onSearch : ', searchInfo.startTime)
    console.log('onSearch : ', searchInfo.endTime)

    let userSearchPrev = {
      startTime: searchInfo.startTime,
      endTime: searchInfo.endTime,
      dateType: searchInfo.dateType,
      type: searchInfo.type,
      keyword: searchInfo.keyword,
    }
    localStorage.setItem("userSearchPrev", JSON.stringify(userSearchPrev));

    let tempUser = (localStorage.getItem("user"));
    console.log('tempUseraaa : ', tempUser);
    dispatch(loadMemberList({
      startTime,
      endTime,
      userkey: tempUser,
      type: searchInfo.type,
      keyword: searchInfo.keyword,
    }),)
  }

  const onChangePage = (page) => {
    dispatch(pageChange(page));
  }

  const setSelectItem = (item) => {
    dispatch(selectUser(item));
    history.push('/member/info');
  }

  
  const onChangeAllChecked = (check) => {
    let templist = []
    for (let i = 0; i < checkList.length; i++) {
      templist.push(check)
    }
    setAllChecked(check)
    setCheckList(templist)
  }

  const onChangeChecked = (index, check) => {
    let templist = []
    for (let i = 0; i < checkList.length; i++) {
      if(i == index)
      {
        templist.push(check)
      }
      else
      {
        templist.push(checkList[i])
      }
    }
    setCheckList(templist)
  }

  const onClickPrint = ()=>{
    let printList = []
    for (let i = 0; i < checkList.length; i++) {
      if(checkList[i] && (memberList.length > i) && (memberList[i].state > 1))
      {
        printList.push(memberList[i]);
      }
    }
    if(printList.length > 0)
    {
      dispatch(printListChenge(printList));
      history.push('/member/print');
    }
  }

  return (
    <>
      <MemberList
        //form={memberForm}
        onChangeStartTime={onChangeStartTime}
        onChangeEndTime={onChangeEndTime}
        onChangeKeyword={onChangeKeyword}
        onSetPeriod={onSetPeriod}
        searchInfo={searchInfo}
        onSearch={onSearch}
        onChangeType={onChangeType}
        setSelectItem={setSelectItem}
        onChangePage={onChangePage}
        page={page}
        allchecked={allchecked}
        onChangeAllChecked={onChangeAllChecked}
        checkList={checkList}
        onChangeChecked={onChangeChecked}
        onClickPrint={onClickPrint}
      />
    </>
  );
};

export default withRouter(MemberListContainer);
