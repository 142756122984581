import React, { useEffect } from 'react';
import SelectItemTemplate from '../../components/selectItem/SelectItemTemplate';
import SelectItemContainer from '../../containers/selectItem/SelectItemContainer';

const SelectItem = () => {
  return (
    <SelectItemTemplate>
        <SelectItemContainer/>
    </SelectItemTemplate>
  );
};

export default SelectItem;

