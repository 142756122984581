import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import palette from '../../lib/styles/palette';
import { FiRotateCw } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { timeValueToDate, timeValueToDate6, timeValueToDateTime } from '../../lib/commonFunc/commonFunc';
import Pagination from '@material-ui/lab/Pagination';

const MemberBlock = styled.div`
  margin-top: 2.5rem;
`;
const ConditionBlock = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${palette.webGray[17]};
`;
const ConditionName = styled.div`
  padding: 20px 32px;
  background: #A5C1E7;
  text-align: center;
  margin-right: 20px;
  color: ${palette.white};
  font-family: 'Noto Sans kr';
  min-width: 150px;

  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
    padding: 20px 28px;
    min-width: 120px;
  }
`;
const StyledForm = styled.form`
  display: flex;
  align-items: center;
`;
const ValueBlock = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.noFlex &&
    css`
      display: block;
    `}
`;

const TextSpace = styled.div`
  padding: 0 10px;
  font-size: 20pt;
`;
const SearchBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 0;
    margin-bottom: 0;
`;
const SearchButtonView = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[17]};
`;
const TD = styled.td`
  text-align: center;
  min-width: 60px;
  width: 8%;
`;

const Basetd = styled.td`
  text-align: center;
  min-width: 150px;
`;

const ImageBox = styled.div`
  height: 50px;
  justify-content: center;
  align-items: center;

  & img {
    width: 50px;
    height: 50px;
  }
`;
const SearchListBlock = styled.div`
  padding-top: 40px;
    ${(props) => props.height ? css`height:${props.height}vh;` : css`height: auto;`}
`;

const MemberTable = styled.table`
  width: 100%;
  text-align: center;
  table-layout: fixed;
`
const MemberTableHeader = styled.thead`
  width: 100%;
  border-top: 1px solid ${palette.black1};
  border-bottom: 1px solid ${palette.webGray[5]};

  & tr{
    cursor: default;
    & td{
      font-weight: bold;
      padding: 12px 10px;
    }
  }
`

const MemberTableBody = styled.tbody`
  width: 100%;
  & tr {
    width: 100%;
  }
  
  & td {    
    font-size: .95rem;
    border-bottom: 1px solid ${palette.webGray[17]};
    text-overflow:ellipsis; 
    overflow:hidden; 
    white-space:nowrap;
    padding: 12px 10px;
  }
  
  & td:nth-child(1) {    
    padding: 12px 10px;
  }
`

const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;
  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }
`;

const StyledInput = styled.input`
  font-size: 1rem;
  border: 1px solid ${palette.gray3};
  padding: 0.5rem;
  outline: none;
  width: 300px;
  margin-left: 1rem;
  margin-right: 1rem;
  &:focus {
    color: ${palette.blue1};
  }
  & + & {
    margin-top: 1rem;
  }
`;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  input: {
    width: '65px',
  },
  buttons: {
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const BuyListView = ({
  onChangeStartTime,
  onChangeEndTime,
  onSetPeriod,
  onChangeKeyword,
  searchInfo,
  onSearch,
  onChangePage,
  page,
}) => {
  const {
    buyList,
    loading
  } = useSelector(({ search, loading }) => ({
    buyList: search.buyList,
    loading: loading[`search/LOAD_MEMBER_LIST`],
  }));

  let startindex = (page - 1) * 20;
  let endindex = (page * 20);

  let userlist = []
  for (let i = startindex; i < endindex; i++) {
    if (buyList.length > i) {
      userlist.push(buyList[i]);
    }
  }

  const classes = useStyles();
  return (
    <MemberBlock>
      <ConditionBlock>
        <ConditionName>기간</ConditionName>
        <ValueBlock>
          <StyledForm noValidate>
            <TextField
              id="startTime"
              label="시작일"
              name="startTime"
              type="date"
              value={searchInfo.startTime}
              onChange={(e) => onChangeStartTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextSpace>~</TextSpace>
            <TextField
              id="endTime"
              label="종료일"
              name="endTime"
              type="date"
              value={searchInfo.endTime}
              onChange={(e) => onChangeEndTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.buttons}>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button onClick={() => onSetPeriod('days')}>1일</Button>
                <Button onClick={() => onSetPeriod('week')}>1주일</Button>
                <Button onClick={() => onSetPeriod('months')}>1달</Button>
                <Button onClick={() => onSetPeriod('')}>전체</Button>
              </ButtonGroup>
            </div>
          </StyledForm>
        </ValueBlock>
      </ConditionBlock>
      <ConditionBlock>
        <ConditionName>검색</ConditionName>
        <ValueBlock>
          <StyledInput
              name="keyword"
              placeholder="이름 또는 아이디를 입력하세요."
              type="text"
              onChange={(e)=>{
                onChangeKeyword(e.target.value);
              }}
              value={searchInfo.keyword}
          />
        </ValueBlock>
      </ConditionBlock>
      <SearchBlock>
        <Button onClick={onSearch}>
          <SearchButtonView>
            <FiRotateCw></FiRotateCw>
            <div style={{ paddingLeft: 10 }}>검색</div>
          </SearchButtonView>
        </Button>
      </SearchBlock>

      <SearchListBlock>
        {
          (buyList && buyList.length > 0) &&
          <MemberTable>
            <MemberTableHeader>
              <tr>
                <td width="15%">회원 아이디</td>
                <td width="10%">회원 이름</td>
                <td width="10%">핸드폰 번호</td>
                <td width="10%">결제일</td>
                <td width="25%">상품명</td>
                <td width="10%">가격</td>
                <td width="15%">RFID</td>
              </tr>

            </MemberTableHeader>
            <MemberTableBody style={loading ? { display: 'none' } : {}}>
              {
                userlist && userlist.map((userData, index) => {
                  return (
                    <tr key={index}>
                        <td width="15%">{userData.userid}</td>
                        <td width="10%">{userData.name}</td>
                        <td width="10%">{userData.phone}</td>
                        <td width="10%">{userData.buyTime ? timeValueToDate(userData.buyTime) : '-'}</td>
                        <td width="25%">{userData.product_name ? userData.product_name : '-'} {userData.product_option ? `(옵션 - ${userData.product_option})` : ""}</td>
                        <td width="10%">{(userData.price && userData.price != "")?userData.price:'-'}</td>
                        <td width="15%">{(userData.rfid && userData.rfid != "")?userData.rfid:'-'}</td>
                    </tr>
                  )
                })
              }
            </MemberTableBody>
          </MemberTable>
        }
        {
          (buyList && buyList.length > 0) ?
            <div style={{ display: 'flex', width: '100%', marginTop: '40px', justifyContent: 'center' }}>
              <Pagination
                count={buyList ? Math.ceil(buyList.length / 20) : 1}
                page={page}
                size="large"
                showFirstButton
                showLastButton
                onChange={(event, value) => {
                  if (value !== page) {
                    onChangePage(value)
                  }
                }}
              />
            </div>
            : null
        }
      </SearchListBlock>
    </MemberBlock>
  );
};

export default React.memo(BuyListView);
