import client, { url } from './client';
import qs from 'qs';

export const loadOrderList = ({startTime, endTime, userkey, keyword, type}) => {
    const queryString = qs.stringify({
        startTime, endTime, userkey, keyword, type
    });
    console.log(queryString)
    return client.get(`${url}/api/selectitem/loadorder?${queryString}`);
  }

  export const updateDownloadList = ({optionidlist,  userkey}) => {
    return client.post(`${url}/api/selectitem/downloadupdate`, {optionidlist,  userkey});
  }

  