
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    initializeBuy,
    loadBuyList,
    pageBuyChange
} from '../../modules/commons/search';
import { withRouter } from 'react-router-dom';
import BuyListView from '../../components/buy/BuyListView';
import moment from 'moment';

const BuyContainer = ({ history }) => {
  const {
    buyList,
    buyPage
  } = useSelector(({ search }) => ({
    buyList: search.buyList,
    buyPage:search.buyPage
  }));

  const [searchInfo, setAccountInfo] = useState({
    startTime: "",
    endTime: "",
    dateType: "",
    keyword: "",
  })

  const dispatch = useDispatch();

  useEffect(() => {
    if (!buyList) {
      dispatch(initializeBuy());
    }
  }, []);

  const onSetPeriod = (unit) => {
    let startTime = '';

    console.log('unit : ', unit)
    if (unit == '') {
      startTime = '2023-08-18';
    }
    else {
      startTime = moment().subtract(1, unit).format('YYYY-MM-DD');
    }

    const endTime = moment().format('YYYY-MM-DD');
    setAccountInfo({
      startTime: startTime,
      endTime: endTime,
      dateType: unit,
      keyword: searchInfo.keyword,
    })
  };

  const onChangeStartTime = (startTime) => {
    console.log('onChangeStartTime : ', startTime)
    setAccountInfo({
      startTime: startTime,
      endTime: searchInfo.endTime,
      dateType: searchInfo.dateType,
      keyword: searchInfo.keyword,
    })
  };

  const onChangeEndTime = (endTime) => {
    console.log('onChangeEndTime : ', endTime)
    setAccountInfo({
      startTime: searchInfo.startTime,
      endTime: endTime,
      dateType: searchInfo.dateType,
      keyword: searchInfo.keyword,
    })
  };

  const onChangeKeyword = (keyword) => {
    setAccountInfo({
      startTime: searchInfo.startTime,
      endTime: searchInfo.endTime,
      dateType: searchInfo.dateType,
      keyword: keyword,
    })
  };

  const onSearch = () => {

    let startTime = moment('2023-08-18').startOf('day').unix();
    let endTime = moment().endOf('day').unix();

    if (searchInfo.startTime) {
      startTime = moment(searchInfo.startTime).startOf('day').unix()
    }
    if (searchInfo.endTime) {
      endTime = moment(searchInfo.endTime).endOf('day').unix();
    }

    console.log('onSearch : ', searchInfo.startTime)
    console.log('onSearch : ', searchInfo.endTime)

    let userSearchPrev = {
      startTime: searchInfo.startTime,
      endTime: searchInfo.endTime,
      dateType: searchInfo.dateType,
      keyword: searchInfo.keyword,
    }

    let tempUser = (localStorage.getItem("user"));
    console.log('tempUseraaa : ', tempUser);
    dispatch(loadBuyList({
      startTime,
      endTime,
      userkey: tempUser,
      keyword: searchInfo.keyword,
    }),)
  }

  const onChangePage = (page) => {
    dispatch(pageBuyChange(page));
  }

  return (
    <>
        <BuyListView
            onChangeStartTime={onChangeStartTime}
            onChangeEndTime={onChangeEndTime}
            onChangeKeyword={onChangeKeyword}
            onSetPeriod={onSetPeriod}
            searchInfo={searchInfo}
            onSearch={onSearch}
            onChangePage={onChangePage}
            page={buyPage}
        />
    </>
  );
};

export default withRouter(BuyContainer);
