import axios from 'axios';
const client = axios.create(
);

//export const url = (config.service == 'testserver')?'https://ohtree.net:3030':(config.service == 'live')?'https://manager.healthnavi.co.kr:3030':'http://localhost:3030';

//export const url = 'http://localhost:9090';
export const url = 'https://api.mydingo.co.kr:9090';

export default client;
