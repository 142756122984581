import React from 'react';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import { SideSpacer } from '../../components/common/Spacer';import {
  HorizonWrapper,
  HorizonContainer,
} from '../../components/common/Container';
import MemberListContainer from '../../containers/member/MemberListContainer';
import {
  CommonContainer,
  HistoryActiveStyle,
  HistoryURL,
  HistoryBlock,
} from '../../components/common/Container';

const MemberListPage = () => {
    
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
      <HorizonWrapper>
        <CommonContainer>
          <MemberListContainer />
        </CommonContainer>
        {/* <HorizonContainer ratio={1} style={{paddingTop: '30px'}}>
          <HistoryBlock>
            <HistoryURL to="/member/" activeStyle={HistoryActiveStyle}>
              
            </HistoryURL>
          </HistoryBlock>
        </HorizonContainer>
        <HorizonContainer ratio={1}>
            <></>
        </HorizonContainer> */}
      </HorizonWrapper>
      </SideSpacer>
    </>
  );
};

export default MemberListPage;
