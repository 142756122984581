
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  userListChange,
  changeUserListState,
  initializeStateRes
} from '../../modules/commons/search';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import MemberInfo from '../../components/member/MemberInfo';
import ReportListView from '../../components/member/ReportListView';

const PrintListContainer = ({ history }) => {
  let {
    printList,
    changeUserListStateRes,
    memberList
  } = useSelector(({ search }) => ({
    printList: search.printList,
    memberList: search.memberList,
    changeUserListStateRes: search.changeUserListStateRes,
  }));

  const dispatch = useDispatch();

  const onChangeState = (itemstate)=>{
    let tempUser = (localStorage.getItem("user"))
    let user_uuids = [];
    for (let i = 0; i < printList.length; i++) {
      user_uuids.push(printList[i].uuid);
    }
    if(user_uuids.length > 0)
    {
      let uuidStr = JSON.stringify(user_uuids)
      dispatch(changeUserListState({user_uuids:uuidStr, userkey:tempUser, state:itemstate}))
    }
  }

  useEffect(() => {
    if(changeUserListStateRes != null)
    {
      dispatch(initializeStateRes());
      if(printList && memberList)
      {
        for (let j = 0; j < memberList.length; j++) {
          for (let i = 0; i < printList.length; i++) {
            if(printList[i].uuid == memberList[j].uuid)
            {
              memberList[j].state = changeUserListStateRes;
            }
          }
        }
        dispatch(userListChange(memberList));
        history.goBack();
      }
    }
  }, [changeUserListStateRes]);
  return (
    <>
      <ReportListView
        history={history}
        itemList={printList}
        onChangeState={onChangeState}
      />
    </>
  );
};

export default withRouter(PrintListContainer);
