import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';

import * as smartstoreAPI from '../../lib/api/smartstore';

const INITIALIZE = 'smartstore/INITIALIZE';
const PAGE_CHANGE = 'smartstore/PAGE_CHANGE';
const CHANGE_TAB = 'smartstore/CHANGE_TAB';


const [
  LOAD_ODER_LIST,
  LOAD_ODER_LIST_SUCCESS,
  LOAD_ODER_LIST_FAILURE,
] = createRequestActionTypes('smartstore/LOAD_ODER_LIST');

export const loadOrderList = createAction(
    LOAD_ODER_LIST,
  ({ startTime, endTime, userkey, keyword, type }) => ({
    startTime, endTime, userkey, keyword, type
  }),
);

const [
  UPDATE_DOWNLOAD_LIST,
  UPDATE_DOWNLOAD_LIST_SUCCESS,
  UPDATE_DOWNLOAD_LIST_FAILURE,
] = createRequestActionTypes('smartstore/UPDATE_DOWN_LIST');

export const updateDownloadList = createAction(
  UPDATE_DOWNLOAD_LIST,
({ optionidlist,  userkey }) => ({
  optionidlist,  userkey
}),
);


export const initialize = createAction(INITIALIZE, (form) => form);
export const pageChange = createAction(PAGE_CHANGE, (form) => form);
export const changeTab = createAction(CHANGE_TAB, (form) => form);

const loadOrderListSaga = createRequestSaga(LOAD_ODER_LIST, smartstoreAPI.loadOrderList);
const updateDownloadListSaga = createRequestSaga(UPDATE_DOWNLOAD_LIST, smartstoreAPI.updateDownloadList);

export function* smartstoreSaga() {
  yield takeLatest(LOAD_ODER_LIST, loadOrderListSaga);
  yield takeLatest(UPDATE_DOWNLOAD_LIST, updateDownloadListSaga);
}

const initialState = {
  result: null,
  orderList: [],
  page:1,
  updateRes:null,
  tab:1,
};

const smartstore = handleActions(
  {
    [INITIALIZE]: (state, { payload: form }) => ({
      ...state,
      result: null,
      orderList: [],
      page:1,
      updateRes:null,
    }),
    [PAGE_CHANGE]: (state, { payload: form }) => ({
      ...state,
      page: form,
    }),
    [CHANGE_TAB]: (state, { payload: form }) => ({
      ...state,
      tab: form,
    }),
    
    [LOAD_ODER_LIST_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      orderList: res,
      updateRes:null,
    }),
    [LOAD_ODER_LIST_FAILURE]: (state, { payload: res }) => ({
      ...state,
      error: res.result,
    }),
    [UPDATE_DOWNLOAD_LIST_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      updateRes: res
    }),
    [UPDATE_DOWNLOAD_LIST_FAILURE]: (state, { payload: res }) => ({
      ...state,
      error: res.result,
    }),
  },
  initialState,
);
export default smartstore;
