import React from 'react';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import { SideSpacer } from '../../components/common/Spacer';import {
  HorizonWrapper,
} from '../../components/common/Container';
import MemberContainer from '../../containers/member/MemberContainer';
import {
  CommonContainer,
} from '../../components/common/Container';

const MemberPage = () => {
    
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
      <HorizonWrapper>
        <CommonContainer>
          <MemberContainer />
        </CommonContainer>
      </HorizonWrapper>
      </SideSpacer>
    </>
  );
};

export default MemberPage;
