import React from 'react';
import styled from 'styled-components';

const SelectItemTemplateBlock = styled.div`
width: 100%;
height: 100%;
padding: 20px 20px 20px 20px;
`;

const SelectItemTemplate = ({ children }) => {
  return (
    <SelectItemTemplateBlock>
        {children}
    </SelectItemTemplateBlock>
  );
};

export default SelectItemTemplate;

